import {
  GET_PRIVATE_KEY,
  GENERATE_PUBLIC_KEY,
  GENERATE_PUBLIC_KEY_HASH,
  GENERATE_WALLET_ADDRESS,
  POST_ERROR
} from '../actions/types';

const initialState = {
  privateKey: '',
  publicKey: '',
  publicKeyHash: '',
  walletAddress: '',
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_PRIVATE_KEY:
      return { ...state, privateKey: payload };

    case GENERATE_PUBLIC_KEY:
      return { ...state, publicKey: payload };

    case GENERATE_PUBLIC_KEY_HASH:
      //console.log(`In Reducer: Public Key hash with pkhass as ${payload}`);
      //console.log(payload);
      return { ...state, publicKeyHash: payload };

    case GENERATE_WALLET_ADDRESS:
      return { ...state, walletAddress: payload };

    case POST_ERROR:
      return {
        ...state,
        error: payload
      };

    default:
      return state;
  }
}
