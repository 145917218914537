import React from 'react';
import imgArchitecture from '../../img/blog-procrain-architecture.jpg'
import imgClientJourney from '../../img/blog-procrain-clientjourney.jpg'
import imgEtherscan from '../../img/blog-procrain-etherscan.jpg'
import imgEveryoneJourney from '../../img/blog-procrain-everyonejourney.jpg'
import imgFaucet from '../../img/blog-procrain-faucet.jpg'
import imgFrontPage from '../../img/blog-procrain-frontpage.jpg'
import imgMetamask from '../../img/blog-procrain-metamask.jpg'
import imgMyProcrain from '../../img/blog-procrain-myprocrain.jpg'
import imgSuppierJourney from '../../img/blog-procrain-supplierjourney.jpg'

const Blog = () => {

    return   <>
    {/* <h1>DESCRIPTION</h1>
    <p className="text-justify h5">SYNOPSIS </p>
    <p className="text-justify h6">
        
            This paper explains the use case of blockchain based smart contracts (self-executing contracts based on certain rules written as code ) to carry out the bidding activities in a procurement cycle. It talks about shortcomings of the prevailing  centralized procurement processes highlighting the lack for transparency, auditability and reusability of the process.  It goes on to explain , conceptually, how the Ethereum blockchain and smart contracts can overcome these shortcomings. 
    </p>
    <p className="text-justify h6">
            The paper elucidates the viability of the idea by demonstrating it with a minimum viable product (MVP) named as ‘Procrain’. Procrain is a near portmanteau of ‘Procurement’  and ‘Blockchain’. Procrain is a procurement platform, at the heart of which, are two smart contracts deployed on the Ethereum public blockchain. A web enabled front end (www.Procrain.com)  allows the end users to interact with the smart contracts. In order to transact with the blockchain the end user will be required to have a crypto wallet with Ether (the native currency of Ethereum blockchain).
    </p>
    <p className="text-justify h6">
            The first Smart contract (called ‘Procrain Smart Contract’) has rules related with the core bidding functions (like initiating a Request for Proposal (RFP) by the Client, adding requirements to an RFP , bidding for an RFP by the Supplier, Managing the RFP Lifecycle etc). The second Smart Contract (called ‘Procrain Token  Smart Contract’) generates Procrain Token, a currency accepted by the first Smart contract for executing some fee-charging functions (e.g. initiating an RFP, bidding for an RFP) . 
    </p>
    <p className="text-justify h6">
            Both the smart contracts are written in Solidity and deployed on Ethereum Rinkeby Test net (no real money is required to carry out transactions on Test nets). www.Procrain.com is a React app hosted on Amazon EC2 instance that allows the end users to interact with both the Smart Contract via an API service provided by Infura.  The user will need a Metamask wallet to use the platform. The Metamask wallet serves two main purpose; firstly, it provides the user with the unique identity key (public key) which the user can use to carry out any transactions on the blockchain. Since the Public key is unique to every user, Procrian.com does not require a sign in. Secondly, the wallet is also used to approve the various Procrain fees  for carrying out the chargeable procurement functions(charged in Procrain Token) and transactions fees for interacting with blockchain  (also referred as gas in Ethereum parlance and is charged in ether). This means that that wallet should have enough funds to carry out transactions.
    </p>
    <p className="text-justify h6">
            At the end the article talks about the enhancement to the MVP, its limitations and its other potential usages.
        
    </p> */}
    
<br></br>

<p className="text-uppercase text-center h3">
PROCRAIN: A Proof of concept for carrying out  procurement
    processes on Ethereum Rinkeby Blockchain
</p>

{/* <p className="text-justify h6">
    <a
        href="#_Toc65745688"
    >
        SYNOPSIS 
    </a>
</p> */}
{/* <p className="text-justify h6">
    <a
        href="#_Toc65745689"
    >
        PROBLEM STATEMENT
    </a>
</p> */}
{/* <p className="text-justify h6">
    <a
        href="#_Toc65745690"
    >
        Situation 1. 4
    </a>
</p> */}
{/* <p className="text-justify h6">
    <a
        href="#_Toc65745691"
    >
        Challenges. 4
    </a>
</p> */}
{/* <p className="text-justify h6">
    <a
        href="#_Toc65745692"
    >
        Situation 2. 5
    </a>
</p> */}
{/* <p className="text-justify h6">
    <a
        href="#_Toc65745693"
    >
        Challenges. 5
    </a>
</p> */}
{/* <p className="text-justify h6">
    <a
        href="#_Toc65745694"
    >
        POTENTIAL SOLUTION
    </a>
</p> */}
{/* <p className="text-justify h6">
    <a
        href="#_Toc65745695"
    >
        Introduction to Blockchain. 6
    </a>
</p> */}
{/* <p className="text-justify h6">
    <a
        href="#_Toc65745696"
    >
        PROCRAIN MVP DESCRIPTION
    </a>
</p> */}
{/* <p className="text-justify h6">
    <a
        href="#_Toc65745697"
    >
        Architecture
    </a>
</p> */}
{/* <p className="text-justify h6">
    <a
        href="#_Toc65745698"
    >
        Local 8
    </a>
</p>
<p className="text-justify h6">
    <a
        href="#_Toc65745699"
    >
        Hosted. 9
    </a>
</p>
<p className="text-justify h6">
    <a
        href="#_Toc65745700"
    >
        Distributed. 10
    </a>
</p> */}
{/* <p className="text-justify h6">
    <a
        href="#_Toc65745701"
    >
        Procrain Business Functions and User Journeys
    </a>
</p> */}
{/* <p className="text-justify h6">
    <a
        href="#_Toc65745702"
    >
        My Procrain Page
    </a>
</p> */}
{/* <p className="text-justify h6">
    <a
        href="#_Toc65745703"
    >
        Client functions
    </a>
</p> */}
{/* <p className="text-justify h6">
    <a
        href="#_Toc65745704"
    >
        Supplier functions
    </a>
</p> */}
{/* <p className="text-justify h6">
    <a
        href="#_Toc65745705"
    >
        Universal functions
    </a>
</p> */}
{/* <p className="text-justify h6">
    <a
        href="#_Toc65745706"
    >
        Owner’s functions
    </a>
</p> */}
{/* <p className="text-justify h6">
    <a
        href="#_Toc65745707"
    >
        Limitation of Procrain
    </a>
</p> */}
{/* <p className="text-justify h6">
    <a
        href="#_Toc65745708"
    >
        Speed of Transaction. 17
    </a>
</p>
<p className="text-justify h6">
    <a
        href="#_Toc65745709"
    >
        Cost of Transactions: 18
    </a>
</p>
<p className="text-justify h6">
    <a
        href="#_Toc65745710"
    >
        Size of Transactions: 18
    </a>
</p> */}
{/* <p className="text-justify h6">
    <a
        href="#_Toc65745711"
    >
            Further Enhancement
    </a>
</p> */}
{/* <p className="text-justify h6">
    <a
        href="#_Toc65745712"
    >
        Evaluation function within the smart contract 18
    </a>
</p>
<p className="text-justify h6">
    <a
        href="#_Toc65745713"
    >
        Scaling of rules to cover more aspect of business life cycle. 18
    </a>
</p>
<p className="text-justify h6">
    <a
        href="#_Toc65745714"
    >
        Cost optimization. 18
    </a>
</p> */}
{/* <p className="text-justify h6">
    <a
        href="#_Toc65745715"
    >
        CONCLUSION
    </a>
</p> */}
<br/>
<p className="text-justify h4">
    <a name="_Toc65745688">SYNOPSIS</a>
</p>
<br></br>
<p className="text-justify h6">
    This article explains the use case of smart contracts for carry out the
    bidding activities in a procurement cycle. Smart contracts are
    self-executing rules written as code on the Ethereum blockchain. The article
    talks about shortcomings of the prevailing centralized procurement
    processes highlighting the lack of traceability and transparency in the
    process and how smart contracts can overcome these shortcomings.
</p>
<p className="text-justify h6">
    The article elucidates the viability of the idea by demonstrating it with a
    minimum viable product (MVP) named as ‘Procrain’. Procrain is a near
portmanteau of ‘<strong>Proc</strong>u<strong>r</strong>ement’ and ‘Blockch<strong><em>ain</em></strong>’. Procrain is a procurement platform that
    allows the end users to manage its procurement functions. It allows a
    client, who is seeking a supplier for some service or products, to raise a
    Request for Proposal (RFP) and attach the requirements against it. It
    enables the client to take the RFP through various bid stages (i.e.
    Initiation, Release, Closure). Any supplier can get registered on the
    platform and bid for any RFP which is in the released stage. Once the
    client of the RFP accepts the bid participation of the supplier, the
    supplier can go ahead with responding to the requirements of the RFP.
    Client can look at the responses from various interested suppliers and make
    decision and close the RFP. All the functions happen as transactions on the
    blockchain which acts as a database albeit a different kind where
    transaction which gets on the blockchain cannot be deleted or altered and
    hence is permanent. The transactions are open to the entire world to see.
    The platform charges a usage fee paid in Procrain token (PRC). A user can
    buy Procrain token on the platform in exchange of Ether( the native
    currency of Ethereum blockchain).
</p>
<p className="text-justify h6">
    Technologically, at the heart of Procrain, are two smart contracts deployed
on the Ethereum blockchain. A web enabled front end (    <a href="http://www.procrain.com/">www.Procrain.com</a>) allows the end
    users to interact with the smart contracts. In order to transact with the
    blockchain the end user will be required to have a crypto wallet. The first
    Smart contract (called ‘Procrain Smart Contract’) has rules related with
    the core bidding functions (like initiating an RFP by the Client, adding
    requirements to an RFP , bidding for an RFP by the Supplier, Managing the
    RFP Lifecycle etc). The second Smart Contract (called ‘Procrain Token Smart
    Contract’) generates Procrain Token, a currency accepted by the first Smart
    contract for executing some fee-charging functions (e.g. initiating an RFP,
    Registering as a supplier etc.). Both the smart contracts are written in
    Solidity and deployed on Ethereum Rinkeby Test net (no real money is
required to carry out transactions on Test nets).    <a href="http://www.procrain.com/">Procrain.com</a> is a React app hosted
    on Amazon EC2 instance that allows the end users to interact with both the
    smart contracts via an API service provided by Infura. The end user will
    need a Metamask wallet to use the platform. The Metamask wallet serves two
    main purpose; firstly, it provides the user with the unique
    address/identity key (public key) which the user can use to carry out any
    transactions on the blockchain. Secondly, the wallet is also used to
    approve the various Procrain fees for carrying out the chargeable
    functions(charged in Procrain Token) and transactions fees for interacting
    with blockchain (also referred as gas in Ethereum parlance and is charged
    in ether). The wallet uses the private key (underpinning the address/public
    key) to approve the transactions. Since the private key , which is known
    only to the user, cannot be worked backward from the public key and is
    computationally impossible to generate randomly makes Procrian.com a
    password less platform.
</p>
<p className="text-justify h6">
    At the end the article talks about the enhancement to the MVP, its
    limitations and its other potential usages.
</p>
<br></br>
<p className="text-justify h4">
    <a name="_Toc65745689">PROBLEM STATEMENT</a>
</p>
<br></br>
<p className="text-justify h5">
    <a name="_Toc65745690">Situation 1</a>
</p>
<p className="text-justify h6">
    An organisation (referred as the Client in this article) wants to focus on
    its core competency and hence decides to outsource the non-core functions
    to an external service provider. The CEO of the organisation asks the
    procurement department to raise a tender in the market to find the right
    service provider. The procurement team consults the internal
    stakeholders(departments, legal, commercial etc) and takes advise from an
    advisory firm that specializes in running the bid process on behalf of the
    client and assess the supplier’s response on behalf of the client. The
    Client manages to come up with a well-defined scope of work (requirements).
</p>
<p className="text-justify h6">
    The Client floats a Request for Proposal (RFP) in the market via its
    eProcurement portal (a leading web-based COTS product that Client uses to
    manage its procurement processes). The RFP clearly outline the requirements
    and has well defined evaluation criteria for the supplier selection. A memo
    about the RFP is floated in the leading procurement journal. The Client
    also uses other channels (Advisors, Marketing etc) to invite the leading
    service providers to the bidding process.
</p>
<p className="text-justify h6">
    About ten service providers express their intent to participate in the
    procurement process. After applying some basic filtering, the Client
    accepts candidature of five of the interested suppliers to participate in
    the process. The shortlisted suppliers respond to the RFP requirements
    within the stipulated time on the Client’s e-procurement portal. The client
    evaluates the responses from the suppliers and takes the top two suppliers
    to the BAFO (Best and Final Offer) stage. The three suppliers who have not
    been taken forward are informed separately about the decision and are given
    reasons for not making to the next stage of the cycle. The two down
    selected suppliers now submit their BAFO offer. After careful
    consideration, due diligence and some elongated negotiations with both the
    suppliers, the Client selects the preferred supplier (referred as the
    Supplier) to provide its service. The second supplier in the BAFO stage who
    did not get selected as the preferred supplier is intimated about not being
    successful and the reasons were cited. This is a typical lifecycle of the
    procurement and bidding process.
</p>
<br></br>
<p className="text-justify h5">
    <a name="_Toc65745691">Challenges</a>
</p>
<p className="text-justify h6">
    Prima facie the process looks transparent however there are some gaps which
    are highlighted below.
</p>
<p className="text-justify h6">
    - <strong>Lack of validation:</strong> The biggest problem is that when any
    supplier is unable to make to the next round of the procurement cycle, it
    is not able to validate the reasons cited for its rejection. They have no
    way to ascertain if the reasons given by the Client are correct or not as
    there is no or little provision to see what the successful suppliers have
    responded and how the scores between various suppliers compares. The
    unsuccessful suppliers must rely completely on the Client’s feedback.
</p>
<p className="text-justify h6">
    - <strong>Centralised Procurement tool favouring the Client:</strong>
    Another problem is the e-procurement tool on which the procurement process
    is being run is owned by the Client (hence centralised control). This means
    that the access to the procurement portal is controlled by the Client. Even
    if a third party (let’s say the Advisor) owns the tool and even if it is
    accessible to all by some mechanism, the Client will always have more
    influence on the tool. Client having more control over the tool compared to
    the suppliers doesn’t make it a fair play.
</p>
<br></br>
<p className="text-justify h5">
    <a name="_Toc65745692">Situation 2</a>
</p>
<p className="text-justify h6">
    Fast forward, the Supplier provided the service to the Client for ten
    years. The relationship between them had its fair share of ups and downs.
    Both parties blame other for not carrying out their share of obligations as
    contracted. The Client thus decides to again go for a tender process in the
    market.
</p>
<p className="text-justify h6">
    Many of the stakeholders who were engaged in previous tender, both from the
    Client and the Supplier sides, have left their respective organisations.
    The Client again go through all the rigmarole of capturing the requirements
    and raising the RFP. This time the process took more time as for
    requirement gathering Client was dependent on the Supplier as it has gained
    knowledge of the non-core processes better than the Client organisation.
</p>
<br></br>
<p className="text-justify h5">
    <a name="_Toc65745693">Challenges</a>
</p>
<p className="text-justify h6">
    Few drawbacks that are evident from the situation described above are:
</p>
<p className="text-justify h6">
    - <strong>Lack of clear accountability and auditability</strong>: Both the
    Client and the Supplier are blaming each other for not carrying out their
    share of obligations and responsibilities. This situation should not be
    happening if there are verifiable and measurable sets of requirements
    against which the parties have contracted to work on. Generally, the
    contract agreed between the parties deviates from the requirements put
    forward during the bidding process.
</p>
<p className="text-justify h6">
    - <strong>Reusability of the process:</strong> The Client gone through the
    similar rigor (if not more) for the second tender process as it has done
    for the first time. Ideally it should have been able to reuse the process
    and requirements from the last time and carry the subsequent tender in a
    faster end efficient manner. There is no reusability of the past process
    undertaken.
</p>
<p className="text-justify h6">
    - <strong>Loss of Knowledge</strong>: A lot of knowledge from the past
    tender process got lost as people associated with it changed roles and
    organisation. There is no permanent repository of the past knowledge.
</p>
<p className="text-justify h6"> 
    From the two sets of challenges outlined above, we construe that the
    prevalent procurement processes are not very transparent and is generally
    controlled by one party (the client) and hence is also centralized. The B2B
    market is huge (most likely greater than USD 20 Trillion) and there is a
    need for a transparent , and traceable procurement system for businesses to
    transact fostering an open and ethical business environment.
</p>
<br></br>
<p className="text-justify h4">
    <a name="_Toc65745694">POTENTIAL SOLUTION</a>
</p>
<br></br>
<p className="text-justify h6">
    A technology solution that is decentralized (not owned or control by a
    central authority), accessible to all (no permission required for access),
    traceable, programmable and tamper proof will be the right solution for the
    above described problem statement. Blockchain fits the bill.
</p>
<br></br>
<p className="text-justify h4">
    <a name="_Toc65745695">Introduction to Blockchain</a>
</p>
<p className="text-justify h6">
Blockchain is a ‘Distributed Ledger Technology’. It is    <strong>Distributed </strong>because there is no central authority
    controlling the ownership of Blockchain. A software/application has an
    underpinning infrastructure (server/machine where its hosted) which is
    owned and controlled by an entity (a person/corporation/government) thus
    making the control of software centralized. The central authority then
    controls the end users’ application usage data. The central authority, if
    it wants, can prevent the users from using its own data. There have been
    many instances in past where the owner of the application has wielded its
    control to deny the end user the usage of application and have leveraged
    the usage data to its advantage without compensating the end users.
</p>
<p className="text-justify h6">
    Blockchain doesn’t sit on a server controlled by a single entity.
    Blockchain is available on peer-to-peer network of nodes (machine/server).
    These nodes can be spun up by anyone. Each node has a copy of blockchain
    and even if few nodes are shut the blockchain is available over the
network. The nodes are independent of each other. It’s also a    <strong>Ledger </strong>because the transactions once registered on
    blockchain cannot be altered. It’s an append only and tamper proof system.
    This is made possible by the usage of cryptography.
</p>
<p className="text-justify h6">
    The concept of blockchain first came into prominence in 2008 when Satoshi
    Nakamoto (a pseudo name ) in its white paper (
    <a href="https://bitcoin.org/bitcoin.pdf">
        Bitcoin: A Peer to Peer Electronic Cash System
    </a>
    ) explained the use case of the viability of digital currency without
    needing a central authority. In doing so he described the technology
    enabling it which was Blockchain. Nakamoto presented a solution for the
    problem of double spend (i.e. the holder of a digital currency cannot spend
    it twice) without the need of having a central authority (e.g. a Bank /
    Government) to do so. The first few sentences of the abstract of the paper
    makes it clear what it’s going to describe:
</p>
<p className="text-justify h6">
    <em>
        “A purely peer-to-peer version of electronic cash would allow online
        payments to be sent directly from one party to another without going
        through a financial institution. Digital signatures provide part of the
        solution, but the main benefits are lost if a trusted third party is
        still required to prevent double spending. We propose a solution to the
        double-spending problem using a peer-to-peer network. The network
        timestamps transactions by hashing them into an ongoing chain of
        hash-based proof-of-work, forming a record that cannot be changed
        without redoing the proof-of-work.”
    </em>
</p>
<p className="text-justify h6">
    Vitalik Buterin extended Nakamoto’s idea of decentralized currency to
    decentralized internet where the software/applications are not in the
    control of central authorities. In his white paper (
    <a
        href="https://ethereum.org/en/whitepaper/#a-next-generation-smart-contract-and-decentralized-application-platform"
    >
        A Next-Generation Smart Contract and Decentralized Application Platform
    </a>
    ), published in 2013, he proposed that the use of blockchain for
    decentralized applications using smart contracts that can be written in the
    scripting language understood by blockchain. Smart contracts are
    self-executing rules. A part of the synopsis of the white paper by Buterin
    summarises the essence:
</p>
<p className="text-justify h6">
    <em>
        “…However, another - arguably more important - part of the Bitcoin
        experiment is the underlying blockchain technology as a tool of
        distributed consensus, and attention is rapidly starting to shift to
        this other aspect of Bitcoin…
    </em>
</p>
<p className="text-justify h6">
    <em>
        Commonly cited alternative applications of blockchain technology
        include using on-blockchain digital assets to represent custom
        currencies and financial instruments (
    </em>
    {/* <a
        href="https://docs.google.com/a/buterin.com/document/d/1AnkP_cVZTCMLIzw4DvsW6M8Q2JC0lIzrTLuoWu2z1BE/edit"
        target="_blank"
    > */}
        <em>colored coins</em>
    {/* </a> */}
    <em>), the ownership of an underlying physical device (</em>
    {/* <a href="https://en.bitcoin.it/wiki/Smart_Property" target="_blank"> */}
        <em>smart property</em>
    {/* </a> */}
    <em>
), non-fungible assets such as domain names (Namecoin), as well
        as more complex applications involving having digital assets being
            directly controlled by a piece of code implementing arbitrary rules
     
        (
    </em>
    
        <strong><em>smart contracts</em></strong>
    
    <em>) or even blockchain-based </em>
    
        <em>decentralized autonomous organizations</em>
    
    <em> (DAOs). </em>
</p>
<p className="text-justify h6">
    <em>
        What Ethereum intends to provide is a blockchain with a built-in fully
        fledged Turing-complete programming language that can be used to create
        "contracts" that can be used to encode arbitrary state transition
        functions, allowing users to create any of the systems described above,
        as well as many others that we have not yet imagined, simply by writing
        up the logic in a few lines of code..”
    </em>
</p>
<p className="text-justify h6">
    Buterin, along with others, went on to start the Ethereum blockchain.
    Ethereum is a public blockchain (permission less) which powered by EVM
    (Ethereum Virtual Machines) that can be used to run the smart contracts
    (rules) on Ethereum. Solidity is the scripting language that allows users
    to write smart contracts. Smart contract, then allows for the development
    of DApps (Distributed Applications) on Ethereum. Procrain at its core is a
    DApp. Ethereum has its own native currency called Ether. Ethereum charges a
    small fee to carry out any transaction on the Blockchain. This fee is
    called the ‘gas’ as it is akin to fuel need to execute the smart contract
    on the EVM.
</p>
<p className="text-justify h6">
    Ethereum also allows for creation of token based on standards (ERC20 and
    ERC721). ERC stands for Ethereum Request for Comments (ERC). Any needed
    improvement to Ethereum protocol is submitted by the proposer to the
    Ethereum community as an Ethereum Improvement Proposal (EIP). When the EIP
    is approved by the Ethereum Committee it is rolled out as an ERC. The two
    token standards got rolled out as part of ERC20 and ERC721. ERC20 tokens
    are fungible tokens that can be traded with any other ERC20 tokens. ERC721
    tokens are non-fungible tokens and are used for generating collectibles.
    Token are digital assets with multiple uses. Generally, they are associated
    within a DApp to carry out transactions on the DApp. It can also be used to
    for the purpose of fund raising for the DApp using ICO (Initial Coin
    Offerings). ICO are like IPO, where tokens are sold to public for the
    purpose of fund raising in exchange of ownership of DApp. Procrain token
    used by the Procrain platform is an ERC20 Token and is required for paying
    various fees on the platform. End user can buy Procrain Token in exchange
    of Ether.
</p>
<p className="text-justify h6">
    Ethereum has many networks. The primary/main network (aka the production
    environment) of Ethereum is called Ethereum Mainnet. There are other test
    networks like Ropsten, Goreli , Koven , Rinkeby with each having some
    specific purpose. There is no real value of Ether or tokens on test network
    and these environments are used for development and testing. The Procrain
    MVP is linked to Rinkeby test net and hence any transaction charges on the
    platform doesn’t have any financial value to it. The MVP is meant for the
    purpose of validation of the concept.
</p>
<br></br>
<p className="text-justify h4">
    <a name="_Toc65745696">PROCRAIN MVP DESCRIPTION</a>
</p>
<br></br>
<p className="text-justify h6">
    Procrain is a DApp that allows the users to carry out the procurement
    functions on the Ethereum blockchain. The core functions are executed by
    smart contracts on the Ethereum. The Procrain MVP is is available on the
    Rinkeby test net.
</p>
<br></br>
<p className="text-justify h4">
    <a name="_Toc65745697">Architecture</a>
</p>
<p className="text-justify h6">
    <br clear="ALL"/>
    Procrain’ s architecture is shown below:
</p>

<img src={imgArchitecture} className="img-fluid img-thumbnail rounded mx-auto d-block" alt="Architecture"/>

<p align="center">
    <em>(Fig: Procrain’ s Architecture)</em>
</p>
<p className="text-justify h5">
    <a name="_Toc65745698">Local</a>
</p>
<p className="text-justify h6">
    The end user ( a client or a supplier or a general member of public) will
    need a crypto wallet to out interaction with the Ethereum blockchain. To
    run any smart contract a fee is charged (called gas) and hence the user
    will need to have Ether in the wallet to run the functions of the smart
    contracts.
</p>
<br></br>
<p className="text-justify font-italic h6 ">
    Metamask
</p>
<p className="text-justify h6">
    Metamask is one such wallet. It provides the user with account credentials
    (public key/ private keys). It also broadcast transactions that takes via
    the accounts and send and receive Ether or Ethereum based tokens. It can be
    installed as a browser extension and securely connects to blockchain.
</p>
<p align="center">
<img src={imgMetamask} className="img-fluid img-thumbnail rounded mx-auto d-block" alt="Metamask"/>
    <em>(Fig: Metamask Crypto Wallet)</em>
</p>
<p className="text-justify h6">
    For installing the Metamask wallet and funding it with play Ether (with no
    real monetary value) follow the steps:
</p>
<p className="text-justify h6">
- Download Metamask from        <a href="https://metamask.io/">https://metamask.io/</a> and install the
        browser extension
</p>


<p className="text-justify h6">
    - Create an account and log in to Metamask
</p>
<p className="text-justify h6">
    - Take one of the account addresses and use your social media account
    (Twitter or Facebook) to post the address in which you want the play Ether
</p>
<p className="text-justify h6">
- Copy the URL of the post and paste it on    <a href="https://faucet.rinkeby.io/">https://faucet.rinkeby.io/</a> and ask
    for Ether
</p>
<p className="text-justify h6">
    - In some time, the Metamask account will be funded by the play ether.
</p>

<p align="center">
<img src={imgFaucet} className="img-fluid img-thumbnail rounded mx-auto d-block" alt="Faucet"/>
    <em>(Fig: Rinkeby Faucet Providing Play Ether)</em>
</p>
<br></br>
<p className="text-justify h5">
    <a name="_Toc65745699">Hosted</a>
</p>
<p className="text-justify h6">
    The end user will need a portal to interact with the smart contract. The
Procrain web application (    <a href="http://www.procrain.com/">www.procrain.com</a>) allows the end
    user to interact with the smart contract.
</p>
<br></br>
<p className="text-justify font-italic h6">
    Procrain.com
</p>
<p className="text-justify h6">
    Log in to <a href="http://www.procrain.com/">www.procrain.com</a> access
    the front end.
</p>
{/* <p align="center">
<img src={imgFrontPage} className="img-fluid img-thumbnail rounded mx-auto d-block" alt="Front Page"/>
    <em>(Fig: Procrain Web Application)</em>
</p> */}
<p className="text-justify h6">
    The web applications have all functions that is required by the end user to
    pass on the smart contract as an argument. These functions can be found
    under the Client, supplier or myProcrain page under the Menu option.
</p>
<br></br>
<p className="text-justify font-italic h6">
    Infura API
</p>
<p className="text-justify h6">
    Now how does the web application talk to the Ethereum blockchain which is a
    distributed system? One way is to create a live node of blockchain and
    interact with it. This is a slightly complex and an infrastructure
    intensive process. Thankfully there are APIs available which enables the
web applications to connect to Blockchain. Infura (    <a href="https://infura.io/">https://infura.io/</a>) is one such API
    provider that allows applications to connect to Ethereum via JSON-RPC call.
    Procrain web app uses Infura API to connect to Ethereum.
</p>
<p className="text-justify h6">
    The Procrain web application is developed using the React library of Java
    script and is deployed on AWS cloud using AWS Amplify. The front end is
    using Web3.js library that allows it to read the data from blockchain.
</p>
<br></br>
<p className="text-justify h5">
    <a name="_Toc65745700">Distributed</a>
</p>
<p className="text-justify h6">
    Etherscan is web portal that allows user to explore and search the Ethereum
    blockchain. One can search for any transactions or account addresses or
    token etc. We will be using this portal to validate the transactions that
    has happened on Procrain. Please ensure to select the Rinkeby Test net as
the chosen network (    <a href="https://rinkeby.etherscan.io/">https://rinkeby.etherscan.io/</a>)
    ; by default, it is Ethereum Mainnet.
</p>
<p align="center">
<img src={imgEtherscan} className="img-fluid img-thumbnail rounded mx-auto d-block" alt="Etherscan"/>
    <em>(Fig: Etherscan Portal)</em>
</p>
<br></br>
<p className="text-justify h4">
    <a name="_Toc65745701">Procrain Business Functions and User Journeys</a>
</p>
<br></br>
<p className="text-justify h6">
    All the business rules are managed by the Procrain smart contract. Procrain
    smart contract has following functions:
</p>
<table className="table table-striped table-bordered">
        <thead className="thead-dark">
            <tr>
            <th scope="col">Client Functions</th>
            <th scope="col">Supplier Functions</th>
            <th scope="col">Owner’s Functions</th>
            <th scope="col">Universal Functions</th>
            </tr>
        </thead>
    <tbody>
      
        <tr>
            <td  valign="top">
               
                    <em>Update Client Details</em>
                
            </td>
            <td valign="top">
               
                    <em>Register as Supplier</em>
                
            </td>
            <td  valign="top">
               
                    <em>Start Procrain</em>
               
            </td>
            <td  valign="top">
               
                    <em>Buy Procrain Token</em>
                
            </td>
        </tr>
        <tr>
            <td  valign="top">
                
                    <em>Initiate RFP</em>
                
            </td>
            <td  valign="top">
                
                    <em>Update Supplier Details</em>
                
            </td>
            <td  valign="top">
               
                    <em>Stop Procrain</em>
               
            </td>
            <td  valign="top">
               
                    <em>Search RFP Details</em>
               
            </td>
        </tr>
        <tr>
            <td  valign="top">
               
                    <em>Change RFP status</em>
                
            </td>
            <td  valign="top">
               
                    <em>Bid RFP</em>
                
            </td>
            <td  valign="top">
              
                    <em>Set RFP Initiation Fee</em>
                
            </td>
            <td  valign="top">
               
                    <em>Fetch Requirements for an RFP</em>
                
            </td>
        </tr>
        <tr>
            <td  valign="top">
               
                    <em>Add Requirement to an RFP</em>
                
            </td>
            <td  valign="top">
              
                    <em>Respond to RFP Requirement</em>
            
            </td>
            <td  valign="top">
                
                    <em>Set Supplier Registration Fee</em>
            
            </td>
            <td  valign="top">
               
                    <em>Fetch Response to Requirement</em>
                
            </td>
        </tr>
        <tr>
            <td  valign="top">
              
                    <em>Fetch Bid Participation for an RFP</em>
                
            </td>
            <td  valign="top">
                
                    <em></em>
               
            </td>
            <td  valign="top">
               
                    <em>Set Requirement Fee</em>
                
            </td>
            <td  valign="top">
               
            </td>
        </tr>
        <tr>
            <td  valign="top">
               
                    <em>Accept/Reject Bid Participation</em>
                
            </td>
            <td  valign="top">
               
            </td>
            <td  valign="top">
               
                    <em>Set Response Fee</em>
                
            </td>
            <td  valign="top">
              
            </td>
        </tr>
        <tr>
            <td  valign="top">
               
                    <em>Fetch Bid Status for a Supplier</em>
                
            </td>
            <td  valign="top">
                
            </td>
            <td  valign="top">
               
                    <em>Get Procrain Ether Balance</em>
                
            </td>
            <td  valign="top">
               
            </td>
        </tr>
        <tr>
            <td  valign="top">
              
            </td>
            <td  valign="top">
                
            </td>
            <td  valign="top">
               
                    <em>Withdraw Ether from Procrain</em>
                
            </td>
            <td  valign="top">
              
            </td>
        </tr>
        <tr>
            <td  valign="top">
                
            </td>
            <td  valign="top">
               
            </td>
            <td  valign="top">
                
                    <em>Get Procrain Token Balance</em>
                
            </td>
            <td  valign="top">
                
            </td>
        </tr>
        <tr>
            <td  valign="top">
                
            </td>
            <td  valign="top">
                
            </td>
            <td  valign="top">
               
                    <em>Withdraw Token from Procrain</em>
                
            </td>
            <td  valign="top">
                
            </td>
        </tr>
    </tbody>
</table>
<br></br>
<p className="text-justify h6">
    Running some of the functions will require additional usage fee in addition
    to the gas fee. The fee incurring functions along with the fee are listed
    below:
</p>
<p className="text-justify h6">
    - Initiate RFP (1 PRC)
</p>
<p className="text-justify h6">
    - Registering as a Supplier (onetime fee for an address 1 PRC)
</p>
<p className="text-justify h6">
    - Add Requirement to an RFP (0.1 PRC)
</p>
<p className="text-justify h6">
    Respond to RFP Requirement (0.1 PRC)
</p>
<br></br>
<p className="text-justify h6">
    There are four different roles in the Procrain. These are Client, Supplier,
    Universal and Owner. An end user can don all the three roles except the
    Owner. The Owner of the Procrain is the address first registering the smart
    contracts on the blockchain.
</p>
<br></br>
<p className="text-justify h5">
    <a name="_Toc65745702">My Procrain Page</a>
</p>
<p className="text-justify h6">
    <br clear="ALL"/>
    Procrain.com is an open platform in a way that it does not require the user
    to sign in to the platform. It uses Metamask account address to show the
    details for the address. When a user opens the ‘MyProcrain’ page (Menu
    -&gt; MyProcrain) it will open a page with details of the account like
    amount of ether in the account, amount of Procrain token etc.
</p>
<p align="center">
<img src={imgMyProcrain} className="img-fluid img-thumbnail rounded mx-auto d-block" alt="My Procrain Page"/>
    <em>(Fig: My Procrain Page)</em>
</p>
<p className="text-justify h6">
    This page allows the user to carry out following functions:
</p>
<p className="text-justify font-italic h6">
    Buy Procrain
</p>
<p className="text-justify h6">
    This function allows the user to buy Procrain Token (PRC) in exchange for
    Ether (ETH). Currently the platform is pegged at 1 PRC for 1 ETH.
</p>
<br></br>
<p className="text-justify font-italic h6">
    Update Client Details
</p>
<p className="text-justify h6">
    This function allows the user to set the display details for its Client
    role credential. It takes ‘display name’ and ‘display description’ as
    arguments. There is no fee for updating the client details.
</p>
<br></br>
<p className="text-justify font-italic h6">
    Update (Register) Supplier details
</p>
<p className="text-justify h6">
    This function allows the user to set the display details for its Supplier’s
    profile which others can see. It takes ‘display name’ and ‘display
    description’ as arguments. There is 1 PRC fee for registering as a supplier
    or updating a supplier detail on the Procrain platform.
</p>
<br></br>
<p className="text-justify font-italic h6">
    Fetch RFP Details
</p>
<p className="text-justify h6">
    This function fetches the details of an RFP given the RFP ID or status of
    the RFP.
</p>
<br></br>
<p className="text-justify h5">
    <a name="_Toc65745703">Client functions</a>
</p>
<p className="text-justify h6">
    A client is one who raises an RFP and put the requirements for the RFP. The
    client journey covering various functions it performs is shown below.
</p>
<p align="center">
<img src={imgClientJourney} className="img-fluid img-thumbnail rounded mx-auto d-block" alt="Client Journey"/>
    <em>(Figure: Client Journey)</em>
</p>
<p className="text-justify h6">
    The functions performed by a client can be found in the Client Tab (Menu
    -&gt; Client). These functions are:
</p>
<p className="text-justify font-italic h6">
    Initiate RFP
</p>
<p className="text-justify h6">
    This function allows a client to raise an RFP by providing the name of the
    RFP and a brief description as arguments. The smart contract charges an RFP
    initiation fee of 1 PRC. If the function runs successfully then the RFP is
    created with an unique RFP id. The RFP id, name and description can then be
    seen in the myProcrain page. When an RFP is created for the first time its
    status is ‘Initiated’.
</p>
<br></br>
<p className="text-justify font-italic h6">
    Change RFP Status
</p>
<p className="text-justify h6">
    This function is used to change the status of an RFP. The RFP can have
    below statuses:
</p>
<p className="text-justify h6">
        <strong><em>- Initiated</em></strong>
        : When RFP is created its status is ‘Initiated’. Requirements are added
        to RFP only in this status.
        </p>
        <p className="text-justify h6">         
        <strong><em>- Released</em></strong>
        : The RFP is made open for bidding by changing it status from
        ‘Initiated’ to ‘Released’. Suppliers can bid for an RFP and respond to
        requirements only if it is in Released status.
        </p>
        <p className="text-justify h6">
        <strong><em>- Closed</em></strong>
        : The RFP is closed by changing the status from ‘Released’ to ‘Closed’.
        </p>
        <p className="text-justify h6">
        <strong><em>- Discarded</em></strong>
        : The RFP can also be discarded. To move to this state the prior status
        of RFP should be either Initiated or Released.
        </p>
  
<br></br>
<p className="text-justify font-italic h6">
    Add a Requirement
</p>
<p className="text-justify h6">
    This function allows a client to add a requirement to an RFP. Requirement
    can be added only if the RFP status is ‘Initiated’. For raising a
    requirement following parameters are supplied:
</p>
<p className="text-justify h6">
    - RFP ID (Number)
</p>
<p className="text-justify h6">
    - Requirement Name (String)
</p>
<p className="text-justify h6">
    - Requirement Desc (String)
</p>
<p className="text-justify h6">
    - Requirement Criticality (String) [ Very Low, Low, Medium,, high, Very
    high]
</p>
<p className="text-justify h6">
    - Points (Number) [20 , 40, 60, 80, 100]
</p>
<p className="text-justify h6">
    - Active (Boolean) [ Active, Inactive]
</p>
<p className="text-justify h6">
    The function returns a unique ‘Requirement Id’ if the requirement is raised
    successfully. For raising a requirement, a fee of 0.1 PRC is charged.
</p>
<br></br>
<p className="text-justify font-italic h6">
    Fetch Bid Participations
</p>
<p className="text-justify h6">
    This function allows a client to fetch expression of interest by suppliers
    for an RFP. It takes RFPid as an input and returns the participation
    interest from Supplier by displaying their Bid participation Reference
    number and Supplier address.
</p>
<p className="text-justify font-italic h6">
    Accept Bid Participation
</p>
<p className="text-justify h6">
    This function allows a client to accept the bid participation request from
    a supplier. It requires ‘bid participation reference number’ as a
    parameter.
</p>
<p className="text-justify font-italic h6">
    Fetch Bid Status for a Supplier
</p>
<p className="text-justify h6">
    This function allows a client to find the bid participation status
    (accepted or rejected) for any supplier by supplying the ‘bid participation
    reference number’.
</p>
<br></br>
<p className="text-justify h5">
    <a name="_Toc65745704">Supplier functions</a>
</p>
<br></br>
<p className="text-justify h6">
    A supplier is one who bid for an RFP raised by the client and responds to
    the requirements of the RFP. The journey for a supplier covering various
    functions it performs is shown below:
</p>
<p align="center">
<img src={imgSuppierJourney} className="img-fluid img-thumbnail rounded mx-auto d-block" alt="Supplier Journey"/>
    <em>(Figure: Supplier Journey)</em>
</p>
<p className="text-justify h6">
    A supplier can perform following functions:
</p>
<br></br>
<p className="text-justify font-italic h6">
    Fetch RFP Requirement
</p>
<p className="text-justify h6">
    This function returns the requirements for a given RFP Id.
</p>
<p className="text-justify font-italic h6">
    Bid RFP
</p>
<p className="text-justify h6">
    This function allows the supplier to bid for an RFP. It taken RFP id as a
    parameter. If the function is successful, then a unique ‘bid participation
    reference number’ is returned by the smart contract. Only when the
    participation is accepted by the client then only the supplier can respond
    to the requirements for the RFP.
</p>
<p className="text-justify font-italic h6">
    Respond to RFP Requirements
</p>
<p className="text-justify h6">
    This function allows a supplier to respond to the RFP requirements. Given
    the Requirement ID (unique for every requirement) and supplier ‘bid
    participation reference number’ a supplier can accept , partially accept or
    reject the requirement. The function will be successful only if the
    participation has been accepted by the Client. On success the function
    returns the Requirement’s Response Reference number (Unique for each
    response) .
</p>
<p className="text-justify font-italic h6">
    Fetch Response to Requirement
</p>
<p className="text-justify h6">
    This function allows a supplier (or anyone) to fetch the response to a
    requirement by providing the requirement response reference number.
</p>
<br></br>
<p className="text-justify h5">
    <a name="_Toc65745705">Universal functions</a>
</p>
<br></br>
<p className="text-justify  h6">
    These functions are accessible to everyone.
</p>
<p align="center">
<img src={imgEveryoneJourney} className="img-fluid img-thumbnail rounded mx-auto d-block" alt="Everyone"/>
    <em>(Figure: Universal Functions)</em>
</p>
<p className="text-justify h6">
    The platform allows any person to find details about any RFP , get it
    requirements and see the response to a requirement if the ‘Requirement
    Response Id’ is available. These are functions that does not require gas
    fee as these details are fetched from the log of past events rather than
    fetching the data from the smart contract.
</p>
<br></br>
<p className="text-justify h5">
    <a name="_Toc65745706">Owner’s functions</a>
</p>
<br></br>
<p className="text-justify h6">
    In addition to the role of the Client and the Supplier there is a third
    role of the Owner which is granted to one who has deployed the smart
    contract on the blockchain. On the Procrain web application, these
    functions will not be visible to others except the contract owner. An owner
    can perform the following functions:
</p>
<p className="text-justify font-itali h6">
    <em>Stop Procrain</em>
</p>
<p className="text-justify h6">
    This function makes other functions of the smart contract inactive. Any
    smart contract, once registered on the blockchain, cannot be altered as
    blockchain is append only and tamper proof. But there might be a need to
    stop the smart contract to execute. This can be handled by architecting the
    smart contract code in such a way that a business function executes only
    when the state of the smart contact is active. This state can then be
    altered by the owner and thus the functions of smart contract become active
    or inactive
</p>
<p className="text-justify font-italic h6">
    <em>Start Procrain</em>
</p>
<p className="text-justify h6">
    This function makes the state of other functions in the smart contract as
    active.
</p>
<p className="text-justify font-italic h6">
    <em>Set RFP Initiation Fee</em>
</p>
<p className="text-justify font-italic h6">
    This function allows the Owner to set the RFP initiation fee (in PRC).
    Currently it is set at 1 PRC.
</p>
<p className="text-justify font-italic h6">
    <em>Set Supplier Registration Fee</em>
</p>
<p className="text-justify h6">
    This function allows the Owner to set the supplier registration fee (in
    PRC). Currently it is set at 1 PRC.
</p>
<p className="text-justify  font-italic h6">
    <em>Set Requirement Fee</em>
</p>
<p className="text-justify h6">
    This function allows the Owner to set the fee (in PRC) for raising a
    requirement in the RFP. Currently it is set at 0.1 PRC. This is charged to
    the Client role.
</p>

<p className="text-justify font-italic h6">
    <em>Set Response Fee</em>
</p>
<p className="text-justify h6">
    This function allows the Owner to set the fee (in PRC) for responding to a
    requirement of the RFP. Currently it is set at 0.1 PRC. This is charged to
    the Supplier role.
</p>

<p className="text-justify font-italic h6">
    <em>Withdraw Ether from Procrain</em>
</p>
<p className="text-justify h6">
    This function allows the Owner to withdraw a specified Ether amount from
    the Procrain smart contract account to the Owner’s account. The Procrain
    smart contract account gets Ether when an end user buys Procrain token in
    exchange of Ether.
</p>

<p className="text-justify font-italic h6">
    <em>Withdraw Token from Procrain</em>
</p>
<p className="text-justify h6">
    This function allows the Owner to withdraw the Procrain Token from the
    Procrain smart contract account to the Owner’s own account. The Procrain
    smart contract account gets Procrain token from an end user account when a
    fee charging function is executed by the end user (e.g. Initiate RFP ,
    Register Supplier etc)
</p>
<br></br>
<p className="text-justify h5">
    <a name="_Toc65745707">Limitation of Procrain</a>
</p>
<br></br>
<p className="text-justify h6">
    There are many shortcomings of the platform. Most of them are because
    blockchain as technology is still evolving and its architecture is
    limiting. Few of them are mentioned below:
</p>
<p className="text-justify h6">
<strong>Speed of Transaction</strong>
    : The functions that changes the state of the blockchain (e.g. bid RFP, Add
    Requirements etc) takes longer time to complete when compared to time taken
    by similar function to execute on any centralized application. This is
    because of the time the transaction takes to be added to a block and then
    the mined block being added to the blockchain. The blockchain community is
    working towards this problem. Bitcoin blockchain adds a block (refereed as
    block time) in around 10 minutes whereas on Ethereum it is around 10 to 20
    seconds. This is still very high compared to any modern web app where
    transaction time are in milli seconds.
</p>
<p className="text-justify h6">
<strong>Cost of Transactions:</strong>
    Any transaction made on blockchain requires gas and thus executing Procrain
    functions are costly. This then puts a need of having only those
    transactions on the blockchain that are essential and is required to be
    recorded.
</p>
<p className="text-justify h6">
    <strong>Size of Transactions:</strong>
    An Ethereum block has a limit of 24 KB of information. Writing a large
    complex smart contract with many computationally intensive functions within
    the limit of 24 KB becomes a very big challenge. There are some hacks like
    using libraries etc but still if one is expecting to make an enterprise
    scale application with many business rules this is the most limiting
    problem that the developers will face.
</p>
<br></br>
<p className="text-justify h4">
       <a name="_Toc65745711">Further Enhancement</a>
</p>
<br></br>
<p className="text-justify h5">
    Evaluation function within the smart contract
</p>
<p className="text-justify h6">
    On the Procrain MVP, there is no function available to automatically
    evaluate the supplier’s response against a set criterion and select the
    most competent supplier. This is because evaluation criteria can vary from
    client to client. Also, it is to make the code in the smart contract light.
    However, there is a work around even in the current MVP. If a client
    publishes it evaluation criteria as part of one of its requirements, then
    it will be available for everyone to see. Since the response data from all
    the suppliers are already available, anyone can validate the final
    evaluation based on the given evaluation criteria. The further enhancement
    of the MVP is to be built in the evaluation function within the smart
    contract.
</p>
<br></br>
<p className="text-justify h5">
   
        Scaling of rules to cover more aspect of business life cycle
    
</p>
<p className="text-justify h6">
    Currently the MVP provides business rules that enables a client to select a
    supplier. These rules cater to the pre contracting phase. The platform can
    be scaled up to include business rules to cover the post contracting aspect
    of business cycle like performance measurement , invoice management etc.
</p>
<br></br>
<p className="text-justify h5">
  Cost optimization
</p>
<p className="text-justify h6">
    Currently there are gas charged for any transaction that alters the state
    off blockchain. The gas charge depends on how computationally intensive the
    function is. A further enhancement of the product will be to query as much
    of data from the past event log wherever possible as reading the logs are
    much cheaper compared to reading from the blockchain. The smart contract
    should be written in such a way that it emits necessary events to be
    queried for transactions result. Where it is necessary that a transaction
    is to be recorded on the blockchain or a data is to be read from blockchain
    (rather than the event log) an optimised function should be written to make
    the gas charges as less as possible.
</p>
<br></br>
<p className="text-justify h4">
    <a name="_Toc65745715">CONCLUSION</a>
</p>
<br></br>
<p className="text-justify h6">
    The issue around control, transparency and traceability that mar today’s
    procurement processes can be overcome by adoption of blockchain.
    Blockchain as technology has evolved tremendously in last one decade. It’s
    technologically feasible to create distributed applications on the
    blockchain which uses the smart contract to execute the business rules. The
    transactions registered on the blockchain is permanent and available for
    any body to see. In doing so it does not compromise with the privacy of the
    parties involved and hence is secure. There are architectural limitations
    of the evolving blockchain technology that makes the scalability a
    challenge. The cost of transaction and speed at which the transactions are
    accepted on blockchain are other limitations of the technology. However
    even within the current limitations, with a good design, the enterprise
    scale distributed application managing the procurement function can be
    made.
</p>

</>
}

export default Blog;