import React from "react";
import { Route, Switch } from "react-router-dom";
import Landing from "../layout/Landing";
import Hash from "../utilities/hash/Hash";
import Block from "../utilities/block/Block";
import BlockChain from "../utilities/blockchain/BlockChain";
import Wallet from "../utilities/wallet/Wallet";
import Verify from "../utilities/verify/Verify";
import Procrain from "../procrain/Procrain";
import Client from "../procrain/Client";
import Supplier from "../procrain/Supplier";
import Owner from "../procrain/Owner";
import Blog from "../blog/blog";

const Routes = (props) => {
  return (
    <section className="container text-center">
      <Switch>
        <Route exact path="/hash" component={Hash} />
        <Route exact path="/block" component={Block} />
        <Route exact path="/blockchain" component={BlockChain} />
        <Route exact path="/wallet" component={Wallet} />
        <Route exact path="/verify" component={Verify} />
        <Route exact path="/procrain" component={Procrain} />
        <Route exact path="/landing" component={Landing} />
        <Route exact path="/client" component={Client} />
        <Route exact path="/supplier" component={Supplier} />
        <Route exact path="/owner" component={Owner} />
        <Route exact path="/blog" component={Blog} />
        {/* <Route component={NotFound} /> */}
      </Switch>
    </section>
  );
};

export default Routes;
