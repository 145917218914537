import React, { Fragment, useState, useEffect } from "react";
import smartContractObj from "../../smartcontract/SmartContract";
import Spinner from "../layout/Spinner";
import Table from "../layout/Table";

import Info from "../../components/layout/Info";
import { txtMetamaskNotInstalled } from "../../content/content";

import ProcrainModal from "../layout/ProcrainModal";

import {BsInfoCircle}  from "react-icons/bs";
import { motion } from "framer-motion";


const Procrain = () => {
  let [spinnerDisplay, setSpinnerDisplay] = useState(false);
  let [metamaskAlert, setMetamaskAlert] = useState(false);
  let [loginAlert, setLoginAlert] = useState(false);

  let [spiBuyProcrain, setSpiBuyProcrain] = useState(false);
  let [spiEtherBalance, setSpiEtherBalance] = useState(false);
  let [spiProcrainBalance, setSpiProcrainBalance] = useState(false);

  let [spiRegisterSupplier, setSpiRegisterSupplier] = useState(false);
  let [spiUpdateClientDetails, setSpiUpdateClientDetails] = useState(false);

  let [spiFetchClientDetails, setSpiFetchClientDetails] = useState(false);
  let [spiFetchSupplierDetails, setSpiFetchSupplierDetails] = useState(false);

  let [etherBalance, setEtherBalance] = useState(0);
  let [procrainBalance, setProcrainBalance] = useState(0);

  let [clientName, setClientName] = useState("");
  let [clientDesc, setClientDesc] = useState("");

  let [supplierName, setSupplierName] = useState("");
  let [supplierDesc, setSupplierDesc] = useState("");

  let [rfPInitiationFee, setRfPInitiationFee] = useState(0);
  let [supplierRegistrationFee, setSupplierRegistrationFee] = useState(0);
  let [raisingRequirementFee, setRaisingRequirementFee] = useState(0);
  let [respondingRequirementFee, setRespondingRequirementFee] = useState(0);

  let [showModal, setShowModal] = useState(false);
  let [modalText, setModalText] = useState("");
  let [modalHeader, setModalHeader] = useState("");
  let [modalSubHeader, setModalSubHeader] = useState("");

  let [columns, setColumns] = useState([
    {
      Header: "My Deals",
      Footer: " ",
      columns: [
        {
          Header: "RFP ID",
          accessor: "RFPid",
        },
        {
          Header: "RFP Description",
          accessor: "description",
        },
        {
          Header: "RFP Name",
          accessor: "name",
        },
        {
          Header: "RFP Status",
          accessor: "status",
        },
      ],
    },
  ]);

  let [data, setData] = useState([
    {
      RFPid: "#",
      description: "RFP Description",
      name: "RFP name",
      status: "null",
    },
  ]);

  useEffect(() => {
    let myRFPs = async () => {
      let myRFPs = await smartContractObj.getRFPsforAnUser();
      //console.log(myRFPs.key);
      setData(myRFPs.key);
    };

    let myEtherBalance = async () => {
      let myEtherBalance = await smartContractObj.getEtherBalanceForAUserAccount();
      //console.log(myEtherBalance.key);
      setEtherBalance(parseFloat(myEtherBalance.key).toFixed(3));
    };

    let myProcrainBalance = async () => {
      let myProcrainBalance = await smartContractObj.getProcrainTokenBalanceForAUserAccount();
      //console.log(myProcrainBalance.key);
      setProcrainBalance(myProcrainBalance.key);
    };

    const myClientDetails = async () => {
      let clientDetails = await smartContractObj.fetchClientDetails();
      //console.log(clientDetails.key.params[0].value);
      let name = "Not set";
      let desc = "Not set";
    
      if (typeof clientDetails !== "undefined") {
        //console.log("I am in " + clientDetails.key.value);
        if (clientDetails.key != "") {
          name = clientDetails.key.params[0].value;
          desc = clientDetails.key.params[1].value;
          //console.log(`name is ${name}`)
        }       
      }
      setClientName(name);
      setClientDesc(desc);
    };

    const mySupplierDetails = async () => {
      let supplierDetails = await smartContractObj.fetchSupplierDetails();
      // console.log(supplierDetails);

      let name = "Not Set";
      let desc = "Not Set";
      if (typeof supplierDetails !== "undefined") {
        if (supplierDetails.key != "") {
          name = supplierDetails.key.params[0].value;
          desc = supplierDetails.key.params[1].value;
        }       
      }
      setSupplierName(name);
      setSupplierDesc(desc);
    };

    const FetchInitiationFee = async () => {
      let intiationFee = await smartContractObj.getRFPInitiationFee();
      if (typeof intiationFee !== "undefined") {
        setRfPInitiationFee(
          parseInt(Object.values(intiationFee)) / Math.pow(10, 18)
        );
      }
    };

    const FetchRegistrationFee = async () => {
      let registrationFee = await smartContractObj.getSupplierRegistartionFee();
      if (typeof registrationFee !== "undefined") {
        setSupplierRegistrationFee(
          Object.values(registrationFee) / Math.pow(10, 18)
        );
      }
    };

    const FetchRequirementFee = async () => {
      let requirementFee = await smartContractObj.getRequirementFee();
      if (typeof requirementFee !== "undefined") {
        setRaisingRequirementFee(
          Object.values(requirementFee) / Math.pow(10, 18)
        );
      }
    };

    const FetchResponseFee = async () => {
      let responseFee = await smartContractObj.getRequirementFee();
      if (typeof responseFee !== "undefined") {
        setRespondingRequirementFee(
          Object.values(responseFee) / Math.pow(10, 18)
        );
      }
    };

    smartContractObj.isMetaMaskInstalled().then((flgMetamask) => {
      if (flgMetamask) {
        //console.log("I am if metamask installed");
        smartContractObj.isLoggedIn().then((flgLoggedIn) => {
          if (flgLoggedIn) {
            myProcrainBalance();
            myEtherBalance();
            myClientDetails();
            mySupplierDetails();

            myRFPs();
          } else {
            setLoginAlert(true);
          }
        });
      } else {
        setMetamaskAlert(true);
      }
    });
    FetchInitiationFee();
    FetchRegistrationFee();
    FetchRequirementFee();
    FetchResponseFee();
  }, []);

  const UpdateMyClientDetails = async () => {
    document.getElementById("divMyDetails").className = "d-none";
    document.getElementById("divUpdateDetails").className = "d-block";
    document.getElementById("btnUpdateClient").className = "d-block";
  };

  const UpdateMySupplierDetails = async () => {
    document.getElementById("divMyDetails").className = "d-none";
    document.getElementById("divUpdateDetails").className = "d-block";
    document.getElementById("btnUpdateSupplier").className = "d-block";
  };

  const FetchRFPDetails = async () => {

    let RFPId = document.getElementById("RFPid").value;
    let RFPStatus = document.getElementById("selRFPStatus").value;
    let searchString = { RFPId: RFPId, RFPStatus: RFPStatus };

    // console.log(RFPId)
    // console.log(RFPStatus)

    if(RFPId =="" && RFPStatus == 0){
      setModalText("Please select a search Criteria")
      setModalHeader("Fetch RFP Details")
      setModalSubHeader("RFP details not fetched")
      setShowModal(true)

    }else{

      setSpinnerDisplay(true);
      let rfpDetails = await smartContractObj.fetchRFPDetails(
        JSON.stringify(searchString)
      );
      setSpinnerDisplay(false);
  
      //console.log(rfpDetails);
  
      // document.getElementById("txtResults").className =
      //   "form-control d-block mb-2 ml-2 mt-2";
  
      let displayResult = "";
      let id = "";
      let RFPname = "";
      let RFPDesc = "";
      let ClientAddress = "";
  
      //console.log(rfpDetails.key);
      if(rfpDetails.key.length !=0 ){
        rfpDetails.key.forEach((rfpDetail) => {
          //console.log(rfpDetail);
          id = rfpDetail.RFPid;
          RFPname = rfpDetail.name;
          RFPDesc = rfpDetail.description;
          ClientAddress = rfpDetail.sender;
    
          displayResult =
            displayResult +
            `RFP Id : ${id} \nRFP Name : ${RFPname} \nRFP Desc : ${RFPDesc} \nClient Address : ${ClientAddress} \n \n`;
        });
    
        // document.getElementById("txtResults").value = displayResult;
        setModalText(displayResult)
        setModalHeader("Fetch RFP Details")
        setModalSubHeader("RFP Details  Status")
        setShowModal(true)
      }else{
         // document.getElementById("txtResults").value = displayResult;
         setModalText("No Results Fetched")
         setModalHeader("Fetch RFP Details")
         setModalSubHeader("RFP Details Status")
         setShowModal(true)

      }
  
      
    }

   
   
  };

  const BuyProcrain = async () => {

    let unitToBuy = document.getElementById("txtUnitToBuy").value;

    if(parseFloat(unitToBuy) > 0 && parseFloat(unitToBuy) <= parseFloat(etherBalance)){

      // Give Message That a Procrain Token will be deducted from your account
      // setModalText("Are yu sure you want to buy Procrain")
      // setModalHeader("Buy Procrain Token")
      // setModalSubHeader("A")
      // setShowModal(true)
      // alert("arre you sure you want to buy procrain")
      setSpiBuyProcrain(true);
      document.getElementById("btnBuyProcrain").className = "d-none";
      let buyProcrainResults = await smartContractObj.buyProcrain(unitToBuy);
      setSpiBuyProcrain(false);
      
      setModalText(buyProcrainResults)
      setModalHeader("Buy Procrain Token")
      setModalSubHeader("Buy Token Success Status")
      setShowModal(true)
      setTimeout(window.location.reload(),10000)
    }else{
      if(parseFloat(unitToBuy) <= 0) {
        setModalText("Please enter an amount greater than zero")
      }
      if(parseFloat(unitToBuy) >= parseFloat(etherBalance)) {
        //console.log(unitToBuy);
        //console.log(etherBalance);
        setModalText("You do not have enough Ether to buy the Procrain")
      }
      
      setModalHeader("Buy Procrain Token")
      setModalSubHeader("Input not correct")
      setShowModal(true)
      //window.location.reload()
      //setTimeout(window.location.reload(),10000); //this is to reload the page
    }
    

    //
    // console.log(buyProcrainResults);
    // document.getElementById("txtResults").value = buyProcrainResults;
  };

  const UpdateClientDetails = async () => {
    let cltName = document.getElementById("name").value;
    let cltDesc = document.getElementById("desc").value;

    setSpiUpdateClientDetails(true);
    let clientDetailsUpdate = await smartContractObj.updateClientDetails(
      cltName,
      cltDesc
    );
    setSpiUpdateClientDetails(false);

    // document.getElementById("name").value = "";
    // document.getElementById("desc").value = "";
    window.location.reload(); //this is to reload the page

    // document.getElementById("txtResults").value = JSON.stringify(
    //   clientDetailsUpdate
    // );
  };

  const RegisterSupplier = async () => {
    let suppName = document.getElementById("name").value;
    let suppDesc = document.getElementById("desc").value;

    if(procrainBalance >= 1) {
      setSpiRegisterSupplier(true);
      let registerSupplierStatus = await smartContractObj.registerSupplier(
        suppName,
        suppDesc
      );
      setSpiRegisterSupplier(false);
      setModalText(registerSupplierStatus)
      setModalHeader("Register as a Supplier")
      setModalSubHeader("Supplier Registration status")
      setShowModal(true)
      setTimeout(window.location.reload(),10000)
      // document.getElementById("txtResults").value = registerSupplierStatus;
    }else{
      setModalText("insufficient funds.Supplier registration fee is 1 PRC.")
      setModalHeader("Register as a supplier")
      setModalSubHeader("Supplier Registration status")
      setShowModal(true)
    }
   
  };

  const SwitchDetailsDisplay = async () => {
    let displayType = document.getElementById("btnHideDetails").text;
    if (displayType === "Show Details") {
      document.getElementById("divShowDetails").className = "d-block";
      document.getElementById("btnHideDetails").text = "Hide Details";
    } else {
      document.getElementById("divShowDetails").className = "d-none";
      document.getElementById("btnHideDetails").text = "Show Details";
    }
  };

  let infoHeader = "";
  let infoText = "";
  let infoType = "";

  if (metamaskAlert) {
    infoHeader = "Metamask not installed";
    //infoText = txtMetamaskNotInstalled;
    infoText = "Please install metamask to see details";
    infoType = "btn btn-danger";
  } else if (loginAlert) {
    infoHeader = "Login to Metamask";
    infoText = "Please log into to Metamask to access this page";
    infoType = "btn btn-info";
  }

  const animationVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 0.9},
    whilehover: {scale:1.2, opacity: 1}
  }

  return (
    <Fragment>
      <a
        id="btnHideDetails"
        href="#"
        role="button"
        onClick={SwitchDetailsDisplay}
        className="d-block"
      >
        Hide Details
      </a>
      {showModal ? (<ProcrainModal header={modalHeader} subHeader={modalSubHeader} text={modalText} onClose={setShowModal}/>) : (null)}
      <div>
        <div id="divShowDetails" className="d-block">
          <div className="row">
            <div className="card-group">
              <div id="divMyFinance" className="card  mr-2">
                {/* <img
                className="card-img-top"
                src={imgSlider3}
                alt="Card image cap"
                  /> */}
                <div className="card-body">
                  <h5 className="card-title">My Financials</h5>
                  {metamaskAlert || loginAlert ? (
                    <Info
                      header={infoHeader}
                      info={infoText}
                      type={infoType}
                    ></Info>
                  ) : (
                    <div>
                      <span className="badge badge-light">
                        Ether balance : {etherBalance} ETH
                      </span>
                      <span className="badge badge-light">
                        Procrain balance : {procrainBalance} PRC
                      </span>
                      {/* <span className="badge badge-light">1 PRC = 1 ETH</span> */}
                      <div className="input-group mt-2">
                        {spiBuyProcrain ? (
                          <Spinner isLarge={false} />
                        ) : (
                          <input
                            id="txtUnitToBuy"
                            className="form-control"
                            placeholder="Procrain to buy"
                            aria-label="Procrain to buy"
                            aria-describedby="basic-addon2"
                          ></input>
                        )}
                        <div className="input-group-append">
                          <button
                            id="btnBuyProcrain"
                            className="btn btn-outline-secondary"
                            type="button"
                            onClick={BuyProcrain}
                          >
                            Buy
                          </button>
                        </div>
                      </div>
                      
                      <motion.p className="mt-2 d-sm-block text-primary"
                      variants={animationVariants}
                      initial="initial"
                      animate="animate"
                      whileHover="whilehover"
                      transition={{repeat:Infinity, duration: 2}}>
                      <BsInfoCircle/> The details as on Rinkeby Testnet
                      </motion.p>
                      <motion.p className="mt-2 d-sm-block text-primary"
                      variants={animationVariants}
                      initial="initial"
                      animate="animate"
                      whileHover="whilehover"
                     >
                      <BsInfoCircle/> 1 PRC = 1 ETH
                      </motion.p>
                    </div>
                  )}
                </div>
              </div>

              <div id="divMyCredentials" className="card  mr-2">
                {/* <img
                className="card-img-top"
                src={imgSlider3}
                alt="Card image cap"
              /> */}
                <div className="card-body">
                  <h5 className="card-title">My Display Details</h5>
                  {metamaskAlert || loginAlert ? (
                    <Info
                      header={infoHeader}
                      info={infoText}
                      type={infoType}
                    ></Info>
                  ) : (
                    <div>
                      <div id="divMyDetails">
                        Client Details :<br></br>
                        <span className="badge badge-light">
                          {`\n Name : ${clientName} Desc :${clientDesc}`}
                        </span>
                        <a
                          id="btnUpdateClientDetails"
                          href="#"
                          role="button"
                          onClick={UpdateMyClientDetails}
                          className="d-block"
                        >
                         
                          Update Client Details
                        </a>
                        Supplier detail: <br></br>
                        <span className="badge badge-light">
                          {`\n Name : ${supplierName} \n Desc :${supplierDesc}`}
                        </span>
                        <a
                          id="btnUpdateSupplierDetails"
                          href="#"
                          role="button"
                          onClick={UpdateMySupplierDetails}
                          className="d-block"
                        >
                           {supplierName === "Not Set" ? ("Register as Supplier") :("Update Supplier Details")}
                          
                        </a>
                      </div>
                      <div id="divUpdateDetails" className="d-none">
                        <div className="input-group mt-2">
                          <input
                            type="text"
                            id="name"
                            className="form-control"
                            placeholder="Name"
                            aria-label="Name"
                            aria-describedby="basic-addon2"
                          />
                          <br></br>
                          <input
                            type="text"
                            className="form-control"
                            id="desc"
                            placeholder="Description"
                            aria-label="Description"
                            aria-describedby="basic-addon2"
                          />
                          <div className="input-group-append">
                            {spiUpdateClientDetails ? (
                              <Spinner isLarge={false} />
                            ) : (
                              <button
                                id="btnUpdateClient"
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={UpdateClientDetails}
                                className="d-none"
                              >
                                Update Client
                              </button>
                            )}
                            {spiRegisterSupplier ? (
                              <Spinner isLarge={false} />
                            ) : (
                              <button
                                id="btnUpdateSupplier"
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={RegisterSupplier}
                                className="d-none"
                              >
                                 {supplierName === "Not Set" ? ("Register as Supplier") :("Update Supplier")}
                                
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div id="divFeeDetails" className="card  mr-2">
                {/* <img
                className="card-img-top"
                src={imgSlider3}
                alt="Card image cap"
              /> */}
                <div className="card-body">
                  <h5 className="card-title">Procrain Fee</h5>
                  <span className="badge badge-light">
                    RFP Intitiation Fee: {rfPInitiationFee} PRC
                  </span>
                  <span className="badge badge-light">
                    Supplier Registration Fee : {supplierRegistrationFee} PRC
                  </span>
                  <span className="badge badge-light">
                    Raising Response Fee: {raisingRequirementFee} PRC
                  </span>
                  <span className="badge badge-light">
                    Responding Response Fee: {respondingRequirementFee} PRC
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <div>
        <div className="bg-info text-white rounded justify-content-md-center">
      
          <div className="row">
            <div className="col-sm-3 ml-2 p-1">
              <div>
                <input
                  name="RFPid"
                  id="RFPid"
                  placeholder="RFP ID"
                  className="form-control mb-2 mt-2 mr-3"
                ></input>
              </div>
            </div>
            <div className="col-sm-3 ml-2 p-1">
              <div>
                <select
                  id="selRFPStatus"
                  className="form-control mb-2 mt-2 mr-3"
                  name="selRFPStatus"
                >
                  <option value="0">Select Status</option>
                  <option value="Initiated">Initiated</option>
                  <option value="Released">Released</option>
                  <option value="Closed">Closed</option>
                  <option value="Discarded">Discarded</option>
                </select>
              </div>
            </div>
            <div className="col-sm-3 m-2 p-1">
              <button
                id="btnFetchRFPDetails"
                className="btn btn-primary mb-2 mt-2  ml-2 mr-3"
                onClick={FetchRFPDetails}
              >
                Fetch RFP details
              </button>
            </div>
          </div>
        </div>
        <br></br>
        <div className="form-group bg-light rounded justify-content-md-center">
          <div className="row  mb-2 mt-2 ml-2 mr-2 ">
            <div id="divMyRFPs" className="col">
              <Table
                id="tblMyDeals"
                className="form-control table-bordered"
                columns={columns}
                data={data}
              />
            </div>
          </div>
          <br></br>
        </div>
      </div>
    </Fragment>
  );
};

export default Procrain;
