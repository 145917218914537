import React, { Fragment, useState, useEffect } from "react";

import { Link } from "react-router-dom";

import smartContractObj from "../../smartcontract/SmartContract";
import Config from "../../smartcontract/config.json";
import ProcrainToken from "../animations/procrainToken"

const Navbar = () => {
  let [isOwner, setOwner] = useState(false);

  useEffect(() => {
    // Check if the contract owner
    // IF not the owner then hide the start and stop procrain button
    let fetchWeb3 = async () => {
      // You can await here
      let web3 = await smartContractObj.connectWeb3();
      let account = await smartContractObj.getaccount(web3);

      const config = Config["owner"];

      if (account[0] === config.address) {
        setOwner(true);
      }
    };
    fetchWeb3();
  });

  return (
    <Fragment>
      <nav className="navbar navbar-expand-md fixed-top navbar-dark bg-dark justify-content-between">
     
        <span className="navbar-brand mb-0 h1">
        <a href="/"><ProcrainToken strokeColor="white" /></a>
        <a className="navbar-brand h3" href="/">     
         P R O C R A I N
        </a>
        </span>
       <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
            <Link
                className="dropdown-item dropdown-toggle bg-dark"
                to="/procrain"
                id="navbarDropdownProcrain"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                My Procrain
              </Link>
              <div
                className="dropdown-menu bg-dark"
                aria-labelledby="navbarDropdownProcrain"
              >
                <Link className="dropdown-item" to="/procrain">
                  Home
                </Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/client">
                  Client
                </Link>
                <Link className="dropdown-item" to="/supplier">
                  Supplier
                </Link>
               
                {isOwner ? (
                  <Link className="dropdown-item" to="/owner">
                    Owner
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </li>
            <li className="nav-item dropdown">
              <Link className=" dropdown-item bg-dark" to="/blog">
                    About Procrain
                  </Link>
            </li>
            {/* <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle bg-dark"
              to="#"
              id="navbarDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Basics
            </Link>
            <div
              className="dropdown-menu bg-dark"
              aria-labelledby="navbarDropdown"
            >
              <Link className="dropdown-item" to="/hash">
                Hash
              </Link>
              <Link className="dropdown-item" to="/block">
                Block
              </Link>
              <Link className="dropdown-item" to="/wallet">
                Wallet
              </Link>
              <div className="dropdown-divider"></div>
              <Link className="dropdown-item" to="/verify">
                Verification
              </Link>
              <Link className="dropdown-item" to="/blockchain">
                Blockchain
              </Link>
            </div>
          </li> */}
            {/* <li className="nav-item">
            <Link className="nav-link" to="/login">
              Login
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/register">
              Register
            </Link>
          </li> */}
          </ul>
          {/* <form className="form-inline my-2 my-lg-0">
          <input
            className="form-control mr-sm-2"
            type="search"
            placeholder="Search"
            aria-label="Search"
          ></input>
          <button
            className="btn btn-outline-success my-2 my-sm-0"
            type="submit"
          >
            Search
          </button>
        </form> */}
        </div>
      </nav>
    </Fragment>
  );
};

export default Navbar;
