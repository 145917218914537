//import React, { useState } from "react";
import Procrain from "./Procrain.json";
import ProcrainToken from "./ProcrainToken.json";
//import Procrain from "../../../build/contracts/Procrain.json";
import Config from "./config.json";
import Web3 from "web3";
import abiDecoder from "abi-decoder";

let smartContractObj = {};

/********************************************** */
/*** Function to connect to web3 ***************/
/********************************************** */

const connectWeb3 = async () => {
  //const config = Config["localhost"];
  const config = Config["rinkeby"];
  let web3Obj;
  abiDecoder.addABI(Procrain.abi);

  if (window.ethereum) {
    // use metamask's providers
    // modern browsers
    web3Obj = new Web3(window.ethereum);
    // Request accounts access
    try {
      window.ethereum.enable();
    } catch (error) {
      console.error("User denied access to accounts");
    }
  } else if (window.web3) {
    // legacy browsers
    //web3Obj = new Web3(web3.currentProvider);
  } else {
    // fallback for non dapp browsers
    web3Obj = new Web3(new Web3.providers.HttpProvider(config.url));
  }
  return web3Obj;
};

/********************************************** */
/*** Function to Load Procrain contract ***************/
/********************************************** */

const loadContract = async (web3) => {
  //const config = Config["localhost"];
  const config = Config["rinkeby"];
  //console.log(config);
  let contract = await new web3.eth.Contract(
    Procrain.abi,
    config.contractAddress
  );
  //console.log(contract);
  return contract;
};

/********************************************** */
/*** Function to Load TOKEN contract ***************/
/********************************************** */

const loadProcrainTokenContract = async (web3) => {
  //const config = Config["localhost"];
  const config = Config["rinkeby"];
  //console.log(config);
  let contract = await new web3.eth.Contract(
    ProcrainToken.abi,
    config.tokenAddress
  );
  //console.log(contract);
  return contract;
};

/********************************************** */
/*** Function to Get the Acoount owner************/
/*** THIS is the logged in accoun in the metamask*/
/********************************************** */

const getaccount = async (web3) => {
  let accounts;
  try {
    await web3.eth.getAccounts(async (error, accts) => {
      if (!error) {
        //console.log(accts);
        accounts = accts;
        return accts;
      } else {
        console.error(error);
      }
    });
  } catch (error) {
    console.log(error);
  }
  return accounts;
};

/********************************************** */
/*** Function to Instantiate the Contract*******/
/*** THIS IS USED IN OTHER PAGES - DO  NOT DELETE/
/********************************************** */

smartContractObj.connectWeb3 = async () => {
  let web3 = await connectWeb3();
  return web3;
};

smartContractObj.getaccount = async (web3) => {
  let account = await getaccount(web3);
  return account;
};


/********************************************** */
/*** Function to CHECK IF METAMASK IS INSTALLED*/
/********************************************** */
smartContractObj.isMetaMaskInstalled = async () => {
  let web3 = await connectWeb3();
  if (
    typeof web3 !== "undefined" &&
    web3.currentProvider.isMetaMask === true
  ) {
    return true;
  } else {
    return false;
  }
};

/********************************************** */
/*** Function to CHECK IF  LOGGED IN METAMASK*/
/********************************************** */

smartContractObj.isLoggedIn = async () => {
  let web3 = await connectWeb3();
  let account = await getaccount(web3);
  //console.log(account);
  if (account[0]) {
    return true;
  } else {
    return false;
  }
};


/********************************************** */
/*** Function to STOP PROCRAIN BY CONTRACT OWNER*/
/********************************************** */
smartContractObj.stopProcrain = async () => {
  try {
    let web3 = await connectWeb3();
    let account = await getaccount(web3);
    let contract = await loadContract(web3, account[0]);

    let contractStatus = await contract.methods.setOperatingStatus(false).send({
      from: account[0],
      gas: 6750000,
      gasPrice: web3.eth.gasPrice,
    });

    //console.log(account[0]);
    //console.log(contract);
    return {
      key: contractStatus,
    };
  } catch (error) {
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to START PROCRAIN BY CONTRACT OWNER*/
/********************************************** */

smartContractObj.startProcrain = async () => {
  try {
    let web3 = await connectWeb3();
    let account = await getaccount(web3);
    let contract = await loadContract(web3, account[0]);
    //console.log(account[0]);
    //console.log(contract);
    let contractStatus = await contract.methods.setOperatingStatus(true).send({
      from: account[0],
      gas: 6750000,
      gasPrice: web3.eth.gasPrice,
    });

    return {
      key: contractStatus,
    };
  } catch (error) {
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to SET RFP INITATION FEE  BY CONTRACT OWNER*/
/********************************************** */
smartContractObj.setRFPInitiationFee = async (initiationFee) => {
  try {
    let web3 = await connectWeb3();
    let account = await getaccount(web3);
    let contract = await loadContract(web3, account[0]);

    //.log(web3.utils.toWei(initiationFee));

    let initiationFeeStatus = await contract.methods
      .setRFPInitaiationFee(web3.utils.toWei(initiationFee))
      .send({
        from: account[0],
        gas: 6750000,
        gasPrice: web3.eth.gasPrice,
      });

    //console.log(initiationFeeStatus);
    return {
      key: initiationFeeStatus,
    };
  } catch (error) {
    console.log(error);
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to GET RFP INITIATION FEE  */
/********************************************** */
smartContractObj.getRFPInitiationFee = async () => {
  try {
    let web3 = await connectWeb3();
    let account = await getaccount(web3);
    let contract = await loadContract(web3, account[0]);

    let initiationFee = await contract.methods.rfpInitiationFee().call();

    //console.log(initiationFee);
    return {
      key: initiationFee,
    };
  } catch (error) {
    console.log(error);
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to SET SUPPLIER REGISTRATION FEE CONTRACT OWNER*/
/********************************************** */
smartContractObj.setSupplierRegistrtationFee = async (
  supplierRegistrationFee
) => {
  try {
    let web3 = await connectWeb3();
    let account = await getaccount(web3);
    let contract = await loadContract(web3, account[0]);

    //console.log(web3.utils.toWei(supplierRegistrationFee));

    let registrtationFeeStatus = await contract.methods
      .setSupplierRegistrationFee(web3.utils.toWei(supplierRegistrationFee))
      .send({
        from: account[0],
        gas: 6750000,
        gasPrice: web3.eth.gasPrice,
      });

    // console.log(registrtationFeeStatus);
    return {
      key: registrtationFeeStatus,
    };
  } catch (error) {
    console.log(error);
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to GET SUPPLIER REGISTRATION FEE  */
/********************************************** */
smartContractObj.getSupplierRegistartionFee = async () => {
  try {
    let web3 = await connectWeb3();
    let account = await getaccount(web3);
    let contract = await loadContract(web3, account[0]);

    let registrationFee = await contract.methods
      .supplierRegistrationFee()
      .call();

    //console.log(registrationFee);
    return {
      key: registrationFee,
    };
  } catch (error) {
    console.log(error);
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to SET REQUIREMENT FEE BY CONTRACT OWNER*/
/********************************************** */
smartContractObj.setRequirementFee = async (requirementFee) => {
  try {
    let web3 = await connectWeb3();
    let account = await getaccount(web3);
    let contract = await loadContract(web3, account[0]);

    //console.log(web3.utils.toWei(supplierRegistrationFee));

    let requirementFeeStatus = await contract.methods
      .setRaisingRequirementFee(web3.utils.toWei(requirementFee))
      .send({
        from: account[0],
        gas: 6750000,
        gasPrice: web3.eth.gasPrice,
      });

    // console.log(registrtationFeeStatus);
    return {
      key: requirementFeeStatus,
    };
  } catch (error) {
    console.log(error);
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to GET REQUIREMENT FEE  */
/********************************************** */
smartContractObj.getRequirementFee = async () => {
  try {
    let web3 = await connectWeb3();
    let account = await getaccount(web3);
    let contract = await loadContract(web3, account[0]);

    let requirementFee = await contract.methods.raisingRequirementFee().call();

    //console.log(requirementFee);
    return {
      key: requirementFee,
    };
  } catch (error) {
    console.log(error);
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to SET RESPONSE FEE BY CONTRACT OWNER*/
/********************************************** */
smartContractObj.setResponseFee = async (responseFee) => {
  try {
    let web3 = await connectWeb3();
    let account = await getaccount(web3);
    let contract = await loadContract(web3, account[0]);

    //console.log(web3.utils.toWei(supplierRegistrationFee));

    let responseFeeStatus = await contract.methods
      .setRespondingRequirementFee(web3.utils.toWei(responseFee))
      .send({
        from: account[0],
        gas: 6750000,
        gasPrice: web3.eth.gasPrice,
      });

    // console.log(registrtationFeeStatus);
    return {
      key: responseFeeStatus,
    };
  } catch (error) {
    console.log(error);
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to GET RESPONSE FEE  */
/********************************************** */
smartContractObj.getResponseFee = async () => {
  try {
    let web3 = await connectWeb3();
    let account = await getaccount(web3);
    let contract = await loadContract(web3, account[0]);

    let responseFee = await contract.methods.respondingRequirementFee().call();

    //console.log(requirementFee);
    return {
      key: responseFee,
    };
  } catch (error) {
    console.log(error);
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to BUY PROCRAIN TOEKEN **********/
/********************************************** */
smartContractObj.buyProcrain = async (unitToBuy) => {
  try {
    let web3 = await connectWeb3();
    let account = await getaccount(web3);
    let contract = await loadContract(web3, account[0]);

    // unitToBuy * GetProcrainPrice
    // uint256 amountTobuy = msg.value/tokenPrice;

    let buyProcrainResult = await contract.methods.buyProcrain().send({
      from: account[0],
      gas: 6750000,
      value: web3.utils.toWei(unitToBuy),
      gasPrice: web3.eth.gasPrice,
    });

    console.log(buyProcrainResult);
    return {
      key: buyProcrainResult,
    };
  } catch (error) {
    console.log(error);
    return {
      key: error,
    };
  }
};

/********************************************** */
/*Function to get all the RFPs from the logged in user **/
/********************************************** */

smartContractObj.getRFPsforAnUser = async () => {
  try {
    let web3 = await connectWeb3();
    let account = await getaccount(web3);
    let contract = await loadContract(web3);

    let userRFPs = [];

    if (account) {
      // GET INITIATED RFP - FOR CLIENT
      await contract
        .getPastEvents(
          "RFPInitiated",
          {
            filter: { sender: account[0] },
            fromBlock: 0,
            toBlock: "latest",
          },
          (errors, events) => {
            if (!errors) {
              let RFPDesc = events;
            } else {
              console.log(errors);
            }
          }
        )
        .then(async function (events) {
          events.forEach((event) => {
            let RFPdetails = JSON.parse(
              JSON.stringify(
                event.returnValues,
                ["RFPid", "name", "description", "sender"],
                2
              )
            );
            RFPdetails["status"] = "Initiated";
            userRFPs.push(RFPdetails);
          });
        });

      // GET RELEASED RFP - FOR CLIENT
      await contract
        .getPastEvents(
          "RFPReleased",
          {
            filter: { sender: account[0] },
            fromBlock: 0,
            toBlock: "latest",
          },
          (errors, events) => {
            if (!errors) {
              let RFPDesc = events;
            } else {
              console.log(errors);
            }
          }
        )
        .then(async function (events) {
          events.forEach((event) => {
            let RFPdetails = JSON.parse(
              JSON.stringify(
                event.returnValues,
                ["RFPid", "name", "description", "sender"],
                2
              )
            );
            RFPdetails["status"] = "Released";

            // IF the RFPId exist then change the old details with new one
            // THIS CAN BE LATER  USED TO AUDIT TRAIL THE RFP JOURNEY
            //https://stackoverflow.com/questions/12462318/find-a-value-in-an-array-of-objects-in-javascript#:~:text=As%20per%20ECMAScript%206%2C%20you%20can%20use%20the%20findIndex%20function.&text=const%20search%20%3D%20what%20%3D%3E%20array,item%20was%20found%20or%20not.

            userRFPs.find((o, i) => {
              if (o.RFPid === RFPdetails["RFPid"]) {
                userRFPs[i] = RFPdetails;
                return true; // stop searching
              }
            });
          });
        });

      // GET CLOSED RFP - FOR CLIENT
      await contract
        .getPastEvents(
          "RFPClosed",
          {
            filter: { sender: account[0] },
            fromBlock: 0,
            toBlock: "latest",
          },
          (errors, events) => {
            if (!errors) {
              let RFPDesc = events;
            } else {
              console.log(errors);
            }
          }
        )
        .then(async function (events) {
          events.forEach((event) => {
            let RFPdetails = JSON.parse(
              JSON.stringify(
                event.returnValues,
                ["RFPid", "name", "description", "sender"],
                2
              )
            );
            RFPdetails["status"] = "Closed";
            // IF the RFPId exist then change the old details with new one
            // THIS CAN BE LATER  USED TO AUDIT TRAIL THE RFP JOURNEY
            //https://stackoverflow.com/questions/12462318/find-a-value-in-an-array-of-objects-in-javascript#:~:text=As%20per%20ECMAScript%206%2C%20you%20can%20use%20the%20findIndex%20function.&text=const%20search%20%3D%20what%20%3D%3E%20array,item%20was%20found%20or%20not.

            userRFPs.find((o, i) => {
              if (o.RFPid === RFPdetails["RFPid"]) {
                userRFPs[i] = RFPdetails;
                return true; // stop searching
              }
            });
          });
        });

      // GET DISCARDED RFP - FOR CLIENT
      await contract
        .getPastEvents(
          "RFPDiscarded",
          {
            filter: { sender: account[0] },
            fromBlock: 0,
            toBlock: "latest",
          },
          (errors, events) => {
            if (!errors) {
              let RFPDesc = events;
            } else {
              console.log(errors);
            }
          }
        )
        .then(async function (events) {
          events.forEach((event) => {
            let RFPdetails = JSON.parse(
              JSON.stringify(
                event.returnValues,
                ["RFPid", "name", "description", "sender"],
                2
              )
            );
            RFPdetails["status"] = "Discarded";
            // IF the RFPId exist then change the old details with new one
            // THIS CAN BE LATER  USED TO AUDIT TRAIL THE RFP JOURNEY
            //https://stackoverflow.com/questions/12462318/find-a-value-in-an-array-of-objects-in-javascript#:~:text=As%20per%20ECMAScript%206%2C%20you%20can%20use%20the%20findIndex%20function.&text=const%20search%20%3D%20what%20%3D%3E%20array,item%20was%20found%20or%20not.

            userRFPs.find((o, i) => {
              if (o.RFPid === RFPdetails["RFPid"]) {
                userRFPs[i] = RFPdetails;
                return true; // stop searching
              }
            });
          });

          // GET BIDDED RFP - FOR SUPPLIER
          await contract
            .getPastEvents(
              "RFPBidParticipationInitiated",
              {
                filter: { supplierAddress: account[0] },
                fromBlock: 0,
                toBlock: "latest",
              },
              (errors, events) => {
                if (!errors) {
                  let RFPDesc = events;
                } else {
                  console.log(errors);
                }
              }
            )
            .then(async function (events) {
              events.forEach(async (event) => {
                let RFPdetails = JSON.parse(
                  JSON.stringify(
                    event.returnValues,
                    ["RFPid", "RFPParticipationRefNo", "supplierAddress"],
                    2
                  )
                );
                RFPdetails["description"] =
                  "RFP Participation Ref No: " +
                  RFPdetails["RFPParticipationRefNo"];

                // let rfpParticipationStatus = await smartContractObj.fetchBidStatusForSupplier(parseInt(RFPdetails["RFPParticipationRefNo"]))
                // let txtStatus = ""
                // if(rfpParticipationStatus.key.name){
                //   if (rfpParticipationStatus.key.name == "acceptBidParticipation") {
                //     txtStatus = "Bid Accepted";
                    
                //   } else if (rfpParticipationStatus.key.name == "rejectBidParticipation") {
                //     txtStatus = "Bid Rejected";
                //   } else  {
                //     txtStatus = "Bid Request Pending"; 
                //   }
                // }else{
                //   txtStatus = "Bid Request Pending";
                // }


                delete RFPdetails["RFPParticipationRefNo"];

                RFPdetails["sender"] = RFPdetails["supplierAddress"];
                delete RFPdetails["supplierAddress"];

                RFPdetails["name"] = "";
                RFPdetails["status"] = "Bidding";
                //RFPdetails["status"] = txtStatus; //Get the status by calling fetchBidStatusForSupplier
                //console.log("in Supplier bid RFP");
                //console.log(RFPdetails);
                userRFPs.push(RFPdetails);
                // IF the RFPId exist then change the old details with new one
                // THIS CAN BE LATER  USED TO AUDIT TRAIL THE RFP JOURNEY
                //https://stackoverflow.com/questions/12462318/find-a-value-in-an-array-of-objects-in-javascript#:~:text=As%20per%20ECMAScript%206%2C%20you%20can%20use%20the%20findIndex%20function.&text=const%20search%20%3D%20what%20%3D%3E%20array,item%20was%20found%20or%20not.
                //userRFPs.push(RFPdetails)
                userRFPs.find((o, i) => {
                  if (o.RFPid === RFPdetails["RFPid"]) {
                    userRFPs[i] = RFPdetails;
                    return true; // stop searching
                  }
                });
              });
            });
        });
      //console.log(userRFPs);
      //Return those rentries belong to the loggedin user

      let loggedInUserRFPs = userRFPs.filter((rfp) => {
        return rfp.sender == account[0];
      });
      return {
        key: loggedInUserRFPs,
      };
    }
  } catch (error) {
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to Initiate a new RFP***********/
/********************************************** */
smartContractObj.initiateRFP = async (RFPName, RFPDesc) => {
  try {
    let web3 = await connectWeb3();
    let account = await getaccount(web3);
    let contract = await loadContract(web3);

    console.log(account[0]);
    //console.log(contract);

    // console.log(RFPName);

    // console.log(RFPDesc);

    if (account) {
      const rfpStatus = await contract.methods
        .initiateRFP(RFPName, RFPDesc)
        .send({
          from: account[0],
          gas: 6750000,
          // gas: 150000000,
          // //gasPrice: "20000000000",
          gasPrice: web3.eth.gasPrice,
        });
      // .send({ from: account, gas: 3000000, gasPrice: "30000000000000" });
      //console.log(rfpStatus);
      //console.log(rfpStatus.transactionHash);
      //let txInfo = await web3.eth.getTransaction(rfpStatus.transactionHash);
      //.log(txInfo.input);
      //let txStringInfo = await abiDecoder.decodeMethod(txInfo.input);
      console.log(rfpStatus);
      return {
        // key: web3.toAscii(rfpDetails)
        key: rfpStatus,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to Change the Status of an RFP ****/
/********************************************** */

smartContractObj.changeRFPStatus = async (RFPid, status) => {
  try {
    let web3 = await connectWeb3();
    let account = await getaccount(web3);
    let contract = await loadContract(web3);

    let RFPStatus;

    if (status == "Release") {
      RFPStatus = await contract.methods.releaseRFP(parseInt(RFPid)).send({
        from: account[0],
        gas: 6750000,
        gasPrice: web3.eth.gasPrice,
      });
    }

    if (status == "Close") {
      //console.log("in close");
      RFPStatus = await contract.methods.closeRFP(parseInt(RFPid)).send({
        from: account[0],
        gas: 6750000,
        gasPrice: web3.eth.gasPrice,
      });
    }

    if (status == "Discard") {
      RFPStatus = await contract.methods.discardRFP(parseInt(RFPid)).send({
        from: account[0],
        gas: 6750000,
        gasPrice: web3.eth.gasPrice,
      });
    }

    //console.log(RFPStatus);

    return {
      key: RFPStatus,
    };
  } catch (error) {
    //console.log(error);
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to Fetch Details of an RFP***********/
/********************************************** */
smartContractObj.fetchRFPDetails = async (searchString) => {
  /*
  - From the Event log get the transaction hash filtering on RFPId
  - From that transaction Hash get the transaction details web3.eth.getTransaction
  -   */

  /*
    Need to show the current status of RFP
    It is better to fetch it directly from the contract to avoid complicated logic.
    In future the front end apps need to decide if they want to read from log and save gas or kee
    or keep front end code simple by dirctly fetching from the contract
  */
  try {
    let web3 = await connectWeb3();
    let account = await getaccount(web3);
    let contract = await loadContract(web3);
    let RFPDesc = [];

    //console.log(account);
    //console.log(contract);

    // https://codeburst.io/deep-dive-into-ethereum-logs-a8d2047c7371
    // https://github.com/trufflesuite/ganache-core/issues/295

    searchString = JSON.parse(searchString);
    let RFPid = Object.values(searchString)[0];
    let RFPStatus = Object.values(searchString)[1];

    if (account && RFPid) {
      await contract
        .getPastEvents(
          "RFPInitiated",
          {
            filter: { RFPid: [parseInt(RFPid)] },
            fromBlock: 0,
            toBlock: "latest",
          },
          (errors, events) => {
            if (!errors) {
              //RFPDesc = events;
            } else {
              console.log(errors);
            }
          }
        )
        .then(async function (events) {
          events.forEach((event) => {
            let RFPDetails = JSON.parse(
              JSON.stringify(
                event.returnValues,
                ["RFPid", "name", "description", "sender"],
                2
              )
            );
            RFPDesc.push(RFPDetails);
          });
          //console.log(RFPDesc);
        });
      return {
        key: RFPDesc,
      };
    }

    let eventName = "";

    if (RFPStatus == "Initiated") {
      eventName = "RFPInitiated";
    } else if (RFPStatus == "Released") {
      eventName = "RFPReleased";
    } else if (RFPStatus == "Closed") {
      eventName = "RFPClosed";
    } else if (RFPStatus == "Discarded") {
      eventName = "RFPDiscarded";
    }

    if (account && RFPStatus) {
      await contract
        .getPastEvents(
          eventName,
          {
            filter: {},
            fromBlock: 0,
            toBlock: "latest",
          },
          (errors, events) => {
            if (!errors) {
              //RFPDesc = events;
            } else {
              console.log(errors);
            }
          }
        )
        .then(async function (events) {
          //console.log(events);
          events.forEach((event) => {
            let RFPDetails = JSON.parse(
              JSON.stringify(
                event.returnValues,
                ["RFPid", "name", "description", "sender"],
                2
              )
            );
            RFPDesc.push(RFPDetails);
          });
          //console.log(RFPDesc);
        });
      return {
        key: RFPDesc,
      };
    }
  } catch (error) {
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to Update Client Details***********/
/********************************************** */

smartContractObj.updateClientDetails = async (clientName, clientDesc) => {
  try {
    let web3 = await connectWeb3();
    let account = await getaccount(web3);
    let contract = await loadContract(web3);

    //console.log(account);
    //console.log(contract);

    const clientUpdateStatus = await contract.methods
      .setClientDetails(clientName, clientDesc)
      .send({
        from: account[0],
        gas: 6750000,
        gasPrice: web3.eth.gasPrice,
      });

    console.log(clientUpdateStatus);

    return {
      key: clientUpdateStatus,
    };
  } catch (error) {
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to Fetch Client Details***********/
/********************************************** */
smartContractObj.fetchClientDetails = async () => {
  try {
    let web3 = await connectWeb3();
    let account = await getaccount(web3);
    let contract = await loadContract(web3);
    let clientDetails = "";

    //console.log(account);
    //console.log(contract);

    // the pastevent method will not work as different details would have been updated by different events
    // So this needs to be fetched from the contract as a function only which fetches the latest state for client

    //The above argument is not totally correct.
    // If the smart contract is so written that anytime we update the struct
    // it force user to update all the field.
    // This way the last update will always be latest and the past event will work

    // Logic: first checck the ClientUpdatedEvent and select the last entry.
    //IF nothing exist in the ClientUpdateEvent then check for ClientAdded Event
    //If nothing exist in Client Added event then ask to add Client

    //console.log(account[0]);

    if (account) {
      await contract
        .getPastEvents(
          // "ClientAdded",
          "ClientDetailsUpdated",
          {
            filter: { clientAddress: [account[0]] },
            fromBlock: 0,
            toBlock: "latest",
          },
          (errors, events) => {
            if (!errors) {
              // clientDetails = events;
            } else {
              //console.log(errors);
            }
          }
        )
        .then(async (events) => {
          //TODO:
          //Client details can be updated in 3 ways
          // If client raise and RFP then he is added in Clients mapping and ClientAdded event is raised
          // In such cases fetching the details from transaction hash will not work as the details
          // will be of RFP details because its invoked by initiateRFP process
          // Second is that client update his details without raising an RFP
          // In such cased ClientAdded event will be raised and transaction hash will give client details
          // As this has been raised by SetClientDetails
          // The third is that client updates its details after either first two. This case
          // The UpdateClient event will be invoked from SetClientDetails fucntion
          // Reading Client details will work here.
          // The first two scenario needs to be factored in

          if (events.length != 0) {
            let txStringInfo = await web3.eth.getTransaction(
              events.pop().transactionHash
            );
            clientDetails = await abiDecoder.decodeMethod(txStringInfo.input);
            //console.log(clientDetails);
          }
        });
    }

    // let clientDetails = await contract.methods.fetchClientDetails().call();
    // Reading from Contract is fetching null value.
    // console.log(clientDetails);

    return {
      key: clientDetails,
    };
  } catch (error) {
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to Appoint Advisor by the Client***/
/********************************************** */

smartContractObj.appointAdvisor = async (advisorAddress, RFPId) => {
  try {
    let web3 = await connectWeb3();
    let account = await getaccount(web3);
    let contract = await loadContract(web3);

    //console.log(account);
    //console.log(contract);

    const appointAdvisorStatus = await contract.methods
      .appointAdvisor(advisorAddress, parseInt(RFPId))
      .send({
        from: account[0],
        gas: 6750000,
        gasPrice: web3.eth.gasPrice,
      });

    //console.log(appointAdvisorStatus);

    return {
      key: appointAdvisorStatus,
    };
  } catch (error) {
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to Appoint Advisor by the Client***/
/********************************************** */

smartContractObj.updateAdvisor = async (advisorName, advisorDesc) => {
  // TODO: To give the error description on other than advisor is updating the details
  try {
    let web3 = await connectWeb3();
    let account = await getaccount(web3);
    let contract = await loadContract(web3);

    //console.log(account);
    //console.log(contract);

    const updateAdvisorStatus = await contract.methods
      .setAdvisorDetails(advisorName, advisorDesc)
      .send({
        from: account[0],
        gas: 6750000,
        gasPrice: web3.eth.gasPrice,
      });

    //console.log(updateAdvisorStatus);

    return {
      key: updateAdvisorStatus,
    };
  } catch (error) {
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to Fetch Advisor details        ***/
/********************************************** */

smartContractObj.fetchAdvisorDetails = async (advisorAddress) => {
  let web3 = await connectWeb3();
  let account = await getaccount(web3);
  let contract = await loadContract(web3);
  let advisorDetails = "";

  try {
    if (account) {
      await contract
        .getPastEvents(
          "AdvisorDetailsUpdated",
          {
            filter: { advisorAddress: advisorAddress },
            fromBlock: 0,
            toBlock: "latest",
          },
          (errors, events) => {
            if (!errors) {
              advisorDetails = events;
            } else {
              //console.log(errors);
            }
          }
        )
        .then(async function (events) {
          //console.log(events); // same results as the optional callback above
          // Take the last update and show
          let txStringInfo = await web3.eth.getTransaction(
            events.pop().transactionHash
          );
          advisorDetails = await abiDecoder.decodeMethod(txStringInfo.input);
          // console.log(advisorDetails);
        });
    }
    return {
      key: advisorDetails,
    };
  } catch (error) {
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to Register a Supplier          ***/
/********************************************** */

smartContractObj.registerSupplier = async (supplierName, supplierDesc) => {
  let web3 = await connectWeb3();
  let account = await getaccount(web3);
  let contract = await loadContract(web3);
  try {
    const registerSupplierStatus = await contract.methods
      .updateSupplier(supplierName, supplierDesc)
      .send({
        from: account[0],
        //value: "100000000000000000",
        gas: 6750000,
        gasPrice: web3.eth.gasPrice,
      });

    //console.log(registerSupplierStatus);

    return {
      key: registerSupplierStatus,
    };
  } catch (error) {
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function toRFetch Supplier Details         ***/
/********************************************** */

smartContractObj.fetchSupplierDetails = async () => {
  let web3 = await connectWeb3();
  let account = await getaccount(web3);
  let contract = await loadContract(web3);

  try {
    let supplierDetails = "";

    //console.log(account);
    //console.log(contract);

    // the pastevent method will not work as different details would have been updated by different events
    // So this needs to be fetched from the contract as a function only which fetches the latest state for client

    //The above argument is not totally correct. If the smart contract is so written that anytime we update the struct
    // Force user to update all the field. This way the last update will always be latest and the past event will work

    // Logic: first checck the ClientUpdatedEvent and select the last entry.
    //IF nothing exist in the ClientUpdateEvent then check for ClientAdded Event
    //If nothing exist in Client Added event then ask to add Client

    if (account) {
      await contract
        .getPastEvents(
          "SupplierAdded",
          {
            filter: { supplierAddress: [account[0]] },
            fromBlock: 0,
            toBlock: "latest",
          },
          (errors, events) => {
            if (!errors) {
              supplierDetails = events;
            } else {
              //console.log(errors);
            }
          }
        )
        .then(async function (events) {
          //console.log(events); // same results as the optional callback above
          // Take the last update and show
          if (events.length != 0) {
            let txStringInfo = await web3.eth.getTransaction(
              events.pop().transactionHash
            );
            supplierDetails = await abiDecoder.decodeMethod(txStringInfo.input);
            // console.log(supplierDetails);
          }
        });
    }

    // let clientDetails = await contract.methods.fetchSupplierDetails().send({
    //   from: account[0],
    //   gas: 6750000,
    //   gasPrice: web3.eth.gasPrice
    // });

    //console.log(clientDetails);

    return {
      key: supplierDetails,
    };
  } catch (error) {
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to Bid for an RFP by a Supplier ***/
/********************************************** */

smartContractObj.bidRFP = async (RFPId) => {
  let web3 = await connectWeb3();
  let account = await getaccount(web3);
  let contract = await loadContract(web3);

  try {
    const bidRFPStatus = await contract.methods.bidRFP(RFPId).send({
      from: account[0],
      gas: 6750000,
      gasPrice: web3.eth.gasPrice,
    });

    //console.log(bidRFPStatus);

    return {
      key: bidRFPStatus,
    };
  } catch (error) {
    return {
      key: error,
    };
  }
};

/*********************************************************************** */
/*** Function for a Client/Advisor to fetch the bid participation Interest **/
/********************************************** **************************/

smartContractObj.fetchBidParticipation = async (rfpId) => {
  let web3 = await connectWeb3();
  let account = await getaccount(web3);
  let contract = await loadContract(web3);

  let bidPariticipation = "";

  // A combination of Bid Pariticipation and Bid accepted.

  try {
    if (account) {
      await contract
        .getPastEvents(
          "RFPBidParticipationInitiated",
          {
            filter: { RFPid: parseInt(rfpId) },
            fromBlock: 0,
            toBlock: "latest",
          },
          (errors, events) => {
            if (!errors) {
              bidPariticipation = events;
              //console.log(events);
            } else {
              console.log(errors);
            }
          }
        )
        .then(async function (events) {
          //console.log(events); // same results as the optional callback above
          // Take the last update and show
          // let txStringInfo = await web3.eth.getTransaction(
          //   events.pop().transactionHash
          // );
          // bidPariticipation = await abiDecoder.decodeMethod(txStringInfo.input);
          //console.log(bidPariticipation);
        });
    }
    return {
      key: bidPariticipation,
    };
  } catch (error) {
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to accept a bid from a supplier ***/
/********************************************** */

smartContractObj.acceptBidParticipation = async (participationRefNo) => {
  let web3 = await connectWeb3();
  let account = await getaccount(web3);
  let contract = await loadContract(web3);

  try {
    const acceptBidParticipationStatus = await contract.methods
      .acceptBidParticipation(participationRefNo)
      .send({
        from: account[0],
        gas: 6750000,
        gasPrice: web3.eth.gasPrice,
      });

    //console.log(acceptBidParticipationStatus);

    return {
      key: acceptBidParticipationStatus,
    };
  } catch (error) {
    return {
      key: error,
    };
  }
};



/********************************************** */
/*** Function to Reject a bid from a supplier ***/
/********************************************** */

smartContractObj.rejectBidParticipation = async (participationRefNo) => {
  let web3 = await connectWeb3();
  let account = await getaccount(web3);
  let contract = await loadContract(web3);

  try {
    const rejectBidParticipationStatus = await contract.methods
      .rejectBidParticipation(participationRefNo)
      .send({
        from: account[0],
        gas: 6750000,
        gasPrice: web3.eth.gasPrice,
      });

    //console.log(acceptBidParticipationStatus);

    return {
      key: rejectBidParticipationStatus,
    };
  } catch (error) {
    return {
      key: error,
    };
  }
};




/********************************************** */
/*** Function for a Supplier to check the status of Bid **/
/********************************************** */

smartContractObj.fetchBidStatusForSupplier = async (participationRefNo) => {
  let web3 = await connectWeb3();
  let account = await getaccount(web3);
  let contract = await loadContract(web3);
  let bidStatusForSupplier = "";

  try {
    if (account) {

      // THE ACCEPT EVENT IS RAISED
      await contract
        .getPastEvents(
          "RFPBidParticipationAccepted",
          {
            filter: { participationConfNo: participationRefNo },
            fromBlock: 0,
            toBlock: "latest",
          },
          (errors, events) => {
            if (!errors) {
              bidStatusForSupplier = events;
            } else {
              console.log(errors);
            }
          }
        )
        .then(async function (events) {
         // same results as the optional callback above
          // Take the last update and show
          // IF the event exist for this Participation reference number then the Participation is accepted
          if(events.length !== 0){
            let txStringInfo = await web3.eth.getTransaction(
              events.pop().transactionHash
            );
            //bidStatusForSupplier.push(events.pop())
            bidStatusForSupplier = await abiDecoder.decodeMethod(
              txStringInfo.input
            );
            console.log(bidStatusForSupplier);
          }
        });

        // THE REJECT EVENT IS RAISED. CHECK ONLY IF NOT ACCEPTED
        //CHECK IF CLIENT CAN REJECT AFTER ACCEPTING. IF YES CHANGE THE IF CONDITION
      
        await contract
        .getPastEvents(
          "RFPBidParticipationRejected",
          {
            filter: { participationConfNo: participationRefNo },
            fromBlock: 0,
            toBlock: "latest",
          },
          (errors, events) => {
            if (!errors) {
              if(!bidStatusForSupplier){
                bidStatusForSupplier = events;
              }
             
            } else {
              console.log(errors);
            }
          }
        )
        .then(async function (events) {
         // same results as the optional callback above
          // Take the last update and show
          // IF the event exist for this Participation reference number then the Participation is accepted
          if(events.length !== 0){
            let txStringInfo = await web3.eth.getTransaction(
              events.pop().transactionHash
            );
            //bidStatusForSupplier.push(events.pop())
            bidStatusForSupplier = await abiDecoder.decodeMethod(
              txStringInfo.input
            );
            console.log(bidStatusForSupplier);
          }
         
        });
      
     

    }
    return {
      key: bidStatusForSupplier,
    };
  } catch (error) {
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function for a adding a requirement for an RFP **/
/********************************************** */

smartContractObj.addRequirement = async (
  rfpID,
  reqName,
  reqDesc,
  reqWeight,
  reqPoint,
  reqActive
) => {
  let web3 = await connectWeb3();
  let account = await getaccount(web3);
  let contract = await loadContract(web3);
  // console.log(
  //   rfpID + "-" + reqName + "-" + reqDesc + "-" + reqWeight + "-" + reqActive
  // );
  try {
    const addRequirementStatus = await contract.methods
      .createRFPRequirement(
        parseInt(rfpID),
        reqName,
        reqDesc,
        parseInt(reqWeight),
        parseInt(reqPoint),
        reqActive
      )
      .send({
        from: account[0],
        //value: "100000000000000000",
        gas: 6750000,
        gasPrice: web3.eth.gasPrice,
      });
    // console.log(addRequirementStatus);
    return {
      key: addRequirementStatus,
    };
  } catch (error) {
    console.log(error);
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to fetch an RFP Requirement **/
/********************************************** */

smartContractObj.fetchRequirement = async (rfpId) => {
  let web3 = await connectWeb3();
  let account = await getaccount(web3);
  let contract = await loadContract(web3);
  let rfpRequirements = "";

  try {
    if (account) {
      await contract.getPastEvents(
        "RequirementAdded",
        {
          filter: { RFPid: rfpId },
          fromBlock: 0,
          toBlock: "latest",
        },
        (errors, events) => {
          if (!errors) {
            rfpRequirements = events;
          } else {
            console.log(errors);
          }
        }
      );
      //console.log(rfpRequirements);
    }
    return {
      key: rfpRequirements,
    };
  } catch (error) {
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function for a Updating a requirement for an RFP **/
/********************************************** */

smartContractObj.updateRequirement = async (
  reqID,
  reqName,
  reqDesc,
  reqWeight,
  reqActive
) => {
  let web3 = await connectWeb3();
  let account = await getaccount(web3);
  let contract = await loadContract(web3);
  console.log(
    reqID + "-" + reqName + "-" + reqDesc + "-" + reqWeight + "-" + reqActive
  );
  try {
    const updateRequirementStatus = await contract.methods
      .updateRFPRequirement(
        parseInt(reqID),
        reqName,
        reqDesc,
        parseInt(reqWeight),
        reqActive
      )
      .send({
        from: account[0],
        //value: "100000000000000000",
        gas: 6750000,
        gasPrice: web3.eth.gasPrice,
      });
    console.log(updateRequirementStatus);
    return {
      key: updateRequirementStatus,
    };
  } catch (error) {
    console.log(error);
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function for Responding an RFP Requirment by a spplier **/
/********************************************** */

smartContractObj.respondToRequirement = async (
  requirementID,
  participationRefNo,
  response
) => {
  let web3 = await connectWeb3();
  let account = await getaccount(web3);
  let contract = await loadContract(web3);
  console.log(requirementID + "-" + participationRefNo + "-" + response);
  try {
    const reqRes = await contract.methods
      .respondToRFPRequirement(
        parseInt(requirementID),
        parseInt(participationRefNo),
        parseInt(response)
      )
      .send({
        from: account[0],
        //value: "100000000000000000",
        gas: 6750000,
        gasPrice: web3.eth.gasPrice,
      });
    console.log(reqRes);
    return {
      key: reqRes,
    };
  } catch (error) {
    console.log(error);
    return {
      key: error,
    };
  }
};

/***********************************************************************/
/*** Client/Advisor/Supplier : Fetch Response against a Rquirement ID */
/********************************************** ***********************/

smartContractObj.fetchResponseToRequirement = async (resReqRefID) => {
  let web3 = await connectWeb3();
  let account = await getaccount(web3);
  let contract = await loadContract(web3);
  let requirementResponse = "";
  console.log("Reference number Response to ReQ ID is :" + resReqRefID);
  try {
    if (account) {
      await contract.getPastEvents(
        "RequirementResponded",
        {
          filter: { requirementResponseRefNo: resReqRefID },
          fromBlock: 0,
          toBlock: "latest",
        },
        (errors, events) => {
          if (!errors) {
            requirementResponse = events;
          } else {
            console.log(errors);
          }
        }
      );
      console.log(requirementResponse);
    }
    return {
      key: requirementResponse,
    };
  } catch (error) {
    console.log(error);
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function for Responding an RFP Requirment by a spplier **/
/********************************************** */

smartContractObj.updateResponseToRequirement = async (
  participationRefNo,
  requirementResponseRefNo,
  response
) => {
  let web3 = await connectWeb3();
  let account = await getaccount(web3);
  let contract = await loadContract(web3);
  console.log(
    participationRefNo + "-" + requirementResponseRefNo + "-" + response
  );
  try {
    const reqRes = await contract.methods
      .UpdateResponseToRFPRequirement(
        parseInt(participationRefNo),
        parseInt(requirementResponseRefNo),
        parseInt(response)
      )
      .send({
        from: account[0],
        //value: "100000000000000000",
        gas: 6750000,
        gasPrice: web3.eth.gasPrice,
      });
    console.log(reqRes);
    return {
      key: reqRes,
    };
  } catch (error) {
    console.log(error);
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to get the balance for the Procrain Contract account**/
/********************************************** */

smartContractObj.getProcrainBalance = async () => {
  let web3 = await connectWeb3();
  let account = await getaccount(web3);
  let contract = await loadContract(web3);

  try {
    const getBalance = await contract.methods.getBalance().call();
    console.log(getBalance);
    return {
      key: getBalance,
    };
  } catch (error) {
    console.log(error);
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to withdraw Ether from procrain account **/
/********************************************** */

smartContractObj.withdrawFromProcrain = async (amountToWithdraw) => {
  let web3 = await connectWeb3();
  let account = await getaccount(web3);
  let contract = await loadContract(web3);

  try {
    const withdrawstatus = await contract.methods
      .withdraw(web3.utils.toWei(amountToWithdraw))
      .send({
        from: account[0],
        gas: 6750000,
        gasPrice: web3.eth.gasPrice,
      });
    console.log(withdrawstatus);
    return {
      key: withdrawstatus,
    };
  } catch (error) {
    console.log(error);
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to get the Procrain Token balance for Procrain Account **/
/********************************************** */

smartContractObj.getProcrainTokenBalance = async () => {
  let web3 = await connectWeb3();
  let account = await getaccount(web3);
  let contract = await loadContract(web3);

  try {
    const procrainBalance = await contract.methods.getProcrainBalance().call();
    console.log(procrainBalance);
    return {
      key: procrainBalance,
    };
  } catch (error) {
    console.log(error);
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to get the Procrain Token balance for a user account **/
/********************************************** */

smartContractObj.getProcrainTokenBalanceForAUserAccount = async () => {
  let web3 = await connectWeb3();
  let account = await getaccount(web3);
  let contract = await loadProcrainTokenContract(web3);

  try {
    const procrainBalance = await contract.methods.balanceOf(account[0]).call();
    //console.log(procrainBalance);
    return {
      key: web3.utils.fromWei(procrainBalance, "ether"),
    };
  } catch (error) {
    console.log(error);
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to get the ether balance for a user account **/
/********************************************** */

smartContractObj.getEtherBalanceForAUserAccount = async () => {
  let web3 = await connectWeb3();
  let account = await getaccount(web3);

  try {
    const etherBalance = await web3.eth.getBalance(account[0]);
    //console.log(procrainBalance);
    return {
      key: web3.utils.fromWei(etherBalance, "ether"),
    };
  } catch (error) {
    console.log(error);
    return {
      key: error,
    };
  }
};

/********************************************** */
/*** Function to withdraw Token from procrain account **/
/********************************************** */

smartContractObj.withdrawTokenFromProcrain = async (
  amountOfTokenToWithdraw
) => {
  let web3 = await connectWeb3();
  let account = await getaccount(web3);
  let contract = await loadContract(web3);

  try {
    const withdrawTokenStatus = await contract.methods
      .withdrawProcrain(parseInt(amountOfTokenToWithdraw))
      .send({
        from: account[0],
        gas: 6750000,
        gasPrice: web3.eth.gasPrice,
      });
    console.log(withdrawTokenStatus);
    return {
      key: withdrawTokenStatus,
    };
  } catch (error) {
    console.log(error);
    return {
      key: error,
    };
  }
};

export default smartContractObj;
