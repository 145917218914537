import React, { Fragment } from "react";
import ContactUS from "./ContactUs";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Fragment>
      <section id="sectionContactUs" className="bg-dark py-1">
      
        <div className="container text-white text-center">
          <p className="lead">PROVIDE FEEDBACK</p>
          <div className="row justify-content-center">
            {/* <div className="col-10 col-sm-8 col-md-6 col-lg-4">
              <form action="" className="form-inline">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Email"
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary">Contact Us</button>
                  </div>
                </div>
              </form>
            </div> */}
            <ContactUS>Feedback</ContactUS>
          </div>
        </div>
      </section>
      <section
        id="sectionFooter"
        className="p-3 bg-secondary d-flex justify-content-center"
      >
        POWERED BY PROCRAIN - 2021
        <ul className="nav text-center justify-content-center flex-column flex-sm-row">
          {/* <li className="nav-item">
            <a href="" className="nav-link text-white">
              Blockchain Basics
            </a>
          </li> */}
       {/* <li className="nav-item dropup">
            <Link
              className="nav-link dropdown-toggle text-white"
              to="#"
              id="navbarDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Basics
            </Link>
            <div
              className="dropdown-menu bg-light"
              aria-labelledby="navbarDropdown"
            >
              <Link className="dropdown-item" to="/hash">
                Hash
              </Link>
              <Link className="dropdown-item" to="/block">
                Block
              </Link>
              <Link className="dropdown-item" to="/wallet">
                Wallet
              </Link>
              <div className="dropdown-divider"></div>
              <Link className="dropdown-item" to="/verify">
                Verification
              </Link>
              <Link className="dropdown-item" to="/blockchain">
                Blockchain
              </Link>
            </div>
          </li> */}
          {/* <li className="nav-item">
            <a href="" className="nav-link text-white">
              About Us
            </a>
          </li> */}
          {/* <li className="nav-item">
            <a href="" className="nav-link text-white">
              FAQ
            </a>
          </li> */}
          {/* <li className="nav-item">
            <a href="" className="nav-link text-white">
              APIs
            </a>
          </li> */}
        </ul> 
      </section>
    </Fragment>
  );
};

export default Footer;
