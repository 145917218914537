import { CREATE_WALLET, POST_ERROR } from "../actions/types";

const initialState = {
  wallets: [],
  error: {}
};

export default function(state = initialState, action) {
  //console.log('in wallet reducer');
  const { type, payload } = action;
  //console.log(payload);
  switch (type) {
    case CREATE_WALLET:
      console.log(payload);
      return { ...state, wallets: state.wallets.concat(payload) };

    case POST_ERROR:
      return {
        ...state,
        error: payload
      };

    default:
      return state;
  }
}
