import React, { Fragment, useState, useEffect } from "react";
import smartContractObj from "../../smartcontract/SmartContract";
import Spinner from "../layout/Spinner";
import Info from "../../components/layout/Info";
import ProcrainModal from "../layout/ProcrainModal";
// import abiDecoder from "abi-decoder";
// import web3 from "web3";


const Supplier = () => {
  
  let [etherBalance, setEtherBalance] = useState(0);
  let [procrainBalance, setProcrainBalance] = useState(0);


  let [spiRegisterSupplier, setSpiRegisterSupplier] = useState(false);
  let [spiFetchRFPRequirement, setSpiFetchRFPRequirement] = useState(false);
  let [spiFetchResponseToReq, setSpiFetchResponseToReq] = useState(false);
  let [spiBidRFP, setSpiBidRFP] = useState(false);
  let [spiRespondToReq, setSpiRespondToReq] = useState(false);
  let [spiUpdateResponseToReq, setSpiUpdateResponseToReq] = useState(false);

  let [metamaskAlert, setMetamaskAlert] = useState(false);
  let [loginAlert, setLoginAlert] = useState(false);
  let [showModal, setShowModal] = useState(false);
  let [modalText, setModalText] = useState("");
  let [modalHeader, setModalHeader] = useState("");
  let [modalSubHeader, setModalSubHeader] = useState("");

  useEffect(() => {
    smartContractObj.isMetaMaskInstalled().then((flgMetamask) => {
      if (flgMetamask) {
        smartContractObj.isLoggedIn().then((flgLoggedIn) => {
          if (flgLoggedIn) {
          } else {
            setLoginAlert(true);
          }
        });
      } else {
        setMetamaskAlert(true);
      }
    });
  }, []);


  const BidRFP = async () => {
    let RFPId = document.getElementById("bid-RFPId").value;

    setSpiBidRFP(true);
    let bidRFPStatus = await smartContractObj.bidRFP(RFPId);
    setSpiBidRFP(false);

    let strMessage = ''
    let strSubHeader = ''

    // When user rejects the Metamask (User Rejects)
    if(bidRFPStatus.key.message ){
      strMessage = bidRFPStatus.key.message
      strSubHeader = "Bid RFP Failed"
    }else if(bidRFPStatus.key.status){
       // When EVM accepts

       //IF either RFP does not exist  or user has already bidded earlier the smartcontract
       //function runs fine but raise an event 'raiseMessage' where the second argument is message
       if(bidRFPStatus.key.events.RaiseMessage){
        console.log(bidRFPStatus.key.events.RaiseMessage.returnValues.message)
        //strMessage =  abiDecoder.decodeMethod(bidRFPStatus.key.events.RaiseMessage.returnValues.message );
        // strMessage = web3.toAscii(bidRFPStatus.key.events.RaiseMessage.returnValues.message)
        if (bidRFPStatus.key.events.RaiseMessage.returnValues.message == "0x24d8be560bf5ee764ef55827f1705e95528e791a3a96bb1fe671a96a14b344c6"){
          strMessage= "RFP is already bid earlier. No fee has been deducted."
          strSubHeader = "Bid RFP Failure"
        }
        
  
       }else{
        strMessage = bidRFPStatus.key.events.RaiseMessage.returnValues.message
        strSubHeader = "Bid RFP Successful"

       }
      

      
    }else {
      //EVM rejected the transacrion
      strMessage = "Transaction Reverted by SmartContract.  "
      strSubHeader = "Reject Bid Participation Failed"
    }
  
    setModalText(strMessage);
    setModalHeader("Bid RFP ");
    setModalSubHeader(strSubHeader);
    setShowModal(true);
  };

  const FetchRFPRequirements = async () => {
    let rfpID = document.getElementById("fetchReqRFPId").value;
    setSpiFetchRFPRequirement(true);
    let fetchRFPRequirementStatus = await smartContractObj.fetchRequirement(
      rfpID
    );
    setSpiFetchRFPRequirement(false);
    let message = "";
    fetchRFPRequirementStatus.key.forEach((element) => {
      let reqId = element.returnValues.requirementId;
      let name = element.returnValues.name;
      let desc = element.returnValues.description;
      let weight = element.returnValues.weight;
      let active = element.returnValues.isActive;
      message = message.concat(
        "Requirement ID : ",
        reqId,
        " Name : ",
        name,
        " Desc : ",
        desc,
        " Weight : ",
        weight,
        " Active : ",
        active,
        "\n"
      );
    });

    setModalText(message)
    setModalHeader("Fetch RFP Requirements")
    setModalSubHeader("Fetch RFP Requirements status")
    setShowModal(true)
  };

  const RespondToRequirement = async () => {
    let requirementID = document.getElementById("resReqId").value;
    let participationRefNo = document.getElementById("resParticipationRefNo")
      .value;
    let response = document.getElementById("resReqResponse").value;

    setSpiRespondToReq(true);
    let responseToRequirement = await smartContractObj.respondToRequirement(
      requirementID,
      participationRefNo,
      response
    );
    setSpiRespondToReq(false);

    setModalText(responseToRequirement)
    setModalHeader("Response to Requirement")
    setModalSubHeader("Fetch Response to Requirement status")
    setShowModal(true)
  };

  const FetchResponseToRequirement = async () => {
    let resReqRefID = document.getElementById("fetchResReqId").value;
    setSpiFetchResponseToReq(true);
    let requirementResponse = await smartContractObj.fetchResponseToRequirement(
      resReqRefID
    );
    setSpiFetchResponseToReq(false);
    let message = "";
    requirementResponse.key.forEach((element) => {
      let reqId = element.returnValues.requirementId;
      let reqResRefNo = element.returnValues.requirementResponseRefNo;
      let response = element.returnValues.response;
      let supplierAddress = element.returnValues.supplierAddress;

      message = `Requirement ID : ${reqId},\nRequirement Response Ref No : ${reqResRefNo},\nResponse : ${response},\nSupplier Address :${supplierAddress}`;
      setSpiFetchResponseToReq(false);
      // console.log(addRequirement);
    });
    setModalText(message)
    setModalHeader("Fetch Response to Requirement")
    setModalSubHeader("Fetch Response to Requirement status")
    setShowModal(true)
  };

  const UpdateResponseToRequirement = async () => {
    let participationRefNo = document.getElementById("updResParticipationRefNo")
      .value;
    let requirementResponseRefNo = document.getElementById(
      "updReqResponseRefNo"
    ).value;
    let response = document.getElementById("updResReqResponse").value;

    setSpiUpdateResponseToReq(true);
    let updateRequirementResponse = await smartContractObj.updateResponseToRequirement(
      participationRefNo,
      requirementResponseRefNo,
      response
    );
    setSpiUpdateResponseToReq(false);

    let message = "";
    updateRequirementResponse.key.forEach((element) => {
      let reqId = element.returnValues.requirementId;
      let reqResRefNo = element.returnValues.requirementResponseRefNo;
      let response = element.returnValues.response;
      let supplierAddress = element.returnValues.supplierAddress;

      message = `Requirement ID : ${reqId},\nRequirement Response Ref No : ${reqResRefNo},\nResponse : ${response},\nSupplier Address :${supplierAddress}`;
      setSpiUpdateResponseToReq(false);
      // console.log(addRequirement);
    });
    setModalText(message)
    setModalHeader("Update Response to Requirement")
    setModalSubHeader("Update Response to Requirement status")
    setShowModal(true)
  };

  let infoHeader = "";
  let infoText = "";
  let infoType = "";

  if (metamaskAlert) {
    infoHeader = "Metamask not installed";
    //infoText = txtMetamaskNotInstalled;
    infoText = "Please install metamask to use this feature";
    infoType = "btn btn-danger";
  } else if (loginAlert) {
    infoHeader = "Login to Metamask";
    infoText = "Please log into to Metamask to use this feature";
    infoType = "btn btn-info";
  }

  return (
    <Fragment>
      <div className="form-group">
      {showModal ? (<ProcrainModal header={modalHeader} subHeader={modalSubHeader} text={modalText} onClose={setShowModal}/>) : (null)}

        <div id="divMainBody" className="row mt-2">
          <div id="divSupplierDashboard" className="col">
            {/* This is start of First Row of Cards */}
            <div className="card-group">
              <div id="crdFetchRFPReq" className="card">
                {/* <img
                  className="card-img-top"
                  src={imgSupplier}
                  alt="Card image cap"
                /> */}
                <div className="card-body">
                  <h5 className="card-title">Fetch RFP Requirement</h5>
                  {spiFetchRFPRequirement ? (
                    <Spinner />
                  ) : (
                    <div>
                      <input
                        name="fetchReqRFPId"
                        id="fetchReqRFPId"
                        className="form-control"
                        placeholder="RFP ID"
                      ></input>
                      <div className="d-flex justify-content-center mt-5">
                        <button
                          id="btnFetchRFPRequirements"
                          className="btn btn-primary"
                          onClick={FetchRFPRequirements}
                        >
                          Fetch RFP Requirements
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div id="crdBidRFP" className="card">
                {/* <img
                      className="card-img-top"
                      src={imgSlider2}
                      alt="Card image cap"
                    /> */}
                <div className="card-body">
                  <h5 className="card-title">Bid RFP</h5>
                  {metamaskAlert || loginAlert ? (
                    <Info
                      header={infoHeader}
                      info={infoText}
                      type={infoType}
                    ></Info>
                  ) : (
                    <div>
                      {spiBidRFP ? (
                        <Spinner />
                      ) : (
                        <div>
                          <input
                            name="bid-RFPId"
                            id="bid-RFPId"
                            className="form-control mt-2"
                            placeholder="RFP ID"
                          ></input>
                          <div className="d-flex justify-content-center">
                            <button
                              id="btnBidRFP"
                              className="btn btn-primary mt-2"
                              onClick={BidRFP}
                            >
                              Bid RFP
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* This is end of First Row of Card */}

            <div className="row mt-5">
              <div className="col">
                {/* This is start of Second Row of Cards */}

                <div className="card-group">
                  <div id="crdRespondToReq" className="card">
                    {/* <img
                      className="card-img-top"
                      src={imgSlider3}
                      alt="Card image cap"
                    /> */}
                    <div className="card-body">
                      <h5 className="card-title">Respond to RFP Requirement</h5>
                      {metamaskAlert || loginAlert ? (
                        <Info
                          header={infoHeader}
                          info={infoText}
                          type={infoType}
                        ></Info>
                      ) : (
                        <div>
                          {spiRespondToReq ? (
                            <Spinner />
                          ) : (
                            <div>
                              <input
                                name="resReqId"
                                id="resReqId"
                                className="form-control mt-1"
                                placeholder="Requirement ID"
                              ></input>
                              <input
                                name="resParticipationRefNo"
                                id="resParticipationRefNo"
                                className="form-control mt-1"
                                placeholder="Participation Ref No"
                              ></input>

                              <select
                                id="resReqResponse"
                                className="form-control mt-1"
                                name="resReqResponse"
                              >
                                <option value="2">Accept</option>
                                <option value="1">Partly Accepty</option>
                                <option value="0">Reject</option>
                              </select>
                              <div className="d-flex justify-content-center">
                                <button
                                  id="btnResReq"
                                  className="btn btn-primary mt-2"
                                  onClick={RespondToRequirement}
                                >
                                  Respond to Requirement
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="crdFecthResponseToReq" className="card">
                    {/* <img
                  className="card-img-top"
                  src={imgSlider1}
                  alt="Card image cap"
                /> */}
                    <div className="card-body">
                      <h5 className="card-title">
                        Fetch Response to Requirement
                      </h5>
                      {spiFetchResponseToReq ? (
                        <Spinner />
                      ) : (
                        <div>
                          <input
                            name="fetchResReqId"
                            id="fetchResReqId"
                            className="form-control"
                            placeholder="Requirement Response No"
                          ></input>
                          <div className="d-flex justify-content-center mt-5">
                            <button
                              id="btnFetchResReq"
                              className="btn btn-primary"
                              onClick={FetchResponseToRequirement}
                            >
                              Fetch Response To Requirement
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* This is end of Second Row of Card */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
    </Fragment>
  );
};

export default Supplier;
