import { ADD_BLOCK_TO_CHAIN, POST_ERROR } from '../actions/types';

const initialState = {
  blockchain: [],
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

   switch (type) {

    case ADD_BLOCK_TO_CHAIN:
      return { ...state, blockchain: [payload, ...state.blockchain] };

    case POST_ERROR:
      return {
        ...state,
        error: payload
      };

    default:
      return state;
  }
}
