import React, { Fragment } from "react";
import SHA256 from "crypto-js/sha256";
import sha256 from "js-sha256";
//import RIPEMD160 from 'crypto-js/ripemd160';
import RIPEMD160 from "ripemd160";
import BASE58 from "bs58";
//import SECP256K1 from 'secp256k1';
import ELLEPTIC from "elliptic";

import Info from "../../layout/Info";
import {
  txtPrivateKey,
  txtPublicKey,
  txtPublicKeyHash,
  txtWalletAddress,
  txtWallet,
} from "../../../content/content";

import {
  getPrivateKey,
  generatePublicKey,
  generatePublicKeyHash,
  generateWalletAddress,
} from "../../../actions/address";

import { createWallet } from "../../../actions/wallet";

import PropTypes from "prop-types";
import { connect } from "react-redux";

const Wallet = ({
  privateKey,
  publicKey,
  publicKeyHash,
  walletAddress,
  getPrivateKey,
  createWallet,
  wallets,
  generatePublicKey,
  generatePublicKeyHash,
  generateWalletAddress,
}) => {
  // // Convert a hex string to a byte array
  // function hexToBytes(hex) {
  //   for (var bytes = [], c = 0; c < hex.length; c += 2)
  //     bytes.push(parseInt(hex.substr(c, 2), 16));
  //   return bytes;
  // }

  // Convert a byte array to a hex string
  function bytesToHex(bytes) {
    for (var hex = [], i = 0; i < bytes.length; i++) {
      var current = bytes[i] < 0 ? bytes[i] + 256 : bytes[i];
      hex.push((current >>> 4).toString(16));
      hex.push((current & 0xf).toString(16));
    }
    return hex.join("");
  }

  // Create a wallet
  /********************************************
   * Create a wallet with unique keys
   ********************************************/
  const address = async () => {
    let walletName = document.getElementById("walletName").value;

    let addresses = {
      privateKey: privateKey,
      publicKey: publicKey,
      publicKeyHash: publicKeyHash,
      walletAddress: walletAddress,
      walletName: walletName,
    };
    console.log(" In address" + addresses);
    await createWallet(addresses);
  };

  // Generate a private key
  /********************************************
   * Private key is a 256 bit number. it can be represented in many formats like Hex, Base58
   * In hex format its a 32 Byte number using 64 character (0-9, a-f)
   * To generate programatically we need a source of entroy (lack of order or precitibilty) i.e. a random number generator
   * Cryptographically secure random number generator
   ********************************************/

  const generatePrivateKey = async () => {
    let array = new Uint8Array(32);
    //console.log(array);
    let rand = window.crypto.getRandomValues(array);
    console.log(rand);
    var num5 = "";
    for (var i = 0; i < array.length; i++) {
      //console.log(array[i]);
      num5 += array[i];
    }
    //console.log(num5);
    await getPrivateKey(SHA256(num5).toString());
  };

  // Generate a public Key from Private Key using ESCDA
  const genPublicKey = async () => {
    //console.log(privateKey);
    let EC = ELLEPTIC.ec;
    let ec = new EC("secp256k1");
    let pubKey = ec.keyFromPrivate(privateKey).getPublic().encode("hex");
    //console.log(pubKey);
    await generatePublicKey(pubKey.toString());
  };

  // Convert public key to public hash using RIPMED160(SHA256(public key))
  const genPublicKeyHash = async () => {
    console.log(`Public key is : ${publicKey}`);
    // await generatePublicKeyHash(RIPEMD160(SHA256(publicKey)));
    let pubKey = sha256(Buffer.from(publicKey, "hex"));
    console.log("SHA256 of public key is " + pubKey.toString());
    let publicKeyHash = new RIPEMD160()
      .update(Buffer.from(pubKey, "hex"))
      .digest();
    //let publicKeyHash = RIPEMD160(pubKey);
    // a newtwork bytye 00 for mainnet and 06 for ttestnet needs to be appended
    publicKeyHash = "00" + bytesToHex(publicKeyHash);
    console.log(publicKeyHash);
    await generatePublicKeyHash(publicKeyHash);
  };

  // Get Wallet address as BASE58CHECK(Public hash)
  const genWalletAddress = async () => {
    // The bitcoin address is generated in a particular fashion.
    // Get Checksum : apply SHA256 twice to public key hash and take the 4 byte (8 character)

    let sha256pkh = sha256(Buffer.from(publicKeyHash, "hex"));
    console.log("sha256 has of pkh is " + sha256pkh);
    let doublesha256pkh = sha256(Buffer.from(sha256pkh, "hex"));
    console.log("double sha256 has of pkh is " + doublesha256pkh);

    console.log(
      "Double hash of public key hash is " + doublesha256pkh.toString()
    );
    let checksum = doublesha256pkh.toString().slice(0, 8);
    console.log(checksum);
    // Append the checksum to publicKey hash
    let modPublicKeyHash = publicKeyHash + checksum;
    console.log(modPublicKeyHash);
    const bytes = Buffer.from(modPublicKeyHash, "hex");
    await generateWalletAddress(BASE58.encode(bytes));
  };

  return (
    <Fragment>
      <div className="form-group">
        <div className="row">
          <Info
            id="infoPrivateKey"
            className="form-control"
            header="About Private key "
            info={txtPrivateKey}
          ></Info>
        </div>
        <div className="row">
          <div className="col-sm-8">
            <input
              type="text"
              id="privateKey"
              className="form-control"
              readOnly={true}
              value={privateKey}
              placeholder="Private key"
            />
          </div>
          <div className="col-sm-4">
            <button
              id="genPrivateKey"
              onClick={generatePrivateKey}
              className="btn btn-primary btn-sm"
            >
              Generate Private Key
            </button>
          </div>
        </div>
        <br></br>
        <div className="row">
          <Info
            id="infoPublicKey"
            className="form-control"
            header="About Public key "
            info={txtPublicKey}
          ></Info>
        </div>
        <div className="row">
          <div className="col-sm-8">
            <input
              type="text"
              id="publicKey"
              className="form-control"
              readOnly={true}
              value={publicKey}
              placeholder="Public key"
            />
          </div>
          <div className="col-sm-4">
            <button
              id="genPublicKey"
              onClick={genPublicKey}
              className="btn btn-primary btn-sm"
            >
              Generate Public Key
            </button>
          </div>
        </div>
        <br></br>
        <div className="row">
          <Info
            id="infoPublicKeyHash"
            className="form-control"
            header="About Public key hash "
            info={txtPublicKeyHash}
          ></Info>
        </div>
        <div className="row">
          <div className="col-sm-8">
            <input
              type="text"
              id="publicKeyHash"
              className="form-control"
              readOnly={true}
              value={publicKeyHash}
              placeholder="Public key Hash"
            />
          </div>
          <div className="col-sm-4">
            <button
              id="genPublicKeyHash"
              onClick={genPublicKeyHash}
              className="btn btn-primary btn-sm"
            >
              Generate Public Key Hash
            </button>
          </div>
        </div>
        <br></br>
        <div className="row">
          <Info
            id="infoWalletAddress"
            className="form-control"
            header="About Wallet Address"
            info={txtWalletAddress}
          ></Info>
        </div>
        <div className="row">
          <div className="col-sm-8">
            <input
              type="text"
              id="walletAddress"
              className="form-control"
              readOnly={true}
              value={walletAddress}
              placeholder="Wallet Address"
            />
          </div>
          <div className="col-sm-4">
            <button
              id="genWalletAddress"
              onClick={genWalletAddress}
              className="btn btn-primary btn-sm"
            >
              Generate Wallet Address
            </button>
          </div>
        </div>
        <br></br>
        <div className="row">
          <Info
            id="infoWallet"
            className="form-control"
            header="About Wallet"
            info={txtWallet}
          ></Info>
        </div>
        <div className="row">
          <div className="col-sm-8">
            <input
              type="text"
              id="walletName"
              className="form-control"
              placeholder="Wallet Name"
              required
            />
          </div>
          <div className="col-sm-4">
            <button
              id="createWallet"
              onClick={address}
              className="btn btn-primary btn-sm"
            >
              Create Wallet
            </button>
          </div>
        </div>
        <br></br>
        <div className="col-sm-8">
          {wallets !== null
            ? wallets.map((wallet) => (
                <input
                  type="text"
                  className="form-control"
                  key={wallet.publicKey}
                  value={wallet.publicKey}
                  readOnly
                />
              ))
            : {}}
        </div>
      </div>
    </Fragment>
  );
};

Wallet.defaultProps = {
  //privateKey: '',
  publicKey: "",
  publicKeyHash: "",
  walletAddress: "",
};

Wallet.propTypes = {
  privateKey: PropTypes.string,
  publicKey: PropTypes.string,
  publicKeyHash: PropTypes.string,
  walletAddress: PropTypes.string,
  wallets: PropTypes.array,
  getPrivateKey: PropTypes.func,
  generatePublicKey: PropTypes.func,
  generatePublicKeyHash: PropTypes.func,
  generateWalletAddress: PropTypes.func,
  createWallet: PropTypes.func,
};

const mapStateToProps = (state) => ({
  privateKey: state.address.privateKey,
  publicKey: state.address.publicKey,
  publicKeyHash: state.address.publicKeyHash,
  walletAddress: state.address.walletAddress,
  wallets: state.wallet.wallets,
});

export default connect(mapStateToProps, {
  getPrivateKey,
  generatePublicKey,
  generatePublicKeyHash,
  generateWalletAddress,
  createWallet,
})(Wallet);
