import React, { Fragment, useState } from "react";
import { motion } from "framer-motion";
import { MdReplay ,MdFastForward, MdFastRewind} from 'react-icons/md';
import {BsCardChecklist, BsSearch, BsFillPeopleFill}  from "react-icons/bs";


const ProcrainWorkflow = () => {

  const [openClient, setOpenClient] = useState(false);
  const [openSupplier, setOpenSupplier] = useState(false);
  const [openEveryone, setOpenEveryone] = useState(false);
  const [transitionDelay, setTransitionDelay] = useState(0.3);

  const [replayClientAnimation, setReplayClientAnimation] = useState(false);
  const [replaySupplierAnimation, setReplaySupplierAnimation] = useState(false);
  const [replayEveryoneAnimation, setReplayEveryoneAnimation] = useState(false);


  const toggleAnimationTrigger = (actor) => {
  
    //console.log(replay)
    // Get the ClassName of the Animation Controller
    let triggerClass = document.getElementById("divTrigger").className;

    // Set an opposite class for animation controller
    let notTriggerClass = "w-100";

  
      if(triggerClass === "w-100 d-none"){
        triggerClass = "w-100";
        notTriggerClass = "w-100 d-none"
      }else{
        triggerClass = "w-100 d-none";
        notTriggerClass = "w-100"
      }
    
    
    // Set the opposite Trigger class on the Div controlling th Animation
    document.getElementById("divTrigger").className = triggerClass;

  if(actor === "client" ){
    // If the controller class is set to none then show the client animation
    if(triggerClass === "w-100 d-none"){
      setOpenClient(true);
    }else{
      //if controller class is displayed then hide the client animation and setOpenClient to false
      document.getElementById("divClientWorkflow").className = notTriggerClass;
      setOpenClient(false);
    }  
   
  }else if (actor === "supplier"){  
    if(triggerClass === "w-100 d-none"){
      setOpenSupplier(true);
    }else{
      //if controller class is displayed then hide the Supplier animation and setOpenClient to false
      document.getElementById("divSupplierWorkflow").className = notTriggerClass;
      setOpenSupplier(false);
    }  
  }
  else if (actor === "everyone"){
    if(triggerClass === "w-100 d-none"){
      setOpenEveryone(true);
    }else{
      //if controller class is displayed then hide the Everyone animation and setOpenClient to false
     document.getElementById("divEveryoneWorkflow").className = notTriggerClass;
      setOpenEveryone(false);
    }  
   
  }

  }

  const replay = async (actor) => {
    if(actor === "client" ){
    // Some logic for replaying  
  }
}

  const animationVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1},
    whilehover: {scale:1.1, opacity: 0.9}
  }

  const animationEveryoneVariants = {
    initial: { opacity: 0 },
    animate: { opacity: [0,1,0]},
    whilehover: {scale:1.1, opacity: 0.9}
  }

  const animationControllerVariants = {
    initial: { opacity: 0.5 , scale: 1},
    animate: { opacity: 0.9, scale: 1.1},
    whilehover: {scale:1.2, opacity: 1}
  }

  const styles = {
    background1:{
      stroke:"grey",
      strokeWidth:"2",
      fill:"grey"
    },
    background2:{
      stroke:"grey",
      strokeWidth: "2",
      fill:"grey"
    },
    text1:{
      fill:"white",
      fontSize:"16"
    },
    text2:{
      fill:"white",
      fontSize:"8"
    },
    text3:{
      fill:"grey",
      fontSize:"8"
    },
    line1:{
      stroke:"grey"
    },
    triangle1:{
      fill:"grey",
      stroke:"grey",
      strokeWidth:"1"
    }

  }

 
  return (
    <Fragment>  

      {/* make a circle - bounce the ball */}
      <div id="divTrigger"  className="w-100">
        <svg  viewBox="0 0 500 100"> 
      
        <motion.circle cx="100" cy="50" r="40"
            variants={animationControllerVariants} 
            initial="initial"
            animate="animate"
            whileHover="whilehover"
            transition={{repeat:Infinity, duration: 2}}
            style={styles.background1}
            onClick={() => toggleAnimationTrigger("client")}/>
            <BsSearch x="93" y="25" fill="white" onClick={() => toggleAnimationTrigger("client")}/>
            <motion.text x="80" y="55" style={styles.text2} onClick={() => toggleAnimationTrigger("client")}>Click For </motion.text> 
            <motion.text x="70" y="65" style={styles.text2} onClick={() => toggleAnimationTrigger("client")}>Client Journey</motion.text> 
        
        <motion.circle cx="250" cy="50" r="40" 
            variants={animationControllerVariants} 
             initial="initial"
             animate="animate"
             whileHover="whilehover"
             transition={{repeat:Infinity, duration: 2}}
            style={styles.background1}
            onClick={() => toggleAnimationTrigger("supplier")}/>         
            <BsCardChecklist x="243" y="25" fill="white" onClick={() => toggleAnimationTrigger("supplier")}/>
            <motion.text x="230" y="55" style={styles.text2} onClick={() => toggleAnimationTrigger("supplier")}>Click For </motion.text> 
            <motion.text x="215" y="65" style={styles.text2} onClick={() => toggleAnimationTrigger("supplier")}>Supplier Journey</motion.text> 
       
        <motion.circle cx="400" cy="50" r="40" 
            variants={animationControllerVariants} 
             initial="initial"
             animate="animate"
             whileHover="whilehover"
             transition={{repeat:Infinity, duration: 2}}
            style={styles.background1}
            onClick={() => toggleAnimationTrigger("everyone")}/>
            <BsFillPeopleFill  x="393" y="25" fill="white" onClick={() => toggleAnimationTrigger("everyone")}/> 
            <motion.text x="380" y="55" style={styles.text2} onClick={() => toggleAnimationTrigger("everyone")}>Click For </motion.text> 
            <motion.text x="375" y="65" style={styles.text2} onClick={() => toggleAnimationTrigger("everyone")}>Overall Journey</motion.text> 
          
       
           
        </svg>     
      </div>


      {openClient  ? (<div id="divClientWorkflow" className="w-100">
        {/* <div>
          <MdReplay onClick={() => replay("client")}/>         
         <MdFastRewind/>
         <MdFastForward/>
        </div> */}
         
        <svg  viewBox="0 0 500 300" onClick={() => toggleAnimationTrigger("client")}>        
        {/* Blockchain Start */}

        <motion.rect  x="10" y="242" width="490" height="20" 
          style={styles.background1}
          variants={animationVariants}
          initial="initial"
          animate="animate"
          whileHover="whilehover"
          transition={{ delay: 1*transitionDelay ,type:"spring"}}    
        />
        <motion.text x="100" y="257" 
        style={styles.text1}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{delay:1*transitionDelay,type:"spring"}}    
        >Ethereum Blockchain</motion.text>

        <motion.rect x="12" y="225" width="128" height="14" 
        style={styles.background2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 2*transitionDelay,type:"spring"}} />
        <motion.text x="15" y="235" 
        style={styles.text2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
        transition={{ delay: 2*transitionDelay,type:"spring"}}
        >Procrain Token SmartContract</motion.text>

        <motion.rect x="143" y="225" width="350" height="14" style={styles.background2}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
        transition={{ delay: 2.5*transitionDelay,type:"spring"}}/>
        <motion.text x="170" y="235" 
        style={styles.text2}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
        transition={{ delay: 2.5*transitionDelay,type:"spring"}}>Procrain SmartContract</motion.text>

         {/* Blockchain End */}

        {/* Client Start */}

        <motion.circle cx="30" cy="32" r="20" style={styles.background2}       
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ type: "spring", delay: 3*transitionDelay}}        
         />
        <motion.text x="20" y="32" style={styles.text2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover" 
         transition={{ delay: 4*transitionDelay }}>Client</motion.text>
        <motion.line x1="50" y1="30" x2="65" y2="30" style={styles.line1}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover" 
         transition={{ delay: 5*transitionDelay }}/>

        <motion.rect x="65" y="15" width="60" height="35" style={styles.background2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
        transition={{ delay: 6*transitionDelay }}/>

        <motion.text x="70" y="32" style={styles.text2}
        variants={animationVariants}
        initial="initial"
        animate="animate" whilehover="whilehover"
         transition={{ delay: 7*transitionDelay }}>Buy Procrain</motion.text>
        <motion.text x="75" y="40" style={styles.text2}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ delay: 7*transitionDelay }}>Token</motion.text>
    
        <motion.line x1="80" y1="50" x2="80" y2="225" 
        style={styles.line1}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ delay: 8*transitionDelay}}/> 
        <motion.polygon points="75,200,80,210,85,200" 
        style={styles.triangle1} 
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ delay: 8*transitionDelay }}/>

        <motion.text  
          style={styles.text3}  
         initial={{ 
           x:0,
           y:0,
          rotate: 0,
          opacity: 0}}
         animate={{ 
           x:55,
           y:150,
          rotate: 90 ,
          opacity: 1}}
          whileHover="whilehover"
         transition={{ delay: 9*transitionDelay }}>Pays Ether</motion.text>
       
        <motion.line x1="112" y1="50" x2="112" y2="225" style={styles.line1}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
         transition={{ delay:10*transitionDelay }}/>
        <motion.polygon points="107,70,112,60,117,70" style={styles.triangle1} 
      variants={animationVariants}
      initial="initial"
      animate="animate" 
      whileHover="whilehover"
         transition={{ delay: 11*transitionDelay }}/>

        <motion.text 
        style={styles.text3}
         initial={{ 
           x:0,
           y:0,
           rotate:0,
           opacity: 0 }}
         animate={{
           x:83,
          y:150,
          rotate:-90,
          opacity: 1 }}
         transition={{ delay: 12*transitionDelay }}>Gets Procrain token</motion.text>

        <motion.line x1="125" y1="30" x2="140" y2="30" style={styles.line1}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
        transition={{ delay: 13*transitionDelay }}/>

        <motion.rect x="140" y="15" width="60" height="35" style={styles.background2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 14*transitionDelay }}
        />

        <motion.text x="145" y="35" style={styles.text2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 15*transitionDelay }}
        >Initiate RFP</motion.text>

        <motion.line x1="152" y1="50" x2="152" y2="225" style={styles.line1}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 16*transitionDelay }}
        />
        <motion.polygon points="147,200,152,210,157,200" style={styles.triangle1} 
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 16*transitionDelay }}
        />
        <motion.text 
        style={styles.text3} 
         initial={{ 
          x:0,
          y:0,
          rotate:0,
          opacity: 0 }}
        animate={{
          x:80,
         y:144,
         rotate:90,
         opacity: 1 }}
        transition={{ delay: 17*transitionDelay }}
        >Pays RFP Inititation Fee (in Procrain)</motion.text>

        <motion.line x1="184" y1="50" x2="183" y2="225" style={styles.line1}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 18*transitionDelay }}/>
        <motion.polygon points="179,70,184,60,189,70" style={styles.triangle1} 
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 18*transitionDelay }}/>
        <motion.text 
        style={styles.text3} 
         initial={{ 
          x:0,
          y:0,
          rotate:0,
          opacity: 0 }}
        animate={{
          x:130,
         y:150,
         rotate:-90,
         opacity: 1 }}
        transition={{ delay: 19*transitionDelay }}
        >RFP Initiated - RFP ID Generated</motion.text>

        <motion.line x1="200" y1="30" x2="215" y2="30" style={styles.line1}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
        transition={{ delay: 20*transitionDelay }}/>


        <motion.rect x="215" y="15" width="60" height="35" style={styles.background2}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
          transition={{ delay: 21*transitionDelay }}/>


        <motion.text x="230" y="35" style={styles.text2}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ delay:22*transitionDelay }}
        >Add</motion.text>
        <motion.text x="222" y="43" style={styles.text2}
      variants={animationVariants}
      initial="initial"
      animate="animate" 
      whileHover="whilehover"
        transition={{ delay: 23*transitionDelay }}>Requirements</motion.text>

       
        <motion.line x1="224" y1="50" x2="224" y2="225" style={styles.line1}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
        transition={{ delay: 24*transitionDelay }}
        />        
        <motion.polygon points="219,200,224,210,229,200" style={styles.triangle1} 
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
        transition={{ delay: 24*transitionDelay }}
        />
        <motion.text 
        style={styles.text3} 
        initial={{ 
          x:0,
          y:0,
          rotate:0,
          opacity: 0 }}
        animate={{
          x:140,
         y:140,
         rotate:90,
         opacity: 1 }}
        transition={{ delay: 25*transitionDelay }}
        >Pays Raise Requirement Fee (in Procrain)</motion.text>

        <motion.line x1="256" y1="50" x2="256" y2="225" style={styles.line1}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
        transition={{ delay: 26*transitionDelay}}/>

        <motion.polygon points="251,70,256,60,261,70" style={styles.triangle1} 
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 27*transitionDelay }}
        />
        <motion.text  
        style={styles.text3}
        initial={{ 
          x:0,
          y:0,
          rotate:0,
          opacity: 0 }}
        animate={{
          x:190,
         y:150,
         rotate:-90,
         opacity: 1 }}
        transition={{ delay: 28*transitionDelay }}
        >Requirement Added - Req ID generated</motion.text>

        <motion.line x1="275" y1="30" x2="290" y2="30" style={styles.line1}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
        transition={{ delay: 29*transitionDelay }}/>


        <motion.rect x="290" y="15" width="60" height="35" style={styles.background2}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
        transition={{ delay: 30*transitionDelay}}/>
        <motion.text x="295" y="35" style={styles.text2}
     variants={animationVariants}
     initial="initial"
     animate="animate" 
     whileHover="whilehover"
        transition={{ delay: 31*transitionDelay}}
        >Release RFP</motion.text>

        <motion.line x1="350" y1="30" x2="365" y2="30" style={styles.line1}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
        transition={{ delay: 32*transitionDelay}}/>

        {/* <line x1="18.5rem" y1="3rem" x2="18.5rem" y2="14rem" style={styles.line1}/>
        <text x="15rem" y="5rem" fill="blue" fontSize="0.5rem" transform="rotate(90,285,85)">Pay Procrain</text> */}

        {/* <line x1="20.5rem" y1="3rem" x2="20.5rem" y2="14rem" style={styles.line1}/>
        <polygon points="323,70,328,60,333,70" style={styles.triangle1} />
        <text x="21.8rem" y="5rem" fill="blue" fontSize="0.5rem" transform="rotate(-90,385,115)">Get RFP ID</text> */}

        <motion.rect x="365" y="15" width="60" height="35" style={styles.background2}
      variants={animationVariants}
      initial="initial"
      animate="animate" 
      whileHover="whilehover"
        transition={{ delay: 33*transitionDelay }}/>

        <motion.text x="370" y="35" style={styles.text2}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
         transition={{ delay: 34*transitionDelay}}>Accept/Reject</motion.text>
        <motion.text x="372" y="43" style={styles.text2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 35*transitionDelay}}>Bid Request</motion.text>

        <motion.line x1="425" y1="30" x2="440" y2="30" style={styles.line1}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 36*transitionDelay}}/>

        {/* <line x1="23rem" y1="3rem" x2="23rem" y2="14rem" style={styles.line1}/>
        <text x="15rem" y="5rem" fill="blue" fontSize="0.5rem" transform="rotate(90,285,85)">Pay Procrain</text> */}

        {/* <line x1="25rem" y1="3rem" x2="25rem" y2="14rem" style={styles.line1}/>
        <text x="21.8rem" y="5rem" fill="blue" fontSize="0.5rem" transform="rotate(-90,385,115)">Get RFP ID</text> */}

        <motion.rect x="440" y="15" width="60" height="35" style={styles.background2}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
         transition={{ delay: 37*transitionDelay }}/>
        <motion.text x="450" y="35" style={styles.text2}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ delay: 38*transitionDelay }}>Close RFP</motion.text>
       
        {/* <line x1="27.5rem" y1="3rem" x2="27.5rem" y2="14rem" style={styles.line1}/>
        <text x="15rem" y="5rem" fill="blue" fontSize="0.5rem" transform="rotate(90,285,85)">Pay Procrain</text>

        <line x1="29.5rem" y1="3rem" x2="29.5rem" y2="14rem" style={styles.line1}/> */}

        {/* Client End */}

       
     </svg>
      </div>
) : (null)}
      
{openSupplier ? (<div id="divSupplierWorkflow" className="w-100">
      <svg  viewBox="0 0 500 300"  onClick={() => toggleAnimationTrigger("supplier")}>        
        {/* Blockchain Start */}
        <motion.rect x="10" y="242" width="490" height="20" 
        style={styles.background1} 
        variants={animationVariants}
          initial="initial"
          animate="animate"
          whileHover="whilehover"
          transition={{ delay: 1*transitionDelay ,type:"spring"}}  
        />
        <motion.text x="100" y="257" 
        style={styles.text1}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{delay:1*transitionDelay,type:"spring"}}    
        >Ethereum Blockchain</motion.text>

        <motion.rect x="12" y="225" width="128" height="14" 
        style={styles.background2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 2*transitionDelay,type:"spring"}} />
        <motion.text x="15" y="235" 
        style={styles.text2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
        transition={{ delay: 2*transitionDelay,type:"spring"}}
        >Procrain Token SmartContract</motion.text>

        <motion.rect x="143" y="225" width="350" height="14" style={styles.background2}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
        transition={{ delay: 2.5*transitionDelay,type:"spring"}}/>
        <motion.text x="170" y="235" 
        style={styles.text2}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
        transition={{ delay: 2.5*transitionDelay,type:"spring"}}>Procrain SmartContract</motion.text>

         {/* Blockchain End */}        

        {/* Supplier Start */}

        <motion.circle cx="30" cy="100" r="20" style={styles.background2}       
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ type: "spring", delay: 3*transitionDelay}}        
         />
        
        <motion.text x="18" y="101" style={styles.text2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover" 
        transition={{ delay: 4*transitionDelay }}>Supplier</motion.text>
        
        <motion.line x1="50" y1="100" x2="65" y2="100" style={styles.line1}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover" 
         transition={{ delay: 5*transitionDelay }}/>

        <motion.rect x="65" y="83" width="60" height="35" style={styles.background2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
        transition={{ delay: 6*transitionDelay }}/>

        <motion.text x="70" y="100" style={styles.text2}
        variants={animationVariants}
        initial="initial"
        animate="animate" whilehover="whilehover"
         transition={{ delay: 7*transitionDelay }}>Buy Procrain</motion.text>
        <motion.text x="75" y="110" style={styles.text2}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ delay: 7*transitionDelay }}>Token</motion.text>

        <motion.line x1="80" y1="118" x2="80" y2="225" 
        style={styles.line1}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ delay: 8*transitionDelay}}/> 
        <motion.polygon points="75,200,80,210,85,200" 
        style={styles.triangle1} 
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ delay: 8*transitionDelay }}/>

        <motion.text  
          style={styles.text3}  
         initial={{ 
           x:0,
           y:0,
          rotate: 0,
          opacity: 0}}
         animate={{ 
           x:55,
           y:150,
          rotate: 90 ,
          opacity: 1}}
          whileHover="whilehover"
         transition={{ delay: 9*transitionDelay }}>Pays Ether</motion.text>
       
        <motion.line x1="112" y1="118" x2="112" y2="225" style={styles.line1}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
         transition={{ delay:10*transitionDelay }}/>
        <motion.polygon points="107,135,112,125,117,135" style={styles.triangle1} 
      variants={animationVariants}
      initial="initial"
      animate="animate" 
      whileHover="whilehover"
         transition={{ delay: 11*transitionDelay }}/>

        <motion.text 
        style={styles.text3}
         initial={{ 
           x:0,
           y:0,
           rotate:0,
           opacity: 0 }}
         animate={{
           x:83,
          y:175,
          rotate:-90,
          opacity: 1 }}
         transition={{ delay: 12*transitionDelay }}>Gets Procrain token</motion.text>

        <motion.line x1="125" y1="100" x2="140" y2="100" style={styles.line1}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
          transition={{ delay: 13*transitionDelay }}/>

        <motion.rect x="140" y="83" width="60" height="35" style={styles.background2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 14*transitionDelay }}
        />

        <motion.text x="145" y="100" style={styles.text2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 15*transitionDelay }}
        >Register</motion.text>
        <motion.text x="145" y="110" style={styles.text2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 15*transitionDelay }}
        >As Supplier</motion.text>

        <motion.line x1="152" y1="118" x2="152" y2="225" style={styles.line1}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 16*transitionDelay }}
        />
        <motion.polygon points="147,200,152,210,157,200" style={styles.triangle1} 
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 16*transitionDelay }}
        />
        <motion.text 
        style={styles.text3} 
         initial={{ 
          x:0,
          y:0,
          rotate:0,
          opacity: 0 }}
        animate={{
          x:108,
         y:165,
         rotate:90,
         opacity: 1 }}
        transition={{ delay: 17*transitionDelay }}
        >Pays Registration Fee</motion.text>

         <motion.text 
        style={styles.text3} 
         initial={{ 
          x:0,
          y:0,
          rotate:0,
          opacity: 0 }}
        animate={{
          x:120,
         y:165,
         rotate:90,
         opacity: 1 }}
        transition={{ delay: 17*transitionDelay }}
        >(in Procrain)</motion.text>

        <motion.line x1="184" y1="118" x2="183" y2="225" style={styles.line1}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 18*transitionDelay }}/>
        <motion.polygon points="179,135,184,125,189,135" style={styles.triangle1} 
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 18*transitionDelay }}/>
        <motion.text 
        style={styles.text3} 
         initial={{ 
          x:0,
          y:0,
          rotate:0,
          opacity: 0 }}
        animate={{
          x:160,
         y:170,
         rotate:-90,
         opacity: 1 }}
        transition={{ delay: 19*transitionDelay }}
        >Gets Supplier ID</motion.text>

        <motion.line x1="200" y1="100" x2="275" y2="100" style={styles.line1}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
        transition={{ delay: 20*transitionDelay }}/>
  
      <motion.rect x="275" y="83" width="60" height="35" style={styles.background2}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
          transition={{ delay: 21*transitionDelay }}/>


        <motion.text x="280" y="100" style={styles.text2}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ delay:22*transitionDelay }}
        >Bid RFP </motion.text>   
        
        <motion.line x1="335" y1="100" x2="365" y2="100" style={styles.line1}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
        transition={{ delay: 23*transitionDelay }}
        />

<motion.rect x="365" y="83" width="60" height="35" style={styles.background2}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
        transition={{ delay: 24*transitionDelay}}/>
        <motion.text x="369" y="100" style={styles.text2}
     variants={animationVariants}
     initial="initial"
     animate="animate" 
     whileHover="whilehover"
        transition={{ delay: 25*transitionDelay}}
        >Respond To</motion.text>
         <motion.text x="368" y="110" style={styles.text2}
     variants={animationVariants}
     initial="initial"
     animate="animate" 
     whileHover="whilehover"
        transition={{ delay: 25*transitionDelay}}
        >Requirements</motion.text>

        <motion.line x1="380" y1="118" x2="380" y2="225" style={styles.line1}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 26*transitionDelay }}
        />
        <motion.polygon points="375,200,380,210,385,200" style={styles.triangle1} 
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 26*transitionDelay }}
        />
        <motion.text 
        style={styles.text3} 
         initial={{ 
          x:0,
          y:0,
          rotate:0,
          opacity: 0 }}
        animate={{
          x:340,
         y:165,
         rotate:90,
         opacity: 1 }}
        transition={{ delay: 27*transitionDelay }}
        >Pays Response Fee</motion.text>

         <motion.text 
        style={styles.text3} 
         initial={{ 
          x:0,
          y:0,
          rotate:0,
          opacity: 0 }}
        animate={{
          x:345,
         y:165,
         rotate:90,
         opacity: 1 }}
        transition={{ delay: 27*transitionDelay }}
        >(in Procrain)</motion.text>

        <motion.line x1="410" y1="118" x2="410" y2="225" style={styles.line1}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 28*transitionDelay }}/>
        <motion.polygon points="405,135,410,125,415,135" style={styles.triangle1} 
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 28*transitionDelay }}/>
        <motion.text 
        style={styles.text3} 
         initial={{ 
          x:0,
          y:0,
          rotate:0,
          opacity: 0 }}
        animate={{
          x:385,
         y:170,
         rotate:-90,
         opacity: 1 }}
        transition={{ delay: 29*transitionDelay }}
        >Gets Response ID</motion.text>

      {/* Supplier Ends */}
     </svg>
      </div>
) : (null)}
      
      {openEveryone ? (<div id="divEveryoneWorkflow" className="w-100">
      <svg  viewBox="0 0 500 300"  onClick={() => toggleAnimationTrigger("everyone")}>        
        {/* Blockchain Start */}

        <motion.rect  x="10" y="242" width="490" height="20" 
          style={styles.background1}
          variants={animationVariants}
          initial="initial"
          animate="animate"
          whileHover="whilehover"
          transition={{ delay: 1*transitionDelay ,type:"spring"}}    
        />
        <motion.text x="100" y="257" 
        style={styles.text1}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{delay:1*transitionDelay,type:"spring"}}    
        >Ethereum Blockchain</motion.text>

        <motion.rect x="12" y="225" width="128" height="14" 
        style={styles.background2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 2*transitionDelay,type:"spring"}} />
        <motion.text x="15" y="235" 
        style={styles.text2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
        transition={{ delay: 2*transitionDelay,type:"spring"}}
        >Procrain Token SmartContract</motion.text>

        <motion.rect x="143" y="225" width="350" height="14" style={styles.background2}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
        transition={{ delay: 2.5*transitionDelay,type:"spring"}}/>
        <motion.text x="170" y="235" 
        style={styles.text2}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
        transition={{ delay: 2.5*transitionDelay,type:"spring"}}>Procrain SmartContract</motion.text>

         {/* Blockchain End */}

        {/* Client Start */}

        <motion.circle cx="30" cy="32" r="20" style={styles.background2}       
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ type: "spring", delay: 3*transitionDelay}}        
         />
        <motion.text x="20" y="32" style={styles.text2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover" 
         transition={{ delay: 4*transitionDelay }}>Client</motion.text>
        <motion.line x1="50" y1="30" x2="65" y2="30" style={styles.line1}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover" 
         transition={{ delay: 5*transitionDelay }}/>

        <motion.rect x="65" y="15" width="60" height="35" style={styles.background2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
        transition={{ delay: 6*transitionDelay }}/>

        <motion.text x="70" y="32" style={styles.text2}
        variants={animationVariants}
        initial="initial"
        animate="animate" whilehover="whilehover"
         transition={{ delay: 7*transitionDelay }}>Buy Procrain</motion.text>
        <motion.text x="75" y="40" style={styles.text2}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ delay: 7*transitionDelay }}>Token</motion.text>
    
        <motion.line x1="80" y1="50" x2="80" y2="225" 
        style={styles.line1}
         variants={animationEveryoneVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ delay: 8*transitionDelay}}/> 
        <motion.polygon points="75,200,80,210,85,200" 
        style={styles.triangle1} 
         variants={animationEveryoneVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ delay: 8*transitionDelay }}/>

        <motion.text  
          style={styles.text3}  
         initial={{ 
           x:0,
           y:0,
          rotate: 0,
          opacity: 0}}
         animate={{ 
           x:55,
           y:150,
          rotate: 90 ,
          opacity: [0,1,0]}}
          whileHover="whilehover"
         transition={{ delay: 9*transitionDelay }}>Pays Ether</motion.text>
       
        <motion.line x1="112" y1="50" x2="112" y2="225" style={styles.line1}
       variants={animationEveryoneVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
         transition={{ delay:10*transitionDelay }}/>
        <motion.polygon points="107,70,112,60,117,70" style={styles.triangle1} 
      variants={animationEveryoneVariants}
      initial="initial"
      animate="animate" 
      whileHover="whilehover"
         transition={{ delay: 11*transitionDelay }}/>

        <motion.text 
        style={styles.text3}
         initial={{ 
           x:0,
           y:0,
           rotate:0,
           opacity: 0 }}
         animate={{
           x:83,
          y:150,
          rotate:-90,
          opacity: [0,1,0] }}
         transition={{ delay: 12*transitionDelay }}>Gets Procrain token</motion.text>

        <motion.line x1="125" y1="30" x2="140" y2="30" style={styles.line1}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
        transition={{ delay: 13*transitionDelay }}/>

        <motion.rect x="140" y="15" width="60" height="35" style={styles.background2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 14*transitionDelay }}
        />

        <motion.text x="145" y="35" style={styles.text2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 15*transitionDelay }}
        >Initiate RFP</motion.text>

        <motion.line x1="152" y1="50" x2="152" y2="225" style={styles.line1}
        variants={animationEveryoneVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 16*transitionDelay }}
        />
        <motion.polygon points="147,200,152,210,157,200" style={styles.triangle1} 
        variants={animationEveryoneVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 16*transitionDelay }}
        />
        <motion.text 
        style={styles.text3} 
         initial={{ 
          x:0,
          y:0,
          rotate:0,
          opacity: 0 }}
        animate={{
          x:80,
         y:144,
         rotate:90,
         opacity: [0,1,0] }}
        transition={{ delay: 17*transitionDelay }}
        >Pays RFP Inititation Fee (in Procrain)</motion.text>

        <motion.line x1="184" y1="50" x2="183" y2="225" style={styles.line1}
        variants={animationEveryoneVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 18*transitionDelay }}/>
        <motion.polygon points="179,70,184,60,189,70" style={styles.triangle1} 
        variants={animationEveryoneVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 18*transitionDelay }}/>
        <motion.text 
        style={styles.text3} 
         initial={{ 
          x:0,
          y:0,
          rotate:0,
          opacity: 0 }}
        animate={{
          x:130,
         y:150,
         rotate:-90,
         opacity: [0,1,0] }}
        transition={{ delay: 19*transitionDelay }}
        >RFP Initiated - RFP ID Generated</motion.text>

        <motion.line x1="200" y1="30" x2="215" y2="30" style={styles.line1}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
        transition={{ delay: 20*transitionDelay }}/>


        <motion.rect x="215" y="15" width="60" height="35" style={styles.background2}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
          transition={{ delay: 21*transitionDelay }}/>


        <motion.text x="230" y="35" style={styles.text2}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ delay:22*transitionDelay }}
        >Add</motion.text>
        <motion.text x="222" y="43" style={styles.text2}
      variants={animationVariants}
      initial="initial"
      animate="animate" 
      whileHover="whilehover"
        transition={{ delay: 23*transitionDelay }}>Requirements</motion.text>

       
        <motion.line x1="224" y1="50" x2="224" y2="225" style={styles.line1}
       variants={animationEveryoneVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
        transition={{ delay: 24*transitionDelay }}
        />        
        <motion.polygon points="219,200,224,210,229,200" style={styles.triangle1} 
        variants={animationEveryoneVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
        transition={{ delay: 24*transitionDelay }}
        />
        <motion.text 
        style={styles.text3} 
        initial={{ 
          x:0,
          y:0,
          rotate:0,
          opacity: 0 }}
        animate={{
          x:140,
         y:140,
         rotate:90,
         opacity: [0,1,0] }}
        transition={{ delay: 25*transitionDelay }}
        >Pays Raise Requirement Fee (in Procrain)</motion.text>

        <motion.line x1="256" y1="50" x2="256" y2="225" style={styles.line1}
        variants={animationEveryoneVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
        transition={{ delay: 26*transitionDelay}}/>

        <motion.polygon points="251,70,256,60,261,70" style={styles.triangle1} 
        variants={animationEveryoneVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 27*transitionDelay }}
        />
        <motion.text  
        style={styles.text3}
        initial={{ 
          x:0,
          y:0,
          rotate:0,
          opacity: 0 }}
        animate={{
          x:190,
         y:150,
         rotate:-90,
         opacity: [0,1,0] }}
        transition={{ delay: 28*transitionDelay }}
        >Requirement Added - Req ID generated</motion.text>

        <motion.line x1="275" y1="30" x2="290" y2="30" style={styles.line1}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
        transition={{ delay: 29*transitionDelay }}/>


        <motion.rect x="290" y="15" width="60" height="35" style={styles.background2}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
        transition={{ delay: 30*transitionDelay}}/>
        <motion.text x="295" y="35" style={styles.text2}
     variants={animationVariants}
     initial="initial"
     animate="animate" 
     whileHover="whilehover"
        transition={{ delay: 31*transitionDelay}}
        >Release RFP</motion.text>

        <motion.line x1="350" y1="30" x2="365" y2="30" style={styles.line1}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
        transition={{ delay: 32*transitionDelay}}/>

        <motion.rect x="365" y="15" width="60" height="35" style={styles.background2}
      variants={animationVariants}
      initial="initial"
      animate="animate" 
      whileHover="whilehover"
        transition={{ delay: 54*transitionDelay }}/>

        <motion.text x="370" y="35" style={styles.text2}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
         transition={{ delay: 55*transitionDelay}}>Accept/Reject</motion.text>
        <motion.text x="372" y="43" style={styles.text2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 56*transitionDelay}}>Bid Request</motion.text>

        <motion.line x1="425" y1="30" x2="440" y2="30" style={styles.line1}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 68*transitionDelay}}/>

       
        <motion.rect x="440" y="15" width="60" height="35" style={styles.background2}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
         transition={{ delay: 69*transitionDelay }}/>
        <motion.text x="450" y="35" style={styles.text2}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ delay: 70*transitionDelay }}>Close RFP</motion.text>
       
       
        {/* Client End */}

        {/* Supplier Start */}

        <motion.circle cx="30" cy="100" r="20" style={styles.background2}       
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ type: "spring", delay: 32*transitionDelay}}        
         />
        
        <motion.text x="18" y="101" style={styles.text2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover" 
        transition={{ delay: 33*transitionDelay }}>Supplier</motion.text>
        
        <motion.line x1="50" y1="100" x2="65" y2="100" style={styles.line1}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover" 
         transition={{ delay: 34*transitionDelay }}/>

        <motion.rect x="65" y="83" width="60" height="35" style={styles.background2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
        transition={{ delay: 35*transitionDelay }}/>

        <motion.text x="70" y="100" style={styles.text2}
        variants={animationVariants}
        initial="initial"
        animate="animate" whilehover="whilehover"
         transition={{ delay: 36*transitionDelay }}>Buy Procrain</motion.text>
        <motion.text x="75" y="110" style={styles.text2}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ delay: 37*transitionDelay }}>Token</motion.text>

        <motion.line x1="80" y1="118" x2="80" y2="225" 
        style={styles.line1}
         variants={animationEveryoneVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ delay: 38*transitionDelay}}/> 
        <motion.polygon points="75,200,80,210,85,200" 
        style={styles.triangle1} 
         variants={animationEveryoneVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ delay: 39*transitionDelay }}/>

        <motion.text  
          style={styles.text3}  
         initial={{ 
           x:0,
           y:0,
          rotate: 0,
          opacity: 0}}
         animate={{ 
           x:55,
           y:150,
          rotate: 90 ,
          opacity: [0,1,0]}}
          whileHover="whilehover"
         transition={{ delay: 40*transitionDelay }}>Pays Ether</motion.text>
       
        <motion.line x1="112" y1="118" x2="112" y2="225" style={styles.line1}
       variants={animationEveryoneVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
         transition={{ delay:41*transitionDelay }}/>
        <motion.polygon points="107,135,112,125,117,135" style={styles.triangle1} 
      variants={animationEveryoneVariants}
      initial="initial"
      animate="animate" 
      whileHover="whilehover"
         transition={{ delay: 42*transitionDelay }}/>

        <motion.text 
        style={styles.text3}
         initial={{ 
           x:0,
           y:0,
           rotate:0,
           opacity: 0 }}
         animate={{
           x:83,
          y:175,
          rotate:-90,
          opacity: [0,1,0] }}
         transition={{ delay: 43*transitionDelay }}>Gets Procrain token</motion.text>

        <motion.line x1="125" y1="100" x2="140" y2="100" style={styles.line1}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
          transition={{ delay: 44*transitionDelay }}/>

        <motion.rect x="140" y="83" width="60" height="35" style={styles.background2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 45*transitionDelay }}
        />

        <motion.text x="145" y="100" style={styles.text2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 46*transitionDelay }}
        >Register</motion.text>
        <motion.text x="145" y="110" style={styles.text2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 46*transitionDelay }}
        >As Supplier</motion.text>

        <motion.line x1="152" y1="118" x2="152" y2="225" style={styles.line1}
        variants={animationEveryoneVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 47*transitionDelay }}
        />
        <motion.polygon points="147,200,152,210,157,200" style={styles.triangle1} 
        variants={animationEveryoneVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 47*transitionDelay }}
        />
        <motion.text 
        style={styles.text3} 
         initial={{ 
          x:0,
          y:0,
          rotate:0,
          opacity: 0 }}
        animate={{
          x:108,
         y:165,
         rotate:90,
         opacity: [0,1,0] }}
        transition={{ delay: 48*transitionDelay }}
        >Pays Registration Fee</motion.text>

         <motion.text 
        style={styles.text3} 
         initial={{ 
          x:0,
          y:0,
          rotate:0,
          opacity: 0 }}
        animate={{
          x:120,
         y:165,
         rotate:90,
         opacity: [0,1,0] }}
        transition={{ delay: 48*transitionDelay }}
        >(in Procrain)</motion.text>

        <motion.line x1="184" y1="118" x2="183" y2="225" style={styles.line1}
        variants={animationEveryoneVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 49*transitionDelay }}/>
        <motion.polygon points="179,135,184,125,189,135" style={styles.triangle1} 
        variants={animationEveryoneVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 49*transitionDelay }}/>
        <motion.text 
        style={styles.text3} 
         initial={{ 
          x:0,
          y:0,
          rotate:0,
          opacity: 0 }}
        animate={{
          x:160,
         y:170,
         rotate:-90,
         opacity: [0,1,0] }}
        transition={{ delay: 50*transitionDelay }}
        >Gets Supplier ID</motion.text>

        <motion.line x1="200" y1="100" x2="300" y2="100" style={styles.line1}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
        transition={{ delay: 51*transitionDelay }}/>
  
        <motion.rect x="300" y="83" width="60" height="35" style={styles.background2}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
          transition={{ delay: 52*transitionDelay }}/>


        <motion.text x="305" y="100" style={styles.text2}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ delay:53*transitionDelay }}
        >Bid RFP </motion.text>   
        
        <motion.line x1="360" y1="100" x2="390" y2="100" style={styles.line1}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
        transition={{ delay: 57*transitionDelay }}
        />

<motion.rect x="390" y="83" width="60" height="35" style={styles.background2}
       variants={animationVariants}
       initial="initial"
       animate="animate" 
       whileHover="whilehover"
        transition={{ delay: 58*transitionDelay}}/>
        <motion.text x="399" y="100" style={styles.text2}
     variants={animationVariants}
     initial="initial"
     animate="animate" 
     whileHover="whilehover"
        transition={{ delay: 59*transitionDelay}}
        >Respond To</motion.text>
         <motion.text x="398" y="110" style={styles.text2}
     variants={animationVariants}
     initial="initial"
     animate="animate" 
     whileHover="whilehover"
        transition={{ delay: 60*transitionDelay}}
        >Requirements</motion.text>

        <motion.line x1="400" y1="118" x2="400" y2="225" style={styles.line1}
        variants={animationEveryoneVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 61*transitionDelay }}
        />
        <motion.polygon points="390,200,400,210,410,200" style={styles.triangle1} 
        variants={animationEveryoneVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 62*transitionDelay }}
        />
        <motion.text 
        style={styles.text3} 
         initial={{ 
          x:0,
          y:0,
          rotate:0,
          opacity: 0 }}
        animate={{
          x:340,
         y:165,
         rotate:90,
         opacity: [0,1,0] }}
        transition={{ delay: 63*transitionDelay }}
        >Pays Response Fee</motion.text>

         <motion.text 
        style={styles.text3} 
         initial={{ 
          x:0,
          y:0,
          rotate:0,
          opacity: 0 }}
        animate={{
          x:345,
         y:165,
         rotate:90,
         opacity: [0,1,0] }}
        transition={{ delay: 64*transitionDelay }}
        >(in Procrain)</motion.text>

        <motion.line x1="410" y1="118" x2="410" y2="225" style={styles.line1}
        variants={animationEveryoneVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 65*transitionDelay }}/>
        <motion.polygon points="405,135,410,125,415,135" style={styles.triangle1} 
        variants={animationEveryoneVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
         transition={{ delay: 66*transitionDelay }}/>
        <motion.text 
        style={styles.text3} 
         initial={{ 
          x:0,
          y:0,
          rotate:0,
          opacity: 0 }}
        animate={{
          x:385,
         y:170,
         rotate:-90,
         opacity: [0,1,0] }}
        transition={{ delay: 67*transitionDelay }}
        >Gets Response ID</motion.text>

      {/* Supplier Ends */}

      {/* Every Start */}

      <motion.circle cx="30" cy="160" r="20" style={styles.background2}       
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ type: "spring", delay: 71*transitionDelay}}        
      />
      <motion.text x="15" y="160" style={styles.text2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover" 
        transition={{ delay: 72*transitionDelay }}>Everyone</motion.text>
     
        <motion.line x1="50" y1="160" x2="170" y2="160" style={styles.line1}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover" 
         transition={{ delay: 73*transitionDelay }}/>

        <motion.rect x="170" y="143" width="60" height="35" style={styles.background2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
        transition={{ delay: 74*transitionDelay }}/>

        <motion.text x="180" y="160" style={styles.text2}
        variants={animationVariants}
        initial="initial"
        animate="animate" whilehover="whilehover"
         transition={{ delay: 75*transitionDelay }}>Fetch RFP</motion.text>
        <motion.text x="185" y="170" style={styles.text2}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ delay: 75*transitionDelay }}>Details</motion.text>

        <motion.line x1="230" y1="160" x2="270" y2="160" style={styles.line1}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover" 
         transition={{ delay: 76*transitionDelay }}/>

        <motion.rect x="270" y="143" width="60" height="35" style={styles.background2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
        transition={{ delay: 77*transitionDelay }}/>

        <motion.text x="280" y="160" style={styles.text2}
        variants={animationVariants}
        initial="initial"
        animate="animate" whilehover="whilehover"
         transition={{ delay: 78*transitionDelay }}>Fetch RFP</motion.text>
        <motion.text x="278" y="170" style={styles.text2}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ delay: 78*transitionDelay }}>Requirements</motion.text>

        <motion.line x1="330" y1="160" x2="420" y2="160" style={styles.line1}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover" 
         transition={{ delay: 79*transitionDelay }}/>

        <motion.rect x="420" y="143" width="60" height="35" style={styles.background2}
        variants={animationVariants}
        initial="initial"
        animate="animate" 
        whileHover="whilehover"
        transition={{ delay: 80*transitionDelay }}/>

        <motion.text x="422" y="160" style={styles.text2}
        variants={animationVariants}
        initial="initial"
        animate="animate" whilehover="whilehover"
         transition={{ delay: 81*transitionDelay }}>Fetch Response</motion.text>
        <motion.text x="421" y="170" style={styles.text2}
         variants={animationVariants}
         initial="initial"
         animate="animate" 
         whileHover="whilehover"
         transition={{ delay: 81*transitionDelay }}>To Requirements</motion.text>

           
      {/* Everyone Ends */}

     </svg>
      </div>
     ) : (null)}
      
     </Fragment>
  
  )}

export default ProcrainWorkflow;
