import React, { Fragment } from "react";

const copyToClipboard = async (controlId) => {
    /* Get the text field */
    var copyText = await document.getElementById(controlId).select();
    // /* Select the text field */
    // copyText.select();
    // copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");

    /* Alert the copied text */
    // alert("Copied the text: " + copyText);
  };

const CopyToClipboard = ({controlId}) => (
  <Fragment>
    <button className="btn btn-small" title="copy" onClick={() => copyToClipboard(controlId)}>
      <i className="far fa-copy  float-right "></i>
    </button>
  </Fragment>
);
export default CopyToClipboard;
