export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const MINE_BLOCK = "MINE_BLOCK";
export const POST_ERROR = "POST_ERROR";
export const ADD_BLOCK_TO_CHAIN = "ADD_BLOCK_TO_CHAIN";
export const GET_PRIVATE_KEY = "GET_PRIVATE_KEY";
export const GENERATE_PUBLIC_KEY = "GENERATE_PUBLIC_KEY";
export const GENERATE_PUBLIC_KEY_HASH = "GENERATE_PUBLIC_KEY_HASH";
export const GENERATE_WALLET_ADDRESS = "GENERATE_WALLET_ADDRESS";
export const CREATE_WALLET = "CREATE_WALLET";
export const MY_RFPS = "MY_RFPS";
