import { CREATE_WALLET, POST_ERROR } from './types';

//get private key
export const createWallet = wallet => async dispatch => {
  console.log('in actions' + wallet);
  try {
    
    dispatch({
      type: CREATE_WALLET,
      payload: wallet
    });
  } catch (err) {
    console.log('in error');
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
