import React, { Fragment } from "react";
import Block from "../block/Block";
import BlockAnimation from "../block/BlockAnimation";
import { addBlockToBlockChain } from "../../../actions/blockchain";
import SHA256 from "crypto-js/sha256";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Info from "../../layout/Info";
import { txtBlockchain } from "../../../content/content";

const BlockChain = ({ blockchain, addBlockToBlockChain }) => {
  // let [prevHash, setPrevHash] = useState('');

  let blockNumber = 1;
  let nonance = "";
  let difficulty = "";
  let hashText = "";
  let hash = "";
  let prevHash = "";

  console.log(`Prev Hash is ${prevHash}`);

  const addToBlockchain = async () => {
    //Collect the block information
    blockNumber = document.getElementById("blockNumber").value;
    nonance = document.getElementById("nonance").value;
    difficulty = document.getElementById("difficulty").value;
    hashText = document.getElementById("hashtext").value;
    hash = document.getElementById("hash").value;
    prevHash = document.getElementById("prevHash").value;

    console.log(`prev hash is ${prevHash}`);

    //check the validity
    let newHashText = blockNumber + nonance + hashText + prevHash.toString();

    // Validate that the clock has been mined and not tampered
    if (hash !== SHA256(newHashText).toString()) {
      console.log("In IF block");
      console.log(hash);
      console.log(newHashText);
      console.log(SHA256(newHashText).toString());
      //return 'Block is tampered';
    } else {
      console.log("In ELSE block");
      // make an object
      let block = {
        blockNumber: blockNumber,
        nonance: nonance,
        difficulty: difficulty,
        hashText: hashText,
        hash: hash,
        prevHash: prevHash,
      };
      console.log(block);
      await addBlockToBlockChain(block);
      //Prepare the block section for next cycle
      document.getElementById("blockNumber").value = parseInt(blockNumber) + 1;
      document.getElementById("nonance").value = "";
      document.getElementById("difficulty").value = difficulty;
      document.getElementById("hashtext").value = "";
      document.getElementById("hash").value = "";
      document.getElementById("prevHash").value = hash.toString();
      //setPrevHash(hash.toString());
      console.log(prevHash);
    }
  };

  return (
    <Fragment>
      <div className="row">
        <Info
          id="infoBlockchain"
          className="form-control"
          header="About Blockchain "
          info={txtBlockchain}
        ></Info>
      </div>
      <br></br>
      <div className="row align-items-center">
        <div className="col-sm-4 bg-secondary">
          <div>
            <Block
              showHeader={false}
              blockNumber={blockNumber}
              flgBlockNumberReadOnly={true}
            />
          </div>
        </div>
        <div id="divAddToBlockchain" className="col-sm-2 align-items-center">
          <button
            onClick={addToBlockchain}
            className="btn btn-primary"
            visibility="hidden"
          >
            Add to BlockChain
          </button>
        </div>
        <div className="col-sm-1 align-items-center"></div>

        <div className="col-sm-5 bg-primary">
          <div>
            {blockchain !== null
              ? blockchain.blockchain.map((block) => (
                  <BlockAnimation key={block.blockNumber} block={block} />
                ))
              : {}}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

BlockChain.defaultProps = {
  blockNumber: 1,
  nonance: "",
  difficulty: "",
  hashText: "",
  hash: "",
  prevHash: "",
};

BlockChain.propTypes = {
  blockchain: PropTypes.object,
  addBlockToBlockChain: PropTypes.func,
};

const mapStateToProps = (state) => ({
  blockchain: state.blockchain,
});

export default connect(mapStateToProps, { addBlockToBlockChain })(BlockChain);
