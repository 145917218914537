import { combineReducers } from "redux";
import alert from "./alert";
import block from "./block";
import blockchain from "./blockchain";
import address from "./address";
import wallet from "./wallet";
import mydeal from "./mydeal";

export default combineReducers({
  alert,
  block,
  blockchain,
  address,
  wallet,
  mydeal,
});
