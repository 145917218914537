import React, {Fragment} from 'react'
import { motion } from "framer-motion";



const svgVariants = {
    hidden: {rotate: -180},
    visible: {
      rotate: 0,
      transition: {
        duration: 1
      }
    }
  }

const pathVariants = {
    hidden:{
      opacity:0,
      pathLength: 0
    },
    visible:{
      opacity:1,
      pathLength: 1,
      transition: {
        duration: 2,
        ease: "easeInOut"
      }
    }
  }

const ProcrainToken = ({strokeColor="white", strokeWidth= "0.5", width="90", fillOpacity = "0.0", height="60", viewBox="0 0 20 15"}) => {

    const styles = {
        pathStyle:{
            fill:"#00FFFFFF",
            stroke:strokeColor,
            strokeWidth,
            fillOpacity
        }
    }

    return <Fragment>
        <svg  width={width} height={height} viewBox={viewBox}
        variants={svgVariants}
        initial="hidden"
        animate="visible">
            <motion.path d="m 9 1 v 12 m 1 -12 v 12 m -3 -11 q 5 -1 6 2 q 0 3 -4 3 q -3 0 -3 3 q 1 3 6 2 m -3 -5 l 5 6" 
            style={styles.pathStyle}
            variants={pathVariants}
            initial="hidden"
            animate="visible">
        
            </motion.path>
        </svg>
    </Fragment>
}

export default ProcrainToken
