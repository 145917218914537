import {
  GET_PRIVATE_KEY,
  GENERATE_PUBLIC_KEY,
  GENERATE_PUBLIC_KEY_HASH,
  GENERATE_WALLET_ADDRESS,
  POST_ERROR
} from './types';

//get private key
export const getPrivateKey = privateKey => async dispatch => {
  try {
    dispatch({
      type: GET_PRIVATE_KEY,
      payload: privateKey
    });
  } catch (err) {
    console.log('in error');
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//GENERATE_PUBLIC_KEY
export const generatePublicKey = publicKey => async dispatch => {
  try {
    dispatch({
      type: GENERATE_PUBLIC_KEY,
      payload: publicKey
    });
  } catch (err) {
    console.log('in error');
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//GENERATE_PUBLIC_KEY_HASH
export const generatePublicKeyHash = publicKeyHash => async dispatch => {
  try {
    //console.log(`In Action: Public Key hash with pkhass as ${publicKeyHash}`)

    dispatch({
      type: GENERATE_PUBLIC_KEY_HASH,
      payload: publicKeyHash
    });
  } catch (err) {
    console.log('in error');
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//GENERATE_PUBLIC_KEY_HASH
export const generateWalletAddress = walletAddress => async dispatch => {
  try {
    dispatch({
      type: GENERATE_WALLET_ADDRESS,
      payload: walletAddress
    });
  } catch (err) {
    console.log('in error');
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
