import { MINE_BLOCK, POST_ERROR } from "../actions/types";

const initialState = {
  loading: false,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case MINE_BLOCK:
      return { ...state, loading: payload };

    case POST_ERROR:
      return {
        ...state,
        error: payload,
      };

    default:
      return state;
  }
}
