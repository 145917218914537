import React, { Fragment } from "react";

const BlockAnimation = (block) => {
  const blockNumber = block.block.blockNumber;
  const hash = block.block.hash;
  const hashText = block.block.hashText;
  const prevHash = block.block.prevHash;
  const blockInfo = `Block Number: ${blockNumber} \nBlock Hash: ${hash} \nMessage: ${hashText} \nPrev Block Hash: ${prevHash} `;
  return (
    <Fragment>
      <div>
        <i className="fas fa-link" />
        <div className="form-group">
          <textarea className="form-control" value={blockInfo} />
        </div>
      </div>
    </Fragment>
  );
};

export default BlockAnimation;
