import React, { Fragment } from "react";
import decentralized from "../../img/decentralized.jpg";
import immutable from "../../img/immutable.jpg";
import traceable from "../../img/traceable.jpg";
import imgClient from "../../img/client.jpg";
import imgSupplier from "../../img/supplier.jpg";
import procrainToken from "../../img/Procrain-token-blue2.jpg";
import procrainTokenBig from "../../img/Procrain-token-big.jpg";
import ProcrainWorkflow from "../animations/procrainWorkflow";
import ProcrainNetworkFlow from "../animations/procrainNetworkFlow";
import ProcrainToken from "../animations/procrainToken";

import { useSpring, animated } from "react-spring";
import { motion } from "framer-motion";


const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.1];
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

const Landing = () => {

  const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }))

  const animationVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 0.9},
    whilehover: {scale:1.2, opacity: 1}
  }


  return (

   
    // Showcase section
    <Fragment>
    
      <section
        id="sectionShowcase"
        className="landing d-flex justify-content-center align-items-center "
      
        >
           
        <div className="overlay">
          <animated.div className="landing-inner"
            onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
            onMouseLeave={() => set({ xys: [0, 0, 1] })}
            style={{ transform: props.xys.interpolate(trans) }}>
            <div id="header" className="container text-center text-white">
              <h3 className="display-5 mb-0">Distributed Procurement</h3>
              <h1 className="display-1 d-none d-sm-block">PROCRAIN</h1>
              <h2 className="font-weight-bold d-sm-none mt-3 mb-2">PROCRAIN</h2>
              <p className="lead mt-2  d-sm-block">
                A procurement platform build on Ethereum Blockchain
              </p>
              <motion.p className="mt-2 d-sm-block text-info"
              variants={animationVariants}
              initial="initial"
              animate="animate"
              whileHover="whilehover"
              transition={{repeat:Infinity, duration: 2}}>
                Connected to Ethereum Rinkeby test network
              </motion.p>

               
            </div>
          </animated.div>
          
        </div>
      </section>
      
      <section id="sectionFeatures">
        <div className="container text-center">
          <h2 className=" text-info">PROCRAIN'S FEATURES</h2>
          
          <div className="row">
              <div className="col">
              <div className="bd-example">
                  <div id="carouselExampleCaptions" className="carousel slide" data-ride="carousel">
                      <ol className="carousel-indicators">
                        <li data-target="#carouselExampleCaptions" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
                        <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
                        <li data-target="#carouselExampleCaptions" data-slide-to="3"></li>
                      </ol>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                          <img src={decentralized} className="d-block w-100" alt="decentralised"/>
                          <div className="carousel-caption">
                            <h4 className="lead">DECENTRALISED PROCUREMENT</h4>
                            <p> Procrain is a Distributed App and hence is not centrally controlled</p>
                          </div>
                        </div>
                      <div className="carousel-item">
                        <img src={immutable} className="d-block w-100" alt="immutable"/>
                        <div className="carousel-caption">
                          <h5>IMMUTABLE DATA</h5>
                          <p> Procrain data cannot be changed once logged on the Blockchain</p>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <img src={traceable} className="d-block w-100" alt="..."/>
                        <div className="carousel-caption">
                          <h5>TRACEABLE TRANSACTIONS</h5>
                          <p> Transactions made on Procrian can be verified by anyone</p>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <img src={procrainTokenBig} className="d-block w-100" alt="Token"/>
                        <div className="carousel-caption">
                          <h5> NATIVE TOKEN </h5>
                          <p> Procrain Token (<ProcrainToken width="45" height="30" strokeWidth="0.25"/>)
                          is used for payment on the Procrain platform</p>
                        </div>
                      </div>
                    </div>
                    <a className="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true"></span>
                      <span className="sr-only">Next</span>
                    </a>
                  </div>
                </div>
              </div>            
          </div>
        </div>
      </section>

      <section id="sectionExplore">
        <div className="container text-center mt-2 p-3">
          <h2 className=" text-info">HOW TO LEVERAGE PROCRAIN PLATFORM</h2>
          <div className="row m-2"> 
            <div className="col-md-6 p-3">
              <div className="card">
                <img src={imgClient} alt="" className="card-img-top" />
                  <div className="card-body">
                  <h2 className="card-title">AS A CLIENT</h2>
                  <p className="card-text">
                    As a Client you can raise an RFP (or any RFX) on Procrain
                    and manage its stages. You can add requirements for an RFP
                    and assign weight and complexity to those requirements. You
                    can accept/reject participations from the Suppliers for your
                    RFP. Any completed actions (e.g. Initiating an RFP, adding 
                    requirement etc) on Procrian gets logged on Ethereum 
                    Blockchain as transactions that can be verified when needed.
                  </p>
                  <a href="/client" className="btn btn-primary">
                    Raise an RFP
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-3">
              <div className="card">
                <img src={imgSupplier} alt="" className="card-img-top" />
               
                <div className="card-body">
                  <h2 className="card-title">AS A SUPPLIER</h2>
                  <p className="card-text">
                    Once you register as a Supplier on Procrain Platform you can
                    bid for an Initiated RFP. When your participation is accepted by the
                    Client that raised the RFP, you can respond to requirements of the
                    RFP and choose the compliance level against those requirements.Any completed actions (e.g. Registering as Supplier, Bidding an RFP 
                    etc) on Procrian gets logged on Ethereum 
                    Blockchain as transactions that can be verified when needed.
                  </p>
                  <a
                    href="/supplier"
                    className="btn btn-primary"
                  >
                    Bid for an RFP
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="sectionWorkflow">
        <div className="container text-center mt-5">
          <h2 className=" text-info">PROCRAIN USER JOURNEYS</h2>
          <div className="row">
            {/* <img src={imgArchitecture} alt="" /> */}
          
               <ProcrainWorkflow/>
                   
          </div>
        </div>
      </section>

      <section id="sectionPricing">
        <div className="container text-center">
          <h2 className=" text-info">PROCRAIN CHARGES AND FEE</h2>
          <p className="lead mt-2 d-sm-block">
            Procrain Token (<ProcrainToken strokeColor="black" width="45" height="30"/>) is used for Payment on Procrain platform
          </p>

          <p className="lead mt-2 d-sm-block">
            1 Procrain Token is equivalent to 1 Ether
          </p>
          <div className="row">
            <div className="col-md-4 my-2">  
              {/* <i className="fa fa-5x text-primary mb-3">
              <ProcrainToken/> 
                <img src={procrainToken}></img>
              </i> */}
               <ProcrainToken strokeColor="grey" strokeWidth="0.75"/>
              <blockquote className="blockquote">
                <h1 className="display-2 text-secondary">
                 1
                </h1>
                <footer >To Raise an RFP</footer>
                {/* <footer className="blockquote-footer">To Bid for an RFP</footer> */}
              </blockquote>
            </div>
            <div className="col-md-4 my-2">
            <ProcrainToken strokeColor="grey"  strokeWidth="0.75"/>  
         
              <blockquote className="blockquote">
                <h1 className="display-2 text-secondary">0.1</h1>

                <footer>
                  For Raising a Requirement
                </footer>
                <footer>
                  For Responding to a Requirement
                </footer>
              </blockquote>
            </div>
            <div className="col-md-4 my-2">
            <ProcrainToken strokeColor="grey"  strokeWidth="0.75"/>  
            
              <blockquote className="blockquote">
                <h1 className="display-2 text-secondary">1</h1>
                <footer>
                  To register as a Supplier
                </footer>
              </blockquote>
            </div>
          </div>
        </div>
      </section>

      <section id="sectionArchitecture">
        <div className="container text-center">
          <h2 className=" text-info">PROCRAIN ARCHITECTURE</h2>
          <div className="row">
            <div className="col mb-3">
              <ProcrainNetworkFlow/>
            </div>
          </div>
        </div>
      </section>

      

    </Fragment>
  );
};

export default Landing;
