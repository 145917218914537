import React, { Fragment, useState } from "react";
import Hash from "../hash/Hash";
import Spinner from "../../layout/Spinner";
import SHA256 from "crypto-js/sha256";
import { mineBlock } from "../../../actions/block";
import { connect } from "react-redux";
import Info from "../../layout/Info";
import { txtBlock } from "../../../content/content";

import PropTypes from "prop-types";

const Block = ({
  text,
  blockNumber,
  difficulty,
  showHeader,
  flgBlockNumberReadOnly,
  loading,
  mineBlock,
}) => {
  let blockHash = "";
  let nonance = 0;

  /********************************** */
  /****FUNCTION : CALCULATE HASH***** */
  /********************************** */

  const findHash = (textForHash) => SHA256(textForHash);

  /********************************** */
  /****FUNCTION : MINE BLOCK********* */
  /********************************** */

  const calculateBlock = async () => {
    let nonanceFound = false;
    let nonanceString = "";
    let prevHash = "";

    text = document.getElementById("hashtext").value;
    blockNumber = document.getElementById("blockNumber").value;
    nonance = document.getElementById("nonance").value;
    difficulty = document.getElementById("difficulty").value;

    if (document.getElementById("prevHash")) {
      prevHash = document.getElementById("prevHash").value;
    }

    nonance = 0;
    let counter = difficulty;

    //await mineBlock(true);

    document.getElementById("divSpinner").className = "d-block";
    document.getElementById("divNonance").className = "d-none";

    setTimeout(() => {
      while (counter > 0) {
        nonanceString = nonanceString + "0";
        counter = counter - 1;
      }

      let blockText = blockNumber + text + prevHash;

      while (!nonanceFound) {
        blockHash = findHash(blockText);
        if (nonanceString === blockHash.toString().slice(0, difficulty)) {
          //await mineBlock(false);

          document.getElementById("divSpinner").className = "d-none";
          document.getElementById("divNonance").className = "d-block";
          nonanceFound = !nonanceFound;
          document.getElementById("nonance").value = nonance;
          document.getElementById("hash").value = blockHash;
          document.getElementById("hashtext").value = text;
          document.getElementById("difficulty").value = difficulty;
        } else {
          nonance++;
          blockText = blockNumber + nonance + text + prevHash;
        }
      }
    }, 2000);
  };

  // console.log("value of loading is " + loading);

  // return spinnerDisplay ? (
  return (
    <Fragment>
      {showHeader && <h1 className="hashHeading">Block</h1>}
      {showHeader && <br />}

      {showHeader && (
        <Info
          id="infoSHA256"
          className="form-control"
          header="About Block (Bitcoin)"
          info={txtBlock}
        ></Info>
      )}
      {showHeader && <br />}
      <div className="form-group">
        <span className="badge badge-info">Block Number</span>
        <input
          id="blockNumber"
          type="text"
          className="form-control"
          {...(flgBlockNumberReadOnly ? { value: blockNumber } : {})}
          placeholder="block number"
          readOnly={flgBlockNumberReadOnly}
        />
        <br></br>
        <div>
          {/* {loading ? (
            <div id="divSpinner" className="d-none">
              <Spinner />
            </div>
          ) : ( */}
          <div id="divSpinner" className="d-none">
            <Spinner />
          </div>
          <div id="divNonance" className="d-block">
            <span className="badge badge-info">Nonance</span>
            <input
              id="nonance"
              type="text"
              className="form-control"
              placeholder="Nonace"
              value={nonance}
              readOnly
            />
          </div>
        </div>
        <br></br>
        <span className="badge badge-info">Difficulty</span>
        <select id="difficulty" className="form-control">
          <option>Select Block difficulty </option>
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
          <option>6</option>
        </select>
        <br />
        <div className="bg-secondary">
          <Hash
            id="hash"
            className="form-control"
            text={text}
            showHeader={false}
          />
        </div>

        <br />
        {!showHeader && (
          <input
            type="textarea"
            id="prevHash"
            className="form-control"
            placeholder="previous block hash"
            readOnly={true}
          />
        )}
        <br />
        <div className=" text-center">
          <button className="btn btn-primary btn-lg" onClick={calculateBlock}>
            Mine the Block
          </button>
        </div>
      </div>
    </Fragment>
  );
};

Block.defaultProps = {
  text: "",
  showHeader: true,
  blockNumber: 1,
  difficulty: 3,
  flgBlockNumberReadOnly: false,
  //loading: false,
};

Block.propTypes = {
  text: PropTypes.string.isRequired,
  showHeader: PropTypes.bool.isRequired,
  blockNumber: PropTypes.number.isRequired,
  difficulty: PropTypes.number.isRequired,
  flgBlockNumberReadOnly: PropTypes.bool.isRequired,
  mineBlock: PropTypes.func,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.block.loading,
});

export default connect(mapStateToProps, { mineBlock })(Block);
