const txtSHA256 =
  "SHA-2(Secure Hash Algorithm 2) is a set of cryptographic hash functions designed by the United States National Security Agency (NSA) and first published in 2001.[3][4] They are built using the Merkle–Damgård structure, from a one-way compression function itself built using the Davies–Meyer structure from a (classified) specialized block cipher.";
const txtBlock =
  "Blocks are files where data pertaining to the Bitcoin network are permanently recorded.A block records some or all of the most recent Bitcoin transactions that have not yet entered any prior blocks. Thus, a block is like a page of a ledger or record book. Each time a block is complted, it gives way to the next block in the blockchain. A block is thus a permanent store of records which, once written, cannot be altered or removed.";
const txtBlockchain =
  "A blockchain,[1][2][3] originally block chain,[4][5] is a growing list of records, called blocks, that are linked using cryptography.[1][6] Each block contains a cryptographic hash of the previous block,[6] a timestamp, and transaction data (generally represented as a Merkle tree).";

const txtPrivateKey =
  " Private key is a 256 bit number. it can be represented in many formats like Hex, Base58.In hex format its a 32 Byte number using 64 character (0-9, a-f).To generate programatically we need a source of entroy (lack of order or precitibilty) i.e. a random number generator.Cryptographically secure random number generator";

const txtPublicKey = "public Key from Private Key using ESCDA";

const txtPublicKeyHash =
  "Convert public key to public hash using RIPMED160(SHA256(public key))";

const txtWalletAddress = "Get Wallet address as BASE58CHECK(Public hash)";

const txtWallet = "Human readable name for the wallet";

const txtMetamaskNotInstalled =
  "Metamask is a cryptocurrency wallet which can be used on the Chrome, Firefox and Brave browsers. \n It’s also has browser extension. Metamask works like a bridge between normal browsers and the Ethereum blockchain. Please install metamask from https://metamask.io to assess this page";

export {
  txtSHA256,
  txtBlock,
  txtBlockchain,
  txtPrivateKey,
  txtPublicKey,
  txtPublicKeyHash,
  txtWalletAddress,
  txtWallet,
  txtMetamaskNotInstalled,
};
