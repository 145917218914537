import { MINE_BLOCK, POST_ERROR } from './types';

//Add blocks to blockchain
export const mineBlock = loading => async dispatch => {
  try {
    dispatch({
      type: MINE_BLOCK,
      payload: loading
    });
  } catch (err) {
    console.log('in error');
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
