import React, { Fragment, useState, useEffect } from "react";
import SHA256 from "crypto-js/sha256";
import PropTypes from "prop-types";
import Info from "../../layout/Info";
import CopyToClipboard from "../../layout/CopyToClipboard";
import { txtSHA256 } from "../../../content/content";

const Hash = ({ text, showHeader }) => {
  const [userInput, setInput] = useState(text);

  useEffect(() => {
    document.getElementById("hash").value = SHA256(userInput);
  }, [userInput]);

  const inputChangeHandler = (event) => {
    setInput(event.target.value);
    document.getElementById("hash").value = SHA256(userInput);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col">
          {showHeader && (
            <span>
              <h1 className="hashHeading">SHA256 Hash</h1>
            </span>
          )}
        </div>
      </div>

      <div className="form-group">
        <div className="col"></div>
        <div className="col">
          {showHeader && (
            <Info
              id="infoSHA256"
              className="form-control"
              header="About SHA256"
              info={txtSHA256}
              type="btn btn-info"
            ></Info>
          )}

          <br></br>
          <span className="badge badge-info">Text</span>

          <textarea
            id="hashtext"
            // className="hashText"
            className="form-control"
            placeholder="Enter text for SHA256 Hash calculation"
            onChange={inputChangeHandler}
            value={userInput}
          />
        </div>
        <div className="col"></div>
      </div>
      <br></br>
      <div className="form-group">
        <CopyToClipboard id="copySHA256Hash" controlId="hash"></CopyToClipboard>
        <div className="col">
          <span className="badge badge-info">SHA256 Hash</span>

          <textarea
            id="hash"
            type="textarea"
            // className="hashValue"
            className="form-control"
            readOnly={true}
          />
          <br></br>
        </div>
      </div>
    </Fragment>
  );
};

Hash.defaultProps = {
  text: "",
  showHeader: true,
};

Hash.propTypes = {
  text: PropTypes.string.isRequired,
  showHeader: PropTypes.bool.isRequired,
};

export default Hash;
