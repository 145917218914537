import React from "react";
import emailjs from "emailjs-com";

export default function ContactUs() {
  const sendEmail = (e) => {
    e.preventDefault();
    // console.log(e);
    let fromMail = document.getElementById("fromEmail").value;
    let message = document.getElementById("message").value;
    let mailContent = {
      from_mail: fromMail,
      to_name: "Procrain Owner",
      message: message,
    };

    console.log(mailContent);

    emailjs
      .send(
        "gmail",
        "template_fsqfu11",
        mailContent,
        "user_LTEKc5N673sN6sIfKNC3Y"
      )
      .then(
        (result) => {
          //console.log(result.text);
          // window.location.reload(); //this is to reload the page
          document.getElementById("fromEmail").value = "Your Email";
          document.getElementById("message").value = "Your Message";
          document.getElementById("divMailSent").className =
            "row d-block alert alert-success";
          setTimeout(() => {
            document.getElementById("divMailSent").className = "row d-none";
          }, 5000);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <form className="form-inline" onSubmit={sendEmail}>
      <div className="row">
        {/* <div className="col">
          <input type="hidden" name="contact_number" />
        </div>
        <div className="col">
           <label for="user_name">Name</label> 
          <input
            type="text"
            className="form-control"
            id="user_name"
            placeholder="Your Name"
          />
        </div> */}
        <div className="col-sm-4 mt-2 p-3">
          {/* <label for="fromEmail">Email address</label> */}
          <input
            type="email"
            className="form-control input-lg"
            id="fromEmail"
            aria-describedby="emailHelp"
            placeholder="Enter email"
          />
          {/* <small id="emailHelp" className="form-text text-muted">
            We'll never share your email with anyone else.
          </small> */}
        </div>
        <div className="col-sm-4 mt-2 p-3">
          {/* <label for="message">Message</label> */}
          <input
            type="text"
            id="message"
            className="form-control input-lg "
            placeholder="Your message"
          />
        </div>
        <div className="col-sm-4 mt-2 p-3">
          <input
            type="submit"
            className="btn btn-primary btn-lg" 
            value="Send Feedback"
          />
        </div>
      </div>
      <div id="divMailSent" className="row d-none alert alert-success">
        <strong>Success!</strong> Mail Sent.
      </div>
    </form>
  );
}
