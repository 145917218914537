import { MY_RFPS, POST_ERROR } from "../actions/types";

const initialState = {
  myDeals: [],
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case MY_RFPS:
      return { ...state, myDeals: [payload, ...state.myDeals] };

    case POST_ERROR:
      return {
        ...state,
        error: payload,
      };

    default:
      return state;
  }
}
