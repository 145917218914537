import React, { Fragment } from "react";
import spinner from "./spinner.gif";

export default ({ isLarge = true }) => (
  <Fragment>
    <img
      src={spinner}
      style={
        isLarge
          ? { width: "150px", margin: "auto", display: "block" }
          : { width: "50px", margin: "auto", display: "block" }
      }
      alt={"Transacting on blockchain.."}
    />
  </Fragment>
);
