import React, { Fragment } from "react";
import ELLEPTIC from "elliptic";
import RIPEMD160 from "ripemd160";
import sha256 from "js-sha256";

import Select from "react-select";

import { connect } from "react-redux";

const Verify = (wallets) => {
  let signature;
  let walletNames = [];
  let privateKey = [];
  let publicKey = [];
  let walletAddress = [];
  let EC = ELLEPTIC.ec;
  let ec = new EC("secp256k1");

  wallets.wallets.forEach((wallet) => {
    let input = {
      label: wallet.walletName,
      value: wallet.walletAddress,
    };
    walletNames.push(input);
  });

  const Hash = (msg) => {
    let result = sha256(msg);
    return new RIPEMD160().update(result).digest("hex");
  };

  const getKeys = (selectedValue) => {
    wallets.wallets.forEach((wallet) => {
      if (wallet.walletAddress === selectedValue.value) {
        // GET PRIVATE KEY
        privateKey.length = 0;
        let pivKey = {
          label: wallet.privateKey,
          value: wallet.privateKey,
        };
        privateKey.push(pivKey);
        console.log(privateKey);

        // GET PUBLIC KEY
        publicKey.length = 0;
        let pubKey = {
          label: wallet.publicKey,
          value: wallet.publicKey,
        };
        publicKey.push(pubKey);
        console.log(publicKey);

        // GET WALLET ADDRESS
        walletAddress.length = 0;
        let walAddress = {
          label: wallet.walletAddress,
          value: wallet.walletAddress,
        };
        walletAddress.push(walAddress);
        console.log(walletAddress);
      }
    });
  };

  const createSignature = (message, privateKey) => {
    const messageHash = Hash(message);
    const privateKeyPair = ec.keyFromPrivate(privateKey);
    const sig = ec.sign(messageHash, privateKeyPair); // generate a signature on the hashed message with the private key
    return sig;
  };

  const verifySignature = (message, publicKey, sig) => {
    const messageHash = Hash(message);
    const publicKeyPair = ec.keyFromPublic(publicKey, "hex"); // use the accessible public key to verify the signature
    const isVerified = publicKeyPair.verify(messageHash, sig);
    return isVerified;
  };

  const signMessage = () => {
    let msg = document.getElementById("message").value;
    let pvyKey = privateKey[0].value;

    if (msg && pvyKey) {
      // let pvKey = new Buffer(32);
      // pvKey.write(privateKey);
      signature = createSignature(msg, pvyKey);
      // signedMessage = crypto.sign(SHA256(msg).toString(), pvKey);
      console.log("The signed message is " + signature);
      document.getElementById("signedMessage").value = signature.toDER("hex");
    } else {
      if (msg === "") {
        alert("Please enter a message to be signed");
      } else {
        if (!pvyKey) {
          alert("Please select a Private key");
        }
      }
    }
  };

  const verifyMessage = () => {
    let msg = document.getElementById("verifyMessage").value;
    let wallAddress = walletAddress[0].value;
    let pubKey = publicKey[0].value;
    let pivKey = privateKey[0].value;
    console.log("message to verify is " + msg);
    if (msg && wallAddress) {
      let verification = verifySignature(msg, pubKey, signature);
      document.getElementById("rsltMsg").value = verification;
    } else {
      if (msg === "") {
        alert("Please enter a message to be signed");
      } else {
        if (!pivKey) {
          alert("Please select a Private key");
        }
      }
    }
  };

  return (
    <Fragment>
      <div align="center" className="form-group">
        <div>
          <h1 className="hashHeading">Sign Message</h1>
        </div>
        <div>
          <input
            type="text"
            id="message"
            className="form-control"
            placeholder="Please enter the message"
          />
        </div>

        <div>
          <Select
            id="wallet"
            onChange={getKeys}
            options={walletNames}
            placeholder="Select Wallet Name"
          ></Select>
        </div>
        <div>
          <Select
            id="selPrivateKey"
            value={privateKey}
            options={privateKey}
            placeholder="Select PrivateKey"
          ></Select>
        </div>
        <br />
        <div>
          <button className="btn btn-lg" onClick={signMessage}>
            Sign Message
          </button>
        </div>
        <br />
        <div>
          <input
            type="text"
            id="signedMessage"
            className="form-control"
            placeholder="The signed Message"
          />
        </div>
        <br />
      </div>

      <div align="center">
        <div>
          <h1>Verify Message</h1>
        </div>
        <div>
          <input
            type="text"
            id="verifyMessage"
            className="form-control"
            placeholder="Message to Verify"
          />
        </div>
        <div>
          <Select
            id="selPublicKey"
            value={publicKey}
            options={publicKey}
            placeholder="Select Public Key"
          ></Select>
          {/* <select id='publicKey'>
            <option value='#'>Select a Public Key</option>
            <option>{publicKey}</option>
          </select> */}
        </div>
        <br></br>
        <div>
          <button className="btn btn-lg" onClick={verifyMessage}>
            Verify Message
          </button>
        </div>
        <br></br>
        <div>
          <input
            type="text"
            id="rsltMsg"
            className="form-control"
            placeholder="The verifcation result"
          />
        </div>
      </div>
    </Fragment>
  );
};

Verify.propTypes = {};

const mapStateToProps = (state) => ({
  wallets: state.wallet.wallets,
});

export default connect(mapStateToProps, {})(Verify);
