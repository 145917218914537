import React, { Fragment } from "react";
import PropTypes from "prop-types";

const Info = ({ header, info, type }) => {
  const urlify = (text) => {
    var urlRegex = /(https?:\/\/[^\s]+)/g;

    return text.replace(urlRegex, function (url) {
      return '<a href="' + url + '">' + url + "</a>";
    });
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  };

  //info = urlify(info);

  return (
    <Fragment>
      <details>
        <summary className={type}>{header}</summary>
        <p className="card card-body">{info}</p>
      </details>
    </Fragment>
  );
};

Info.propTypes = {
  header: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
};

export default Info;
