import React, { Fragment, useState } from "react";
import {Modal, Button} from 'react-bootstrap'
import PropTypes from "prop-types";

const ProcrainModal = ({ header="", subHeader="", text="", onClose }) => {

  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    onClose(false);
  }
  //;
  const handleShow = () => setShow(true);


  return (
    <Fragment>
       {/* <Button variant="primary" onClick={handleShow}>
        Launch static backdrop modal
      </Button> */}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {subHeader}
        <div className="form-group">
          <div className="row">
              <div className="col">
                <textarea
                  id="txtResults"
                  className="form-control"
                  placeholder="Results"
                  value={text}
                  readOnly
                />
              </div>
            </div>
        </div>        
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary">Understood</Button> */}
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

ProcrainModal.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ProcrainModal;
