import React, { Fragment, useState, useEffect } from "react";
import smartContractObj from "../../smartcontract/SmartContract";
import Config from "../../smartcontract/config.json";
import Spinner from "../layout/Spinner";
import Info from "../../components/layout/Info";
import ProcrainModal from "../layout/ProcrainModal"



const Client = () => {

  let [procrainBalance, setProcrainBalance] = useState(0);
  let [rfPInitiationFee, setRfPInitiationFee] = useState(0);
  let [raisingRequirementFee, setRaisingRequirementFee] = useState(0);



  let [spiInitiateRFP, setSpiInitiateRFP] = useState(false);
  let [spiChangeRFPStatus, setSpiChangeRFPStatus] = useState(false);
  let [spiUpdateClientDetails, setSpiUpdateClientDetails] = useState(false);
  let [spiFetchClientDetails, setSpiFetchClientDetails] = useState(false);
  let [spiFetchBidParticipation, setSpiFetchBidParticipation] = useState(false);
  let [spiAcceptBidParticipation, setSpiAcceptBidParticipation] = useState(
    false
  );
  let [
    spiFetchBidStatusForSupplier,
    setSpiFetchBidStatusForSupplier,
  ] = useState(false);
  let [spiAddRequirement, setSpiAddRequirement] = useState(false);
  let [spiUpdateRequirement, setSpiUpdateRequirement] = useState(false);

  let [isOwner, setOwner] = useState(false);

  let [metamaskAlert, setMetamaskAlert] = useState(false);
  let [loginAlert, setLoginAlert] = useState(false);
  let [showModal, setShowModal] = useState(false);
  let [modalText, setModalText] = useState("");
  let [modalHeader, setModalHeader] = useState("");
  let [modalSubHeader, setModalSubHeader] = useState("");

  useEffect(() => {
    // Check if the contract owner
    // IF not the owner then hide the start and stop procrain button

    let fetchWeb3 = async () => {
      // You can await here
      let web3 = await smartContractObj.connectWeb3();
      let account = await smartContractObj.getaccount(web3);

      // TODO: THIS IS A BIG SECURITY THREAT
      // SOMEONE CAN CHANGE tHE CCONFIG FILE FOR OWNER AND LOG IN FROM METAMASK ACCOUNT TO THE OWNER PAGE
      // TO CHECK THE OWNER FECT THE CONTRACT OWNER FROM THE CONTRACT AND NOT HARD CODED FROM CONFIG FILE
      // THOUGH HE WILL NOT BE ABLE TO USE THE OWNER PAGE FUNCTTIONS BUT STILL CAN SEE THE OWNER'S
      // FUNCTIONALITIES
      const config = Config["owner"];

      if (account[0] == config.address) {
        setOwner(true);
        // if (document.getElementById("divProcrainOwner")) {
        //   document.getElementById("divProcrainOwner").className = "d-none";
        // }
      }
    };
    fetchWeb3();


    let myProcrainBalance = async () => {
      let myProcrainBalance = await smartContractObj.getProcrainTokenBalanceForAUserAccount();
      //console.log(myProcrainBalance.key);
      setProcrainBalance(myProcrainBalance.key);
    };

    const FetchInitiationFee = async () => {
      let intiationFee = await smartContractObj.getRFPInitiationFee();
      if (typeof intiationFee !== "undefined") {
        setRfPInitiationFee(
          parseInt(Object.values(intiationFee)) / Math.pow(10, 18)
        );
      }
    };

    const FetchRequirementFee = async () => {
      let requirementFee = await smartContractObj.getRequirementFee();
      if (typeof requirementFee !== "undefined") {
        setRaisingRequirementFee(
          Object.values(requirementFee) / Math.pow(10, 18)
        );
      }
    };

   

    smartContractObj.isMetaMaskInstalled().then((flgMetamask) => {
      if (flgMetamask) {
        smartContractObj.isLoggedIn().then((flgLoggedIn) => {
          if (flgLoggedIn) {
            //myEtherBalance();
            myProcrainBalance();
            FetchInitiationFee();
            FetchRequirementFee();

          } else {
            setLoginAlert(true);
          }
        });
      } else {
        setMetamaskAlert(true);
      }
    });

  

  }, []);

  const InitiateRFP = async () => {

    let RFPName = document.getElementById("txtRFPName").value;
    let RFPDesc = document.getElementById("txtRFPDesc").value;
    // console.log(RFPName);
    // console.log(RFPDesc);
    let strMessage = "";
    let strHeader = "RFP Initiation";
    let strSubHeader = "Initiation Status"

    if(procrainBalance > rfPInitiationFee) {
      setSpiInitiateRFP(true);
      let rfpStaus = await smartContractObj.initiateRFP(RFPName, RFPDesc);
      setSpiInitiateRFP(false);
      //console.log(JSON.stringify(rfpStaus));

      rfpStaus.key.message ? (strMessage = rfpStaus.key.message ) : 
        (
          strMessage = `RFP Id : ${rfpStaus.key.events.RFPInitiated.returnValues[0]} \nRFP Name : ${rfpStaus.key.events.RFPInitiated.returnValues[1]} \n RFP Desc : ${rfpStaus.key.events.RFPInitiated.returnValues[2]} \n`
      //strMessage = JSON.stringify(rfpStaus.key.events.RFPInitiated.returnValues)
        );
      rfpStaus.key.message ? (strSubHeader = "Initiation Status Failed" ) : (strSubHeader = "Initiation Status Successful");

      setModalText(strMessage)
      setModalHeader(strHeader)
      setModalSubHeader(strSubHeader)
      setShowModal(true)
    }else{
      strMessage = "You Do not have enough PRC to raise an RFP"
      setModalText(strMessage)
      setModalHeader("RFP Initiation Status")
      setModalSubHeader("Insufficient Fund")
      setShowModal(true)
    }
   
  };


  const ChangeRFPStatus = async () => {
    let RFPid = document.getElementById("txtChgRFPid").value;
    let status = document.getElementById("selChangeRFPStatus").value;

    setSpiChangeRFPStatus(true);
    let rfpStaus = await smartContractObj.changeRFPStatus(RFPid, status);
    setSpiChangeRFPStatus(false);

    let strMessage = ''
    let strSubHeader = ''

    // When user rejects the Metamask (User Rejects)
    if(rfpStaus.key.message ){
      strMessage = rfpStaus.key.message
      strSubHeader = "Change Status Failed"
    }else if(rfpStaus.key.status){
       // When EVM is reverted (SmartContract Rejects)
      strMessage = "Status Changed Successfully"
      strSubHeader = "Change Status Successful"
    }else {
      //EVM rejected the transacrion
      strMessage = "Transaction Reverted by SmartContract. Only an Initiated RFP can be Released. Only a Released RFP can be closed. "
      strSubHeader = "Change Status Failed"
    }

    setModalText(strMessage)
    setModalHeader("RFP Status Change")
    setModalSubHeader(strSubHeader)
    setShowModal(true)
  };

  
 
  const FetchBidParticipation = async () => {
    let rfpId = document.getElementById("bidInterestRFPId").value;

    setSpiFetchBidParticipation(true);
    let bidParticipation = await smartContractObj.fetchBidParticipation(rfpId);
    setSpiFetchBidParticipation(false);
    let displayResult = "";
    //console.log(bidParticipation);

    bidParticipation.key.forEach((participant) => {
      displayResult = `${displayResult} \n RFP ID : ${participant.returnValues.RFPid}
      \n Participation Reference Number : ${participant.returnValues.RFPParticipationRefNo} 
      \n Supplier Address : ${participant.returnValues.supplierAddress} \n`;
    });

    //document.getElementById("txtResults").value = displayResult;
    setModalText(displayResult)
    setModalHeader("Fetch Bid Participation")
    setModalSubHeader("Fetch Bid Participation Status")
    setShowModal(true)
  };

  const AcceptBidParticipation = async () => {
    let participationRefNo = document.getElementById("participationRefNo")
      .value;

    setSpiAcceptBidParticipation(true);
    let acceptBidParticipationStatus = await smartContractObj.acceptBidParticipation(
      participationRefNo
    );
    setSpiAcceptBidParticipation(false);
    // console.log(acceptBidParticipationStatus);
    //document.getElementById("txtResults").value = acceptBidParticipationStatus;

    let strMessage = ''
    let strSubHeader = ''

    // When user rejects the Metamask (User Rejects)
    if(acceptBidParticipationStatus.key.message ){
      strMessage = acceptBidParticipationStatus.key.message
      strSubHeader = "Accept Bid Participation Failed"
    }else if(acceptBidParticipationStatus.key.status){
       // When EVM accepts
      strMessage = "Bid Participation Accepted Successfully"
      strSubHeader = "Bid Participation Accepted Successful"
    }else {
      //EVM rejected the transacrion
      strMessage = "Transaction Reverted by SmartContract.  "
      strSubHeader = "Accept Bid Participation Failed"
    }

    setModalText(strMessage)
    setModalHeader("Accept Bid Participation")
    setModalSubHeader(strSubHeader)
    setShowModal(true)
  };

  const RejectBidParticipation = async () => {
    let participationRefNo = document.getElementById("participationRefNo")
      .value;

    setSpiAcceptBidParticipation(true);
    let rejectBidParticipationStatus = await smartContractObj.rejectBidParticipation(
      participationRefNo
    );
    setSpiAcceptBidParticipation(false);
    // console.log(acceptBidParticipationStatus);
    //document.getElementById("txtResults").value = acceptBidParticipationStatus;

    let strMessage = ''
    let strSubHeader = ''

    // When user rejects the Metamask (User Rejects)
    if(rejectBidParticipationStatus.key.message ){
      strMessage = rejectBidParticipationStatus.key.message
      strSubHeader = "Reject Bid Participation Failed"
    }else if(rejectBidParticipationStatus.key.status){
       // When EVM accepts
      strMessage = "Bid Participation Rejected Successfully"
      strSubHeader = "Bid Participation Rejected Successful"
    }else {
      //EVM rejected the transacrion
      strMessage = "Transaction Reverted by SmartContract.  "
      strSubHeader = "Reject Bid Participation Failed"
    }

    setModalText(strMessage)
    setModalHeader("Reject Bid Participation")
    setModalSubHeader(strSubHeader)
    setShowModal(true)
  };


  const FetchBidStatusForSupplier = async () => {
    let statusParticipationRefNo = document.getElementById(
      "statusParticipationRefNo"
    ).value;

    setSpiFetchBidStatusForSupplier(true);
    let fetchBidStatusForSupplierStatus = await smartContractObj.fetchBidStatusForSupplier(
      statusParticipationRefNo
    );
    setSpiFetchBidStatusForSupplier(false);

    console.log(fetchBidStatusForSupplierStatus.key);
    let txtStatus = "";
    if(fetchBidStatusForSupplierStatus.key.name){
      if (fetchBidStatusForSupplierStatus.key.name == "acceptBidParticipation") {
        txtStatus = "Participation Request Accepted";
        
      } else if (fetchBidStatusForSupplierStatus.key.name == "rejectBidParticipation") {
        txtStatus = "Participation Request Rejected";
      } else  {
        txtStatus = "Participation Request Pending"; 
      }
    }else{
      txtStatus = "Participation Request Pending";
    }
  
    // document.getElementById("txtResults").value = txtStatus;
    setModalText(txtStatus)
    setModalHeader("Fetch Bid status for Supplier")
    setModalSubHeader("Fetch Bid status for Supplier")
    setShowModal(true)
  };

  const AddRequirement = async () => {
    let rfpID = document.getElementById("reqRFPid").value;
    let reqName = document.getElementById("rfpRequirementName").value;
    let reqDesc = document.getElementById("rfpRequirementDesc").value;
    let reqWeight = document.getElementById("rfpRequirementWeight").value;
    let reqPoint = document.getElementById("rfpRequirementPoint").value;
    let reqActive = document.getElementById("rfpRequirementActive").value;


    if(parseInt(procrainBalance) > parseInt(raisingRequirementFee)) {
      setSpiAddRequirement(true);
      let addRequirement = await smartContractObj.addRequirement(
        rfpID,
        reqName,
        reqDesc,
        reqWeight,
        reqPoint,
        reqActive
      );
      setSpiAddRequirement(false);
  
      // console.log(addRequirement);
      //document.getElementById("txtResults").value = addRequirement;
      let strMessage = `Requirement ID: ${addRequirement.key.events.RequirementAdded.returnValues.requirementId}
      \n RFP ID: ${addRequirement.key.events.RequirementAdded.returnValues.RFPid}
      \n Reuirement Name: ${addRequirement.key.events.RequirementAdded.returnValues.name}
      \n Requirement Description: ${addRequirement.key.events.RequirementAdded.returnValues.description}`
      setModalText(strMessage)
      setModalHeader("Add Requirement")
      setModalSubHeader("Requirement added successfully")
      setShowModal(true)
    }else{
      setModalText("Not enough funds to raise the Requirement. Please purchase more Procrain Token")
      setModalHeader("Add Requirement")
      setModalSubHeader("Add Requirement Failed")
      setShowModal(true)
    }


    
  };

  const UpdateRequirement = async () => {
    let reqID = document.getElementById("updateReqId").value;
    let reqName = document.getElementById("updateRfpRequirementName").value;
    let reqDesc = document.getElementById("updateRfpRequirementDesc").value;
    let reqWeight = document.getElementById("updateRfpRequirementWeight").value;
    let reqActive = document.getElementById("updateRfpRequirementActive").value;

    setSpiUpdateRequirement(true);
    let updateRequirement = await smartContractObj.updateRequirement(
      reqID,
      reqName,
      reqDesc,
      reqWeight,
      reqActive
    );
    setSpiUpdateRequirement(false);

    // console.log(addRequirement);
    //document.getElementById("txtResults").value = updateRequirement;
    setModalText(updateRequirement)
    setModalHeader("Update Requirement")
    setModalSubHeader("Update Requirement status")
    setShowModal(true)
  };

  let infoHeader = "";
  let infoText = "";
  let infoType = "";

  if (metamaskAlert) {
    infoHeader = "Metamask not installed";
    //infoText = txtMetamaskNotInstalled;
    infoText = "Please install metamask to see details";
    infoType = "btn btn-danger";
  } else if (loginAlert) {
    infoHeader = "Login to Metamask";
    infoText = "Please log into to Metamask to access this page";
    infoType = "btn btn-info";
  }



  return (
    <Fragment>
      {showModal ? (<ProcrainModal header={modalHeader} subHeader={modalSubHeader} text={modalText} onClose={setShowModal}/>) : (null)}
      <div className="form-group">
         <div id="divMainBody" className="row mt-2">
          <div id="divClientDashboard" className="col">
            <div className="card-group">
              <div id="crdInitiateRFP" className="card  mr-2">
                {/* <img
                  className="card-img-top"
                  src={imgClient}
                  alt="Card image cap"
                /> */}
                <div className="card-body">
                  <h5 className="card-title">Initiate RFP</h5>
                  {metamaskAlert || loginAlert ? (
                    <Info
                      header={infoHeader}
                      info={infoText}
                      type={infoType}
                    ></Info>
                  ) : (
                    <div>
                      {spiInitiateRFP ? (
                        <Spinner />
                      ) : (
                        <div>
                          <input
                            id="txtRFPName"
                            className="form-control"
                            placeholder="RFP Name"
                          ></input>
                          <input
                            id="txtRFPDesc"
                            className="form-control  mt-2"
                            placeholder="RFP Description"
                          ></input>
                          <div className="d-flex justify-content-center">
                            <button
                              id="btnInitiateRFP"
                              className="btn btn-primary btn-small  mt-2"
                              onClick={InitiateRFP}
                            >
                              Initiate an RFP
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div id="crdChangeRFPStatus" className="card  mr-2">
                {/* <img
                  className="card-img-top"
                  src={imgSupplier}
                  alt="Card image cap"
                /> */}
                <div className="card-body">
                  <h5 className="card-title">Change RFP status</h5>
                  {metamaskAlert || loginAlert ? (
                    <Info
                      header={infoHeader}
                      info={infoText}
                      type={infoType}
                    ></Info>
                  ) : (
                    <div>
                      {spiChangeRFPStatus ? (
                        <Spinner />
                      ) : (
                        <div>
                          <input
                            id="txtChgRFPid"
                            className="form-control"
                            placeholder="RFP ID"
                          ></input>
                          <select
                            id="selChangeRFPStatus"
                            className="form-control mt-2"
                            name="selChangeRFPStatus"
                          >
                            <option value="0">Select Status</option>
                            <option value="Release">Release</option>
                            <option value="Close">Close</option>
                            <option value="Discard">Discard</option>
                          </select>
                          <div className="d-flex justify-content-center">
                            <button
                              id="btnchgRFStatus"
                              className="btn btn-primary btn-small mt-2"
                              onClick={ChangeRFPStatus}
                            >
                              Change Status
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        
        <div className="row mt-2">
          <div className="col">
            {/* This is start of Third Row of Cards */}

            <div className="card-group">
              <div id="crdAddARequirement" className="card  mr-2">
                {/* <img
                  className="card-img-top"
                  src={imgSlider3}
                  alt="Card image cap"
                /> */}
                <div className="card-body">
                  <h5 className="card-title">Add a Requirement</h5>
                  {metamaskAlert || loginAlert ? (
                    <Info
                      header={infoHeader}
                      info={infoText}
                      type={infoType}
                    ></Info>
                  ) : (
                    <div>
                      {spiAddRequirement ? (
                        <Spinner />
                      ) : (
                        <div>
                          <input
                            name="reqRFPid"
                            id="reqRFPid"
                            className="form-control"
                            placeholder="RFP ID"
                          ></input>
                          <input
                            name="rfpRequirementName"
                            id="rfpRequirementName"
                            className="form-control mt-1"
                            placeholder="Requirement Name"
                          ></input>
                          <input
                            name="rfpRequirementDesc"
                            id="rfpRequirementDesc"
                            className="form-control mt-1"
                            placeholder="Requirement Desc"
                          ></input>
                          <select
                            id="rfpRequirementWeight"
                            className="form-control mt-1"
                            name="rfpRequirementWeight"
                          >
                            <option value="0">Requirement Criticality</option>
                            <option value="1">Very Low</option>
                            <option value="2">Low</option>
                            <option value="3">Medium</option>
                            <option value="4">High</option>
                            <option value="5">Very High</option>
                          </select>
                          <select
                            id="rfpRequirementPoint"
                            className="form-control mt-1"
                            name="rfpRequirementPoint"
                          >
                            <option value="0">Select Points</option>
                            <option value="1">20 points</option>
                            <option value="2">40 points</option>
                            <option value="3">60 points</option>
                            <option value="4">80 points</option>
                            <option value="5">100 points</option>
                          </select>
                          <select
                            id="rfpRequirementActive"
                            className="form-control mt-1"
                            name="rfpRequirementActive"
                          >
                            <option value="1">Active</option>
                            <option value="0">In Active</option>
                          </select>
                          <div className="d-flex justify-content-center">
                            <button
                              id="btnAddRequirement"
                              className="btn btn-primary mt-1"
                              onClick={AddRequirement}
                            >
                              Add Requirement
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {/*<div id="crdUpdateReq" className="d-block card  mr-2">
               
                 <div className="card-body">
                  <h5 className="card-title">Update Requirements</h5>
                  {metamaskAlert || loginAlert ? (
                    null
                    // <Info
                    //   header={infoHeader}
                    //   info={infoText}
                    //   type={infoType}
                    // ></Info>
                  ) : (
                    null
                    // <div>
                    //   {spiUpdateRequirement ? (
                    //     <Spinner />
                    //   ) : (
                    //     <div>
                    //       <input
                    //         name="updateReqId"
                    //         id="updateReqId"
                    //         className="form-control"
                    //         placeholder="Requirement ID"
                    //       ></input>
                    //       <input
                    //         name="updateRfpRequirementName"
                    //         id="updateRfpRequirementName"
                    //         className="form-control mt-1"
                    //         placeholder="Requirement Name"
                    //       ></input>
                    //       <input
                    //         name="updateRfpRequirementDesc"
                    //         id="updateRfpRequirementDesc"
                    //         className="form-control mt-1"
                    //         placeholder="Requirement Desc"
                    //       ></input>
                    //       <select
                    //         id="updateRfpRequirementWeight"
                    //         name="updateRfpRequirementWeight"
                    //         className="form-control mt-1"
                    //       >
                    //         <option value="0">Select Weight</option>
                    //         <option value="1">Very Low</option>
                    //         <option value="2">Low</option>
                    //         <option value="3">Medium</option>
                    //         <option value="4">High</option>
                    //         <option value="5">Very High</option>
                    //       </select>
                    //       <select
                    //         id="rfpUpdateRequirementPoint"
                    //         className="form-control mt-1"
                    //         name="rfpUpdateRequirementPoint"
                    //       >
                    //         <option value="0">Select Points</option>
                    //         <option value="1">20 points</option>
                    //         <option value="2">40 points</option>
                    //         <option value="3">60 points</option>
                    //         <option value="4">80 points</option>
                    //         <option value="5">100 points</option>
                    //       </select>
                    //       <select
                    //         id="updateRfpRequirementActive"
                    //         name="updateRfpRequirementActive"
                    //         className="form-control mt-1"
                    //       >
                    //         <option value="1">Active</option>
                    //         <option value="0">In Active</option>
                    //       </select>
                    //       <div className="d-flex justify-content-center">
                    //         <button
                    //           id="btnUpdateRequirement"
                    //           className="btn btn-primary mt-1"
                    //           onClick={UpdateRequirement}
                    //         >
                    //           Update Requirement
                    //         </button>
                    //       </div>
                    //     </div>
                    //   )}
                    // </div>
                  )} 
                 </div> 
              </div>*/}
            </div>
            {/* This is end of third Row of Card */}
            
          </div>
        </div>

        <div className="row mt-2">
          <div className="col">
            {/* This is start of Second Row of Cards */}

            <div className="card-group">
              <div id="crdFetchBidParticipation" className="card  mr-2">
                {/* <img
                  className="card-img-top"
                  src={imgSlider3}
                  alt="Card image cap"
                /> */}
                <div className="card-body">
                  <h5 className="card-title">Fetch Bid Participation</h5>
                  {spiFetchBidParticipation ? (
                    <Spinner />
                  ) : (
                    <div>
                      <input
                        name="bidInterestRFPId"
                        id="bidInterestRFPId"
                        className="form-control"
                        placeholder="RFP ID"
                      ></input>
                      <div className="d-flex justify-content-center">
                        <button
                          id="btnShowtBidParticipation"
                          className="btn btn-primary mt-2"
                          onClick={FetchBidParticipation}
                        >
                          Show Bid Participation
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div id="crdAcceptBidParticipation" className="card  mr-2">
                {/* <img
                  className="card-img-top"
                  src={imgSlider4}
                  alt="Card image cap"
                /> */}
                <div className="card-body">
                  <h5 className="card-title">Accept/Reject Supplier Bid Participation</h5>
                  {metamaskAlert || loginAlert ? (
                    <Info
                      header={infoHeader}
                      info={infoText}
                      type={infoType}
                    ></Info>
                  ) : (
                    <div>
                      {spiAcceptBidParticipation ? (
                        <Spinner />
                      ) : (
                        <div>
                          <input
                            name="participationRefNo"
                            id="participationRefNo"
                            className="form-control"
                            placeholder="Bid Participation Ref No"
                          ></input>
                          <div className="d-flex justify-content-center">
                            <button
                              id="btnAcceptBidParticipation"
                              className="btn btn-primary mt-2"
                              onClick={AcceptBidParticipation}
                            >
                              Accept  Participation
                            </button>
                            <button
                              id="btnAcceptBidParticipation"
                              className="btn btn-primary btn-danger mt-2"
                              onClick={RejectBidParticipation}
                            >
                              Reject Participation
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* This is end of Second Row of Card */}
          </div>
          
        </div>
        <div className="row mt-2">
              {/* This is begining of fourth Row of Card */}
              <div className="col">
                <div
                  id="crdFetchBidStatusForSupplier"
                  className="d-block card mr-2"
                >
                  {/* <img
                  className="card-img-top"
                  src={imgSlider2}
                  alt="Card image cap"
                /> */}
                  <div className="card-body">
                    <h5 className="card-title">
                      Fetch Bid status for a Supplier
                    </h5>
                    {spiFetchBidStatusForSupplier ? (
                      <Spinner />
                    ) : (
                      <div>
                        <input
                          name="statusParticipationRefNo"
                          id="statusParticipationRefNo"
                          className="form-control"
                          placeholder="Bid Participation Reference number"
                        ></input>
                        <div className="d-flex justify-content-center">
                          <button
                            id="btnFetchBidStatusForSupplier"
                            className="btn btn-primary mt-2"
                            onClick={FetchBidStatusForSupplier}
                          >
                            Fetch Bid Status
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* This is end of fourth Row of Card */}
            </div>                
      </div>
    </Fragment>
  );
};

export default Client;
