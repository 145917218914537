import React, {Fragment, useState} from 'react'
import { motion } from "framer-motion";
import {FaUser}  from "react-icons/fa";

const ProcrainNetworkFlow = () => {

    const [transitionDelay, setTransitionDelay] = useState(3);

    const styles = {
        rectOutline :{
            fill: "white",
            stroke:"grey",
            strokeWidth:"1"
        },
        rectInline :{
            fill: "grey",
            stroke:"grey",
            strokeWidth:"1",
            opacity: "0.4"
        },
        lineOutline:{
            stroke:"grey"
        },
        triangleOutline:{
            fill:"grey",
            stroke:"grey",
            strokeWidth:"1"
          }
    }

    const animationVariants = {
        initial: { opacity: 0 },
        animate: { opacity: 1},
        whilehover: {scale:1.1, opacity: 0.9}
      }
    

    return <Fragment>
        <svg  viewBox="0 0 500 200">
            {/* LOCAL START */}
            {/* <rect x="10" y="10" width="160" height="180" style={styles.rectOutline}/> */}
            <text x="100" y="25" fontSize="10">LOCAL</text>
            <FaUser x="30" y="90" />
            <text x="30" y="180" fontSize="10">User</text>
            <motion.line x1="43" y1="95" x2="90" y2="95" style={styles.lineOutline}></motion.line>
            {/* <polygon points="75,90,75,100,85,95" style={styles.triangleOutline}></polygon> */}
            <motion.rect x="90" y="40" width="60" height="130" style={styles.rectInline}
             variants={animationVariants}
             initial="initial"
             animate="animate"
             whileHover="whilehover"
             transition={{ delay: 1*transitionDelay ,type:"spring"}}
            />
            <motion.text 
             initial={{
                x:0,
                y:0 ,
                fontSize:0,
                rotate:0,
            }}
            animate={{
                x:100,
                y:130 ,
                fontSize:8,
                rotate:-90
            }}>Chrome Browser
            </motion.text>
            <motion.text 
             initial={{
                x:0,
                y:0 ,
                fontSize:0,
                rotate:0,
            }}
            animate={{
                x:120,
                y:135 ,
                fontSize:8,
                rotate:-90
            }}>Metamask Extension
            </motion.text>
            <motion.text 
             initial={{
                x:0,
                y:0 ,
                fontSize:0,
                rotate:0,
            }}
            animate={{
                x:140,
                y:145 ,
                fontSize:8,
                rotate:-90
            }}>Ether Balace in Metamask
            </motion.text>
            <line x1="150" y1="95" x2="180" y2="95" style={styles.lineOutline}></line>
            {/* <polygon points="165,90,165,100,170,95" style={styles.triangleOutline}></polygon> */}
            <text x="95" y="180" fontSize="10">Web</text>
            <text x="90" y="190" fontSize="10">Browser</text>
          

            {/* LOCAL END */}
            {/* HOSTED START */}
            {/* <rect x="160" y="10" width="160" height="180" style={styles.rectOutline}/> */}
            <text x="220" y="25" fontSize="10">HOSTED</text>
            <motion.rect x="180" y="40" width="60" height="130" style={styles.rectInline}
             variants={animationVariants}
             initial="initial"
             animate="animate"
             whileHover="whilehover"
             transition={{ delay: 2*transitionDelay ,type:"spring"}}/>
            <motion.text 
             initial={{
                x:0,
                y:0 ,
                fontSize:0,
                rotate:0,
            }}
            animate={{
                x:200,
                y:130 ,
                fontSize:8,
                rotate:-90
            }}>React Based App
            </motion.text>
            <motion.text 
             initial={{
                x:0,
                y:0 ,
                fontSize:0,
                rotate:0,
            }}
            animate={{
                x:220,
                y:130 ,
                fontSize:8,
                rotate:-90
            }}>Hosted on AWS
            </motion.text>
            <text x="183" y="180" fontSize="10">Procrain</text>
            <text x="180" y="190" fontSize="10">Webservice</text>
            <line x1="240" y1="95" x2="250" y2="95" style={styles.lineOutline}></line>
            <rect x="250" y="40" width="60" height="130" style={styles.rectInline}/>
            <motion.text 
             initial={{
                x:0,
                y:0 ,
                fontSize:0,
                rotate:0,
            }}
            animate={{
                x:270,
                y:130 ,
                fontSize:8,
                rotate:-90
            }}>API to connect to
            </motion.text>
            <motion.text 
             initial={{
                x:0,
                y:0 ,
                fontSize:0,
                rotate:0,
            }}
            animate={{
                x:290,
                y:140 ,
                fontSize:8,
                rotate:-90
            }}>Ethereum Blockchain
            </motion.text>
            <text x="265" y="180" fontSize="10">Infura</text>
            <text x="260" y="190" fontSize="10">Web API</text>
            <line x1="310" y1="95" x2="350" y2="95" style={styles.lineOutline}></line>
            {/* HOSTED END */}
            {/* DISTRIBUTED START */}
            {/* <rect x="320" y="10" width="160" height="180" style={styles.rectOutline}/> */}
            <text x="360" y="25" fontSize="10">DISTRIBUTED</text>
            <rect x="350" y="40" width="80" height="130" style={styles.rectInline}
             variants={animationVariants}
             initial="initial"
             animate="animate"
             transition={{ delay: 4*transitionDelay ,type:"spring"}}/>
            <motion.text 
             initial={{
                x:0,
                y:0 ,
                fontSize:0,
                rotate:0,
            }}
            animate={{
                x:380,
                y:150 ,
                fontSize:8,
                rotate:-90
            }}>Procrain Smart contract
            </motion.text>
            <motion.text 
             initial={{
                x:0,
                y:0 ,
                fontSize:0,
                rotate:0,
            }}
            animate={{
                x:400,
                y:168 ,
                fontSize:8,
                rotate:-90
            }}>Procrain Token contract (ERC-20)
            </motion.text>
            <text x="350" y="180" fontSize="10">Ethereum (Rinkeby)</text>
            <text x="365" y="190" fontSize="10">Blockchain</text>
            {/* DISTRIBUTED END */}
        </svg>
    </Fragment>
}

export default ProcrainNetworkFlow ; 