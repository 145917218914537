import React, { Fragment, useState, useEffect } from "react";
import smartContractObj from "../../smartcontract/SmartContract";
import Config from "../../smartcontract/config.json";
import Spinner from "../layout/Spinner";
import imgStart from "../../img/client.jpg";
import imgStop from "../../img/supplier.jpg";
import imgFee from "../../img/slider/slider1.jpg";
import ProcrainModal from "../layout/ProcrainModal";

const Owner = () => {
  let [spiStopProcrain, setSpiStopProcrain] = useState(false);
  let [spiStartProcrain, setSpiStartProcrain] = useState(false);
  let [spiSetInitiationFee, setSpiSetInitiationFee] = useState(false);
  let [spiSetRegistrationFee, setSpiSetRegistrationFee] = useState(false);
  let [spiSetRequirementFee, setSpiSetRequirementFee] = useState(false);
  let [spiSetResponseFee, setSpiSetResponseFee] = useState(false);
  let [spiGetBalance, setSpiGetBalance] = useState(false);
  let [spiWithdrawFromProcrain, setSpiWithdrawFromProcrain] = useState(false);
  let [spiGetTokenBalance, setSpiGetTokenBalance] = useState(false);
  let [
    spiWithdrawTokenFromProcrain,
    setSpiWithdrawTokenFromProcrain,
  ] = useState(false);

  let [isOwner, setOwner] = useState(false);
  let [showModal, setShowModal] = useState(false);
  let [modalText, setModalText] = useState("");
  let [modalHeader, setModalHeader] = useState("");
  let [modalSubHeader, setModalSubHeader] = useState("");

  useEffect(() => {
    // Check if the contract owner
    // IF not the owner then hide the start and stop procrain button
    let fetchWeb3 = async () => {
      // You can await here
      let web3 = await smartContractObj.connectWeb3();
      let account = await smartContractObj.getaccount(web3);

      const config = Config["owner"];

      if (account[0] === config.address) {
        setOwner(true);
      }
    };
    fetchWeb3();
  });

  const stopProcrain = async () => {
    setSpiStopProcrain(true);
    let procrainStatus = await smartContractObj.stopProcrain();
    setSpiStopProcrain(false);
    //console.log(procrainStatus);
    setModalText(procrainStatus);
    setModalHeader("Stop Procrain ");
    setModalSubHeader("Stop Procrain status");
    setShowModal(true);
  };

  const startProcrain = async () => {
    setSpiStartProcrain(true);
    let procrainStatus = await smartContractObj.startProcrain();
    setSpiStartProcrain(false);
    //console.log(procrainStatus);
    setModalText(procrainStatus);
    setModalHeader("Start Procrain ");
    setModalSubHeader("Start Procrain status");
    setShowModal(true);
  };

  const setRFPInitiationFee = async () => {
    let initiatiationFee = document.getElementById("txtRFPInitiationFee").value;
    //console.log(initiatiationFee);
    setSpiSetInitiationFee(true);
    let initiationFeeStatus = await smartContractObj.setRFPInitiationFee(
      initiatiationFee
    );
    setSpiSetInitiationFee(false);
    //document.getElementById("txtResults").value = initiationFeeStatus.key;
    setModalText(initiationFeeStatus.key);
    setModalHeader("RFP Initiation Fee");
    setModalSubHeader("RFP Initiation Fee status");
    setShowModal(true);
  };

  const setSupplierRegistrationFee = async () => {
    let supplierRegistrationFee = document.getElementById(
      "txtSupplierRegistrationFee"
    ).value;
    console.log(supplierRegistrationFee);
    setSpiSetRegistrationFee(true);
    let supplierRegistrationStatus = await smartContractObj.setSupplierRegistrtationFee(
      supplierRegistrationFee
    );
    setSpiSetRegistrationFee(false);
    //document.getElementById("txtResults").value = supplierRegistrationStatus.key;
    setModalText(supplierRegistrationStatus.key);
    setModalHeader("Supplier Registration Fee");
    setModalSubHeader("Supplier Registration Fee status");
    setShowModal(true);  
  };

  const setRequirementFee = async () => {
    let requirementFee = document.getElementById("txtRequirementFee").value;
    setSpiSetRequirementFee(true);
    let requirementFeeStatus = await smartContractObj.setRequirementFee(
      requirementFee
    );
    setSpiSetRequirementFee(false);
    //console.log(requirementFeeStatus);
    setModalText(requirementFeeStatus.key);
    setModalHeader("Set Requirement Fee");
    setModalSubHeader("Set Requirement Fee status");
    setShowModal(true); 
  };

  const setResponseFee = async () => {
    let responseFee = document.getElementById("txtResponseFee").value;
    setSpiSetResponseFee(true);
    let responseFeeStatus = await smartContractObj.setResponseFee(responseFee);
    setSpiSetResponseFee(false);
    //console.log(responseFeeStatus);
    setModalText(responseFeeStatus.key);
    setModalHeader("Set Response Fee");
    setModalSubHeader("Set Response Fee status");
    setShowModal(true); 
  };

  const getProcrainBalance = async () => {
    setSpiGetBalance(true);
    let procrainBalance = await smartContractObj.getProcrainBalance();
    setSpiGetBalance(false);
    console.log(procrainBalance);
    setModalText(procrainBalance.key);
    setModalHeader("Get Procrain Balance");
    setModalSubHeader("Get Procrain Balance status");
    setShowModal(true); 
  };

  const withdrawFromProcrain = async () => {
    let amountToWithdraw = document.getElementById("txtAmountToWithdraw").value;
    setSpiWithdrawFromProcrain(true);
    let withdrawStatus = await smartContractObj.withdrawFromProcrain(
      amountToWithdraw
    );
    setSpiWithdrawFromProcrain(false);
    //console.log(withdrawStatus);
    setModalText(withdrawStatus.key);
    setModalHeader("Withdraw from Procrain");
    setModalSubHeader("Withdraw from Procrain status");
    setShowModal(true); 
  };

  const getProcrainTokenBalance = async () => {
    setSpiGetTokenBalance(true);
    let procrainTokenBalance = await smartContractObj.getProcrainTokenBalance();
    setSpiGetTokenBalance(false);
    //console.log(procrainTokenBalance);
    setModalText(procrainTokenBalance.key);
    setModalHeader("Get Procrain Balance");
    setModalSubHeader("Get Procrain Balance status");
    setShowModal(true); 
  };

  const withdrawTokenFromProcrain = async () => {
    let amountOfTokenToWithdraw = document.getElementById(
      "txtAmountOfTokenToWithdraw"
    ).value;
    setSpiWithdrawTokenFromProcrain(true);
    let withdrawTokenStatus = await smartContractObj.withdrawTokenFromProcrain(
      amountOfTokenToWithdraw
    );
    setSpiWithdrawTokenFromProcrain(false);
    //console.log(withdrawTokenStatus);
    setModalText(withdrawTokenStatus.key);
    setModalHeader("Withdraw from Procrain");
    setModalSubHeader("Withdraw from Procrain status");
    setShowModal(true);
  };

  return (
    <Fragment>
      {isOwner ? (
        <div id="divProcrainOwner" className="form-group d-block">
           {showModal ? (<ProcrainModal header={modalHeader} subHeader={modalSubHeader} text={modalText} onClose={setShowModal}/>) : (null)}
          <div id="divOwnerSection" className="row mt-2">
            <div className="col">
              <div className="card-group">
                <div id="crdStopProacrain" className="card  mr-2">
                  <img
                    className="card-img-top"
                    src={imgStart}
                    alt="Card image cap"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Stop Procrain</h5>
                    {spiStopProcrain ? (
                      <Spinner />
                    ) : (
                      <button
                        id="btnstopProcrain"
                        onClick={stopProcrain}
                        className="btn btn-primary"
                      >
                        Stop Procrain
                      </button>
                    )}
                  </div>
                </div>
                <div id="crdStartProacrain" className="card  mr-2">
                  <img
                    className="card-img-top"
                    src={imgStop}
                    alt="Card image cap"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Start Procrain</h5>
                    {spiStartProcrain ? (
                      <Spinner />
                    ) : (
                      <button
                        id="btnstartProcrain"
                        onClick={startProcrain}
                        className="btn btn-primary"
                      >
                        Start Procrain
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <div className="card-group">
                <div id="crdIniatiationFee" className="card  mr-2">
                  <img
                    className="card-img-top"
                    src={imgFee}
                    alt="Card image cap"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Set RFP Initiation Fee</h5>
                    {spiSetInitiationFee ? (
                      <Spinner />
                    ) : (
                      <div>
                        <input
                          id="txtRFPInitiationFee"
                          className="form-control"
                          placeholder="RFP initiation fee"
                        ></input>
                        <button
                          id="btnSetRegistrationFee"
                          onClick={setRFPInitiationFee}
                          className="btn btn-primary mt-2"
                        >
                          Set RFP Initiation Fee
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div id="crdRegistrationFee" className="card  mr-2">
                  <img
                    className="card-img-top"
                    src={imgFee}
                    alt="Card image cap"
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      Set Supplier Registration Fee
                    </h5>
                    {spiSetRegistrationFee ? (
                      <Spinner />
                    ) : (
                      <div>
                        <input
                          id="txtSupplierRegistrationFee"
                          className="form-control"
                          placeholder="Supplier Registration fee"
                        ></input>
                        <button
                          id="btnSetRegistration    "
                          onClick={setSupplierRegistrationFee}
                          className="btn btn-primary mt-2"
                        >
                          Set Supplier Registration Fee
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* End of Second Row */}
          </div>

          <div className="row mt-2">
            {/* Begining  of third Row */}
            <div className="col">
              <div className="card-group">
                <div id="crdReqFee" className="card  mr-2">
                  <img
                    className="card-img-top"
                    src={imgStart}
                    alt="Card image cap"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Set Requirement Fee</h5>
                    {spiSetRequirementFee ? (
                      <Spinner />
                    ) : (
                      <div>
                        <input
                          id="txtRequirementFee"
                          className="form-control"
                          placeholder="Requirement Fee"
                        ></input>
                        <button
                          id="btnSetRequirementFee"
                          onClick={setRequirementFee}
                          className="btn btn-primary mt-2"
                        >
                          Set Requirement Fee
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div id="crdResponseFee" className="card  mr-2">
                  <img
                    className="card-img-top"
                    src={imgFee}
                    alt="Card image cap"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Set Response Fee</h5>
                    {spiSetResponseFee ? (
                      <Spinner />
                    ) : (
                      <div>
                        <input
                          id="txtResponseFee"
                          className="form-control"
                          placeholder="Response Fee"
                        ></input>
                        <button
                          id="btnSetResponseFee"
                          onClick={setResponseFee}
                          className="btn btn-primary mt-2"
                        >
                          Set Response Fee
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* End  of Third Row */}
          </div>
          <div className="row mt-2">
            {/* Begining  of Fourth Row */}
            <div className="col">
              <div className="card-group">
                <div id="crdGetBalance" className="card  mr-2">
                  <img
                    className="card-img-top"
                    src={imgStart}
                    alt="Card image cap"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Get Procrain Ether Balance</h5>
                    {spiGetBalance ? (
                      <Spinner />
                    ) : (
                      <div>
                        <button
                          id="btnGetBalance"
                          onClick={getProcrainBalance}
                          className="btn btn-primary mt-2"
                        >
                          Get Balance
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div id="crdWithdraw" className="card  mr-2">
                  <img
                    className="card-img-top"
                    src={imgFee}
                    alt="Card image cap"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Withdraw Ether from Procrain</h5>
                    {spiWithdrawFromProcrain ? (
                      <Spinner />
                    ) : (
                      <div>
                        <input
                          id="txtAmountToWithdraw"
                          className="form-control"
                          placeholder="Amount to Withdraw"
                        ></input>
                        <button
                          id="btnWithdrawFromProcrain"
                          onClick={withdrawFromProcrain}
                          className="btn btn-primary mt-2"
                        >
                          Withdraw
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* End  of Fourth Row */}
          </div>
          <div className="row mt-2">
            {/* Begining  of Fifth Row */}
            <div className="col">
              <div className="card-group">
                <div id="crdGetTokenBalance" className="card  mr-2">
                  <img
                    className="card-img-top"
                    src={imgStart}
                    alt="Card image cap"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Get Procrain Token Balance</h5>
                    {spiGetTokenBalance ? (
                      <Spinner />
                    ) : (
                      <div>
                        <button
                          id="btnGetTokenBalance"
                          onClick={getProcrainTokenBalance}
                          className="btn btn-primary mt-2"
                        >
                          Get Token Balance
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div id="crdWithdrawToken" className="card  mr-2">
                  <img
                    className="card-img-top"
                    src={imgFee}
                    alt="Card image cap"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Withdraw Token from Procrain</h5>
                    {spiWithdrawTokenFromProcrain ? (
                      <Spinner />
                    ) : (
                      <div>
                        <input
                          id="txtAmountOfTokenToWithdraw"
                          className="form-control"
                          placeholder="Amount of Token to Withdraw"
                        ></input>
                        <button
                          id="btnWithdrawTokenFromProcrain"
                          onClick={withdrawTokenFromProcrain}
                          className="btn btn-primary mt-2"
                        >
                          Withdraw Token
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* End  of Fifth Row */}
          </div>
        </div>
      ) : (
        <br></br>
      )}
    </Fragment>
  );
};

export default Owner;
